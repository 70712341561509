import about from "@/i18n/en/langAbout";
import button from "@/i18n/en/langButton";
import error from "@/i18n/en/langError";
import faq from "@/i18n/en/langFaq";
import instructions from "@/i18n/en/langInstructions";
import message from "@/i18n/en/langMessage";
import privacy from "@/i18n/en/langPrivacy";
import success from "@/i18n/en/langSuccess";
import terms from "@/i18n/en/langTerms";
import text from "@/i18n/en/langText";
import title from "@/i18n/en/langTitle";
import { Lang } from "@/types/lang";

export default <Lang>{
  button,
  text,
  message,
  success,
  title,
  error,
  about,
  faq,
  instructions,
  terms,
  privacy,
};
