import about from "@/i18n/nl/langAbout";
import button from "@/i18n/nl/langButton";
import error from "@/i18n/nl/langError";
import faq from "@/i18n/nl/langFaq";
import instructions from "@/i18n/nl/langInstructions";
import message from "@/i18n/nl/langMessage";
import privacy from "@/i18n/nl/langPrivacy";
import success from "@/i18n/nl/langSuccess";
import terms from "@/i18n/nl/langTerms";
import text from "@/i18n/nl/langText";
import title from "@/i18n/nl/langTitle";
import { Lang } from "@/types/lang";

export default <Lang>{
  button,
  text,
  message,
  success,
  title,
  error,
  about,
  faq,
  instructions,
  terms,
  privacy,
};
