import { createI18n } from "vue-i18n";

import de from "@/i18n/de";
import en from "@/i18n/en";
import es from "@/i18n/es";
import fr from "@/i18n/fr";
import it from "@/i18n/it";
import nl from "@/i18n/nl";

export const langEnum: { [key: string]: string } = {
  english: "en",
  italian: "it",
  french: "fr",
  german: "de",
  spanish: "es",
  dutch: "nl",
};

const messages = {
  en,
  de,
  es,
  fr,
  it,
  nl,
};

export const i18n: any = createI18n({
  locale: "en",
  fallbackLocale: "en",
  legacy: false,
  globalInjection: true,
  messages,
});

i18n.global.locale.value = "en";

export default messages;
