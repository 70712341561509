import { Lang } from "@/types/lang";

export default <Lang>{
  termsHeading1: "Conditions générales",
  termsHeading2: "Garantie et responsabilité",
  termsHeading3: "Le Contenu",
  termsHeading4: "Contenu restreint",
  termsHeading5: "Pays restreints",
  termsHeading6: "Procédure de paiement",
  termsHeading7: "Politique de remboursement",
  termsHeading8: "Services de tiers",
  termsHeading9: "Droits d'auteur",
  termsHeading10: "Résiliation",

  terms1:
    'Avant d\'utiliser les services fournis par AI Factory (la "société", "nous", "la plateforme", le(s) "service(s)" ou "le site web"), il est recommandé de lire les conditions générales suivantes ("l\'accord"). Si vous accédez au site web ou aux services de quelque manière que ce soit, vous reconnaissez que vous avez lu, compris et accepté les conditions générales du présent accord et que vous acceptez d\'être lié par les limites de toutes les autres conditions. La violation de l\'une des conditions générales du présent accord entraîne l\'interruption de tout droit d\'utilisation de la plateforme ou des services. Si vous n\'êtes pas d\'accord avec ces conditions, veuillez NE PAS utiliser le site web.\n' +
    "Aifactorysolution.com est détenu et exploité par Techfox Group LTD.\n" +
    "Techfox Group LTD. est une société à responsabilité limitée régie par les lois de la République de Chypre, dont le siège social est situé à 22A Agias Zonis, office 101, 3027 Limassol, Chypre.\n" +
    "Numéro d'enregistrement : HE 436440\n" +
    "Numéro de téléphone : +357 968 78 662 +357 968 78 662\n" +
    "Courriel : contact{'@'}aifactorysolution.com\n" +
    "Éligibilité de l'utilisation\n" +
    "Vous nous garantissez que\n" +
    "- Vous avez l'âge légal dans votre pays de résidence ;\n" +
    "- Vous êtes capable de conclure un contrat contraignant et la loi ne vous interdit pas d'utiliser les services ou d'y accéder ;\n" +
    "- Les informations que vous nous fournissez sont exactes et véridiques ;\n" +
    "- Vous êtes légalement autorisé à utiliser toute méthode de paiement, y compris une carte de crédit ou de débit pour remplir toute commande (en fournissant ces informations, vous reconnaissez et nous autorisez à partager ces informations avec des tiers chargés du traitement des paiements afin de faciliter l'exécution de votre commande. Si nous soupçonnons une fraude, une transaction non autorisée ou illégale, nous nous réservons le droit de refuser ou d'annuler votre commande à tout moment pour certaines raisons, notamment l'indisponibilité du service, des erreurs techniques ou une tarification incorrecte).\n" +
    "Dans les juridictions où la loi l'interdit, le présent accord devient invalide et le droit d'accès aux services est révoqué.",
  terms2:
    "La plate-forme est continuellement mise à jour en ce qui concerne la disponibilité, les erreurs et les inexactitudes de nos services. Notre Plateforme peut contenir des Services dont le prix est inexact, qui sont décrits comme périmés ou qui ne sont pas disponibles à tout moment, et Nous pouvons subir des retards dans la mise à jour des informations concernant notre Service sur la Plateforme et dans la publicité sur d'autres sites web.\n" +
    "Nous avons le pouvoir de modifier ou de réviser les informations sans préavis, y compris les inexactitudes ou les omissions. Que vous utilisiez une carte de crédit ou de débit, le paiement peut être effectué en utilisant la carte qui vous a été émise et l'émetteur de la carte la validera. Si nous ne sommes pas en mesure d'obtenir l'autorisation nécessaire, nous ne pourrons être tenus responsables du retard ou de la non-livraison de votre commande.\n" +
    "Vous avez l'obligation de maintenir la confidentialité lorsque vous partagez votre mot de passe avec d'autres personnes. Si vous découvrez que votre compte a été piraté ou utilisé à des fins illégales, vous devez nous en informer sans délai.\n" +
    "Il vous incombe de veiller à ce que le contenu créé à l'aide de notre (nos) service(s) réponde aux exigences légales, notamment en ce qui concerne son exactitude et son caractère approprié. La Société vous accorde le droit et la licence d'utiliser, de modifier, d'exposer, de copier (dans son intégralité), de reproduire et de distribuer le Contenu créé par le biais de notre plateforme. La Société vous accorde des droits complets sur tout le Contenu généré par vous, ce qui signifie que vous êtes responsable de la protection et de la conservation de ces droits.\n" +
    "Vous utilisez le Service à vos propres risques. Vous reconnaissez que le Service peut contenir du contenu qui peut être considéré comme offensant, indécent, incorrect ou répréhensible, et vous acceptez qu'aucune responsabilité n'existe pour toute erreur ou dommage résultant de votre utilisation de notre Plateforme. Si vous adoptez un comportement frauduleux, abusif ou autrement illégal lors de l'utilisation des services, votre compte pourra être résilié. Il vous est interdit de publier ou de transmettre tout message ou toute sollicitation visant à obtenir des mots de passe, des comptes ou des informations privées d'autres utilisateurs de la plate-forme. Il est expressément interdit d'utiliser les services dans le but de porter atteinte à la sécurité de tout réseau informatique, de craquer des mots de passe ou des codes de cryptage de sécurité, ou de transférer ou de stocker du matériel illégal (y compris du contenu potentiellement nuisible ou illégal), ainsi que de s'engager dans toute activité illégale.\n" +
    "Le code source de la plate-forme ne sera pas décompilé, désossé ou obtenu par d'autres moyens. La société met en œuvre des sauvegardes régulières du contenu, mais celles-ci ne garantissent pas une protection à 100 % contre la corruption ou la perte de données. L'Entreprise fournira une assistance technique et s'efforcera de résoudre tous les problèmes qui pourraient survenir, qu'ils soient connus ou découverts, et qui affectent les sauvegardes du contenu. L'Entreprise n'est pas responsable de l'intégrité du Contenu ou de son incapacité à le restaurer avec succès dans un état utilisable.",
  terms3:
    "Notre service vous permet de générer du contenu audio, texte et image. Vous êtes responsable du contenu que vous générez en utilisant nos services, y compris de sa légalité et de son caractère approprié.\n" +
    "En générant du Contenu sur la Plateforme, vous nous accordez le droit et la licence d'utiliser, de modifier, d'exécuter publiquement, d'afficher publiquement, de reproduire et de distribuer ce Contenu sur et à travers le Service. Vous conservez tous vos droits sur tout Contenu que vous créez, publiez ou affichez sur ou par le biais du Service et vous êtes responsable de la protection de ces droits.\n" +
    "L'utilisation de nos services implique que vous compreniez que la société ne peut garantir que le contenu généré est correct à 100 %, de la meilleure qualité ou entièrement véridique et que, par conséquent, il ne fera l'objet d'aucun remboursement ni d'aucune demande concernant la qualité du contenu final.",
  terms4:
    "Vous êtes conscient et acceptez d'être entièrement responsable du contenu ainsi que de toutes les activités associées à votre compte, que ce soit vous ou un autre utilisateur qui les exécute avec un accès à votre compte.\n" +
    "Il vous est interdit de créer ou de transmettre tout contenu illégal, offensant, déshonorant, destiné à être répugnant, menaçant, diffamatoire, indécent, illégal ou autrement offensant. La liste suivante de contenus peut être considérée comme restreinte, sans toutefois s'y limiter :\n" +
    "- La participation à une activité illégale ou l'encouragement d'un tel comportement illégal ;\n" +
    "- Utiliser la plate-forme pour créer des documents diffamatoires, discriminatoires et offensants, y compris toute mention ou remarque concernant la religion, la race, l'orientation sexuelle/l'identité de genre, l'origine nationale/ethnique ou d'autres groupes ;\n" +
    "- Utiliser le service pour promouvoir des activités interdites telles que la consommation de substances ou de drogues illégales et les services pour adultes ;\n" +
    "- Générer du spam, des tâches générées par des machines ou attribuées au hasard, de la publicité non autorisée ou non sollicitée, des chaînes de lettres ;\n" +
    "- toute autre forme de sollicitation non sécurisée, ou toute forme de loterie ou de jeu ;\n" +
    "- utiliser le service pour créer ou développer des virus, des logiciels malveillants, des chevaux de Troie ou tout autre matériel similaire conçu pour perturber, endommager ou interférer avec le fonctionnement d'un logiciel ;\n" +
    "- enfreindre les droits de propriété intellectuelle de toute partie, y compris, mais sans s'y limiter, les brevets, les marques ou les secrets commerciaux, ainsi que les droits d'auteur et les droits de publicité ;\n" +
    "- Usurper l'identité de personnes, y compris d'employés et de représentants de l'entreprise ;\n" +
    "- mettre en danger la vie privée d'autrui.\n" +
    "Sauf accord contraire, la société a le pouvoir de rejeter ou de supprimer le contenu sexuellement explicite et de décider s'il est approprié à l'utilisation ou au respect des présentes conditions. La Société est libre de modifier la façon dont le contenu est présenté et d'apporter des changements. La société a le pouvoir de restreindre ou de révoquer le service si vous l'utilisez pour créer un contenu considéré comme offensant. La Société n'est pas responsable du contenu publié par les utilisateurs et/ou les tiers sur d'autres plateformes.",
  terms5:
    "Pour utiliser les services, un utilisateur doit confirmer son adresse e-mail. Toutefois, l'utilisation des Services est restreinte aux résidents des pays suivants : Afghanistan, Albanie, Barbade, Burkina Faso, Cambodge, Îles Caïmans, République démocratique du Congo, Gibraltar, Haïti, Jamaïque, Jordanie, Mali, Maroc, Mozambique. , Nicaragua, Pakistan, Panama, Philippines, Russie, Sénégal, Soudan du Sud, Syrie, Tanzanie, Trinité-et-Tobago, Turquie, Ouganda, Émirats arabes unis, Vanuatu, Yémen, Zimbabwe Corée du Nord (République populaire démocratique de Corée), Iran, Myanmar .\n",
  terms6:
    'Afin d\'accéder aux Services, vous devez disposer d\'un montant suffisant de la balance interne de la Plateforme - "Neurons" - dans votre compte Wallet. Les "Neurons" offrent aux utilisateurs un accès flexible aux services disponibles sur la plateforme. Vous pouvez vérifier votre solde et le recharger dans la section correspondante du site web. Les Neurons n\'ont aucune valeur en dehors de la Plateforme, ne peuvent être utilisés que sur la Plateforme et ne peuvent être transférés vers une autre plateforme ou à une autre personne. 10 Neurons équivalent à 1 euro.\n' +
    "Les transactions sont traitées dans les 72 heures suivant le paiement.\n" +
    "Une fois le paiement effectué, la mise à jour de votre solde peut prendre jusqu'à 24 heures, mais ce processus est généralement instantané. Nous sommes autorisés à faire appel à un prestataire de services de paiement tiers (le \"prestataire de services de paiement\") pour vos dépôts. Le prestataire de services de paiement concerné est principalement chargé de faciliter le paiement de vos transactions. Pour que votre transaction soit traitée, le prestataire de services de paiement a besoin de vos données personnelles et de vos données de paiement, telles que les données de votre carte de débit/crédit, votre adresse et d'autres informations. Ces informations ne sont utilisées que pour traiter les transactions et ne sont pas divulguées à des tiers, à l'exception des institutions financières et réglementaires impliquées dans les procédures de traitement ou de conformité. Pour en savoir plus sur l'utilisation de vos données personnelles, consultez notre politique de confidentialité.\n" +
    "Nous nous réservons le droit de vérifier et d'approuver toutes les transactions que vous effectuez en utilisant le Service afin de nous assurer qu'elles sont conformes aux présentes Conditions générales et aux conditions imposées à la Plateforme par des fournisseurs de services tiers. Vous reconnaissez et acceptez expressément que ces vérifications peuvent nécessiter que vous nous fournissiez des informations personnelles supplémentaires afin de vérifier et de confirmer votre identité et d'effectuer des vérifications visant à prévenir la fraude et l'utilisation abusive du Service, conformément à notre politique de confidentialité et à toutes les lois et réglementations applicables.\n" +
    "En procédant à une transaction, vous certifiez que toutes les cartes bancaires et autres comptes de paiement que vous utilisez pour les transactions sont légalement les vôtres.",
  terms7:
    "Nous ne garantissons aucun type de remboursement si le service payant a été fourni. Vous n'avez droit à aucun remboursement pour les frais ou les commissions de transaction que vous avez déjà payés.\n" +
    "Les remboursements ne sont possibles que pour les transactions effectuées pour acheter des Neurones et uniquement en cas d'erreurs techniques avec le service fourni sur le site Web et/ou d'autres problèmes découlant de nos propres problèmes qui ont conduit à votre incapacité à recevoir les services pour lesquels vous avez payé.\n" +
    "Les \"problèmes techniques\" sont définis comme des problèmes de performance importants, des pannes de système ou l'incapacité d'accéder aux fonctions essentielles de notre plateforme en raison d'erreurs attribuables à nos systèmes. Aucun remboursement ne sera accordé en cas de suspicion de fraude ou d'utilisation abusive de nos services. L'entreprise se réserve le droit d'enquêter de manière approfondie sur toute réclamation et de prendre les mesures juridiques appropriées si nécessaire pour protéger nos intérêts et ceux de nos utilisateurs légitimes.\n" +
    "La demande de remboursement doit être soumise via contact{'@'}aifactorysolution.com au plus tard 24 heures après la date de la transaction. Les demandes de remboursement seront traitées dans un délai de 14 jours ouvrables à compter de la date de soumission.\n" +
    "Les utilisateurs doivent fournir des descriptions détaillées, des captures d'écran, des messages d'erreur ou tout autre document pertinent qui justifie les problèmes techniques qu'ils ont rencontrés. Les demandes peuvent être refusées si elles ne répondent pas aux critères énoncés, notamment si les preuves de problèmes techniques sont insuffisantes ou s'il s'agit de cas de fraude ou d'abus.\n" +
    "Aucun remboursement ne sera effectué dans les cas où l'utilisateur est simplement insatisfait du produit, du service ou de la qualité du contenu généré sans raisons techniques valables.",
  terms8:
    "Les liens sur notre plateforme peuvent conduire à des sites web ou des services de tiers qui n'appartiennent pas à l'entreprise. Les sites web ou services de tiers peuvent être liés à la Société, mais la Société n'est pas responsable de leur contenu, de leurs politiques de confidentialité ou de leurs pratiques. Vous reconnaissez et acceptez que la Société ne peut être tenue responsable de tout dommage ou perte causé par ou en rapport avec l'utilisation ou la confiance accordée à ces contenus, biens ou services offerts par l'intermédiaire de sites web ou d'autres services. Nous vous recommandons vivement de lire les conditions d'utilisation, ainsi que les politiques de confidentialité, de tout site web ou service tiers auquel vous accédez.",
  terms9:
    "Le principe du droit d'auteur nous oblige à reconnaître et à respecter les droits de propriété intellectuelle d'autrui. Notre politique consiste à répondre à toute allégation selon laquelle le contenu publié sur le service enfreint les droits de propriété intellectuelle ou les droits d'auteur d'autrui. Si vous pensez que le service facilite la violation des droits d'auteur, et que vous êtes soit un détenteur de droits d'auteur, soit autorisé au nom d'un détenteur de droits d'auteur, veuillez nous envoyer un courrier électronique à l'adresse contact{'@'}aifactorysolution.com pour soumettre votre avis. Veuillez inclure une explication détaillée de la violation supposée dans votre notification. Si vous admettez qu'un contenu porte atteinte à vos droits d'auteur, vous pourrez être tenu responsable des dommages, y compris des frais et honoraires d'avocat.",
  terms10:
    "Nous avons le pouvoir de suspendre ou de résilier votre compte sans préavis ni responsabilité, même si vous n'enfreignez pas les conditions. Cette disposition s'applique quelle que soit la raison. La suppression de votre compte met fin à votre utilisation du service. Si vous souhaitez supprimer votre compte de la plateforme, il vous suffit de cesser d'utiliser le service. Le présent accord restera pleinement en vigueur tant que vous utiliserez les services. À tout moment, la Société a le pouvoir de résilier ou de suspendre votre accès aux Services. En cas de violation de l'une des conditions du présent accord, la société peut immédiatement suspendre ou mettre fin à tous les services et à l'accès au site web sans aucune responsabilité ni avertissement.\n" +
    "Toutes les dispositions du présent accord qui restent en vigueur après la résiliation sont essentielles, y compris les dispositions relatives à la propriété et les clauses de non-responsabilité. Ces limitations s'appliquent également aux limitations de responsabilité. La société, ses parents, ses filiales, ses sociétés affiliées et ses employés sont entièrement tenus de se défendre contre toute réclamation, responsabilité, règlement/coûts raisonnables, y compris les frais d'avocat ou les dommages résultant de votre utilisation des services, de la violation du présent accord ou de la violation par vous ou par une autre partie de votre propriété intellectuelle ou d'autres droits.\n" +
    "Si une disposition des présentes conditions est jugée inapplicable ou invalide, les autres dispositions seront modifiées et interprétées de manière à atteindre l'objectif visé conformément à la législation applicable, sans être remplacées. Sauf accord contraire, la non-application d'un droit ou d'une obligation en vertu des présentes conditions n'affectera pas la capacité de l'une ou l'autre partie à faire respecter le présent accord. En cas de litige, c'est la version anglaise originale des conditions qui prévaut.\n" +
    "Les présentes conditions sont susceptibles d'être modifiées et peuvent être modifiées ou remplacées par nous à notre seule discrétion. Vous reconnaissez et acceptez d'être lié par les conditions révisées si vous continuez à accéder à notre service ou à l'utiliser après l'entrée en vigueur de ces révisions. Si vous n'acceptez pas les nouvelles conditions, veuillez cesser d'utiliser le site web et le service.\n" +
    "Merci d'avoir choisi AI Factory ! Si vous avez des questions concernant les présentes conditions générales, veuillez nous contacter à l'adresse contact{'@'}aifactorysolution.com.",
  updated: "Dernière mise à jour le 01 août 2024",
};
