import { Lang } from "@/types/lang";

export default <Lang>{
  paraphrase1: "Enter a text you wish to paraphrase",
  paraphrase2: "Choose a style",
  paraphrase3: "Click 'Paraphrase'",
  creative1: "Choose a topic",
  creative2: "Choose an idea to generate",
  creative3: "Click “Generate”. The Platform will display 3 results",
  poems1: "Choose a style",
  poems2: "Set-up a stanza size and stanza count",
  poems3: " Click 'Generate'",
  voxAi1: "Choose a language.",
  voxAi2: "Choose a voice.",
  voxAi3: "Enter the text and click “Generate”. Generated audio may then be downloaded by clicking “Download” button.",
  neuroVision1: "Enter a prompt and set the amount of images for generation",
  neuroVision2: "Choose a style of image",
  neuroVision3:
    "Press “Generate”. Note that AI Factory has keyword filters, please refrain from using harmful, suggestive or in any way offensive prompts",
};
