import { orderBy } from "lodash";

import { useApi } from "@/api";
import { API_ROUTES } from "@/api/apiRoutes";
import { useAppStore } from "@/store/app/appStore";

export type Article = {
  id?: string;
  title: string;
  content: string;
  stats: Record<string, number>;
  published_at?: string;
  main_image: string | null;
  secondary_images?: string[] | null;
  reacted?: {
    isReacted: boolean;
    type: string | null;
  };
  isOpen?: boolean;
};

export const useGetArticles = async (): Promise<Article[]> => {
  const appStore = useAppStore();

  const { updateIsLoading } = appStore;
  const { request, data } = useApi<string>({
    path: API_ROUTES.ARTICLES as string,
  });

  updateIsLoading(true);

  try {
    await request();

    if (data.value) {
      return orderBy(data.value.data, "published_at", "desc");
    } else {
      return [];
    }
  } finally {
    updateIsLoading(false);
  }
};
