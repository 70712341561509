import { AxiosError } from "axios";
import { isArray } from "lodash";

import { RequestMethod, useApi } from "@/api";
import { API_ROUTES } from "@/api/apiRoutes";
import { i18n } from "@/i18n";
import { useAppStore } from "@/store/app/appStore";
import { HookConfig } from "@/types/api";
import { ToastSeverity } from "@/types/toast";

export type PaymentCallback = {
  session_id: string;
};

interface PaymentCallbackResponse {
  id: string;
  session_id: string;
  created_at: string;
  neuron_qnt: 3300;
  status: string;
}

export const useUserPaymentCallback = async <R>(
  body: PaymentCallback,
  config?: HookConfig
): Promise<PaymentCallbackResponse | null> => {
  const appStore = useAppStore();
  const { updateIsLoading, showToast } = appStore;
  const { request, data } = useApi<R>({
    path: API_ROUTES.PAYMENT_CALLBACK as string,
    method: RequestMethod.Post,
    body,
  });

  if (!config?.noLoader) {
    updateIsLoading(true);
  }

  try {
    await request();

    if (data.value.status === "confirmed") {
      showToast({
        summary: i18n.global.t("message.buyNeurons"),
        severity: ToastSeverity.Success,
        detail: i18n.global.t("success.balance"),
      });
      return data.value;
    }
  } catch (e) {
    let errorMessage = i18n.global.t("error.somethingWentWrong");
    if (e instanceof AxiosError) {
      errorMessage = isArray(e.response?.data.message) ? e.response?.data.message[0] : e.response?.data.message;
    }
    console.error(errorMessage);
  } finally {
    if (!config?.noLoader) {
      updateIsLoading(false);
    }
  }
  return null;
};
