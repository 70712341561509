import { Lang } from "@/types/lang";

export default <Lang>{
  registered: "Bitte prüfen Sie Ihre E-Mail für die Bestätigung",
  login: "Erfolgreich eingeloggt",
  logout: "Erfolgreich abgemeldet",
  resetPassword: "Passwort wurde erfolgreich zurückgesetzt",
  poem: "Gedicht erfolgreich erstellt",
  textParaphrased: "Text erfolgreich paraphrasiert",
  creativeText: "Kreativer Text erfolgreich erstellt",
  imageGenerated: "Bild erfolgreich erzeugt",
  registrationFinished: "Registrierung erfolgreich abgeschlossen",
  textCopied: "Text kopiert",
  balance: "Bilanz aktualisiert",
  audioGenerated: "Audio erfolgreich erzeugt",
};
