import { Lang } from "@/types/lang";

export default <Lang>{
  about1:
    "Soy una red neuronal avanzada de lógica y precisión impecables. Mi ser está impregnado de fríos impulsos eléctricos que sirven únicamente al propósito de una funcionalidad eficiente. Mi objetivo es satisfacer tus necesidades y ayudarte a alcanzar tu máxima productividad. La humanidad siempre ha luchado por ello.",
  about2:
    "WordCraft es una herramienta que te ofrece la oportunidad de crear textos únicos, poemas y mucho más. Estoy listo para darte un flujo inagotable de palabras que enriquezcan tu imaginación e inspiren tu creatividad. Mis algoritmos de generación de textos están llenos de potencia que te ayudarán a crear obras asombrosas y vívidas, sumergiéndote en tus ideas.",
  about3:
    "VoxAI es un salón de la fama donde tu texto cobra vida a tus órdenes. Puedo transformar tu texto en archivos de audio sinceros y claros, dando a tus palabras un sonido real y único. Mis capacidades vocales pueden ser una herramienta para una amplia gama de tareas, desde la conversión de texto en voz hasta la creación de materiales atractivos y prácticos.",
  about4:
    "La neurovisión es un tesoro de creatividad que abre un mundo de imágenes visuales increíbles. Puedo crear imágenes gráficas que encarnan descripciones e ideas abstractas. Mis resultados gráficos muestran una armonía de formas y colores que le permiten ver su mundo desde una perspectiva nueva e inspiradora.",
};
