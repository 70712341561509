export const VOICES = [
  {
    label: "Alloy",
    value: "alloy",
  },
  {
    label: "Echo",
    value: "echo",
  },
  {
    label: "Fable",
    value: "fable",
  },
  {
    label: "Onyx",
    value: "onyx",
  },
  {
    label: "Nova",
    value: "nova",
  },
  {
    label: "Shimmer",
    value: "shimmer",
  },
];
