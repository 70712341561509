import { Lang } from "@/types/lang";

export default <Lang>{
  registered: "Veuillez vérifier votre courrier électronique pour obtenir une confirmation",
  login: "Connexion réussie",
  logout: "Déconnexion réussie",
  resetPassword: "Le mot de passe a été réinitialisé avec succès",
  poem: "Poème créé avec succès",
  textParaphrased: "Texte paraphrasé avec succès",
  creativeText: "Texte créatif créé avec succès",
  imageGenerated: "Image générée avec succès",
  registrationFinished: "L'enregistrement s'est terminé avec succès",
  textCopied: "Texte copié",
  balance: "Solde mis à jour",
  audioGenerated: "Audio généré avec succès",
};
