const styleOptionsEn = [
  {
    title: "Fantasy-art",
    value: "fantasy-art",
  },
  {
    title: "3d model",
    value: "3d-model",
  },
  {
    title: "Photographic",
    value: "photographic",
  },
  {
    title: "Isometric",
    value: "isometric",
  },
  {
    title: "Analog film",
    value: "analog-film",
  },
  {
    title: "Pixel-art",
    value: "pixel-art",
  },
  {
    title: "Line-art",
    value: "line-art",
  },
  {
    title: "Anime",
    value: "anime",
  },
  {
    title: "Tile-texture",
    value: "tile-texture",
  },
  {
    title: "Low-poly",
    value: "low-poly",
  },
  {
    title: "Cinematic",
    value: "cinematic",
  },
  {
    title: "Modeling-compound",
    value: "modeling-compound",
  },
  {
    title: "Comic-book",
    value: "comic-book",
  },
  {
    title: "Digital Art",
    value: "digital-art",
  },
  {
    title: "Enhance",
    value: "enhance",
  },
  {
    title: "Neon Punk",
    value: "neon-punk",
  },
  {
    title: "Origami",
    value: "origami",
  },
];

const styleOptionsDe = [
  {
    title: "Fantasy-Kunst",
    value: "fantasy-art",
  },
  {
    title: "3d-Modell",
    value: "3d-model",
  },
  {
    title: "Fotografisch",
    value: "photographic",
  },
  {
    title: "Isometrisch",
    value: "isometric",
  },
  {
    title: "Analoger Film",
    value: "analog-film",
  },
  {
    title: "Pixel-Kunst",
    value: "pixel-art",
  },
  {
    title: "Strichzeichnungen",
    value: "line-art",
  },
  {
    title: "Anime",
    value: "anime",
  },
  {
    title: "Kachel-Textur",
    value: "tile-texture",
  },
  {
    title: "Niedrig-Poly",
    value: "low-poly",
  },
  {
    title: "Kinematisch",
    value: "cinematic",
  },
  {
    title: "Modellierung-Verbindung",
    value: "modeling-compound",
  },
  {
    title: "Comicbuch",
    value: "comic-book",
  },
  {
    title: "Digitale Kunst",
    value: "digital-art",
  },
  {
    title: "Verbessern",
    value: "enhance",
  },
  {
    title: "Neon Punk",
    value: "neon-punk",
  },
  {
    title: "Origami",
    value: "origami",
  },
];

const styleOptionsEs = [
  {
    title: "Fantasía-Arte",
    value: "fantasy-art",
  },
  {
    title: "Modelo 3d",
    value: "3d-model",
  },
  {
    title: "Fotográfico",
    value: "photographic",
  },
  {
    title: "Isométrico",
    value: "isometric",
  },
  {
    title: "Película analógica",
    value: "analog-film",
  },
  {
    title: "Pixel-Art",
    value: "pixel-art",
  },
  {
    title: "Line-Art",
    value: "line-art",
  },
  {
    title: "Anime",
    value: "anime",
  },
  {
    title: "Tile-texture",
    value: "tile-texture",
  },
  {
    title: "Low-poly",
    value: "low-poly",
  },
  {
    title: "Cinemático",
    value: "cinematic",
  },
  {
    title: "Modelado compuesto",
    value: "modeling-compound",
  },
  {
    title: "Cómic",
    value: "comic-book",
  },
  {
    title: "Arte digital",
    value: "digital-art",
  },
  {
    title: "Mejorar",
    value: "enhance",
  },
  {
    title: "Neón punk",
    value: "neon-punk",
  },
  {
    title: "Origami",
    value: "origami",
  },
];

const styleOptionsFr = [
  {
    title: "Fantasy-Art",
    value: "fantasy-art",
  },
  {
    title: "Modèle 3d",
    value: "3d-model",
  },
  {
    title: "Photographique",
    value: "photographic",
  },
  {
    title: "Isométrique",
    value: "isometric",
  },
  {
    title: "Film analogique",
    value: "analog-film",
  },
  {
    title: "Pixel-Art",
    value: "pixel-art",
  },
  {
    title: "Art linéaire",
    value: "line-art",
  },
  {
    title: "Anime",
    value: "anime",
  },
  {
    title: "Tile-texture",
    value: "tile-texture",
  },
  {
    title: "Low-poly",
    value: "low-poly",
  },
  {
    title: "Cinématique",
    value: "cinematic",
  },
  {
    title: "Modeling-compound",
    value: "modeling-compound",
  },
  {
    title: "Bande dessinée",
    value: "comic-book",
  },
  {
    title: "Art numérique",
    value: "digital-art",
  },
  {
    title: "Améliorer",
    value: "enhance",
  },
  {
    title: "Néon punk",
    value: "neon-punk",
  },
  {
    title: "Origami",
    value: "origami",
  },
];

const styleOptionsIt = [
  {
    title: "Fantasia-Arte",
    value: "fantasy-art",
  },
  {
    title: "Modello 3d",
    value: "3d-model",
  },
  {
    title: "Fotografico",
    value: "photographic",
  },
  {
    title: "Isometrico",
    value: "isometric",
  },
  {
    title: "Film analogico",
    value: "analog-film",
  },
  {
    title: "Pixel-Art",
    value: "pixel-art",
  },
  {
    title: "Line-Art",
    value: "line-art",
  },
  {
    title: "Anime",
    value: "anime",
  },
  {
    title: "Texture a piastrelle",
    value: "tile-texture",
  },
  {
    title: "Low-poly",
    value: "low-poly",
  },
  {
    title: "Cinematico",
    value: "cinematic",
  },
  {
    title: "Modellazione composta",
    value: "modeling-compound",
  },
  {
    title: "Modellazione composta",
    value: "comic-book",
  },
  {
    title: "Arte digitale",
    value: "digital-art",
  },
  {
    title: "Migliorare",
    value: "enhance",
  },
  {
    title: "Neon punk",
    value: "neon-punk",
  },
  {
    title: "Origami",
    value: "origami",
  },
];

const styleOptionsNl = [
  {
    title: "Fantasy-kunst",
    value: "fantasy-art",
  },
  {
    title: "3d model",
    value: "3d-model",
  },
  {
    title: "Fotografisch",
    value: "photographic",
  },
  {
    title: "Isometrisch",
    value: "isometric",
  },
  {
    title: "Analoge film",
    value: "analog-film",
  },
  {
    title: "Pixel-kunst",
    value: "pixel-art",
  },
  {
    title: "Line-Art",
    value: "line-art",
  },
  {
    title: "Anime",
    value: "anime",
  },
  {
    title: "Tegel-textuur",
    value: "tile-texture",
  },
  {
    title: "Low-poly",
    value: "low-poly",
  },
  {
    title: "Filmisch",
    value: "cinematic",
  },
  {
    title: "Modelleer-samenstelling",
    value: "modeling-compound",
  },
  {
    title: "Stripboek",
    value: "comic-book",
  },
  {
    title: "Digitale kunst",
    value: "digital-art",
  },
  {
    title: "Verbeteren",
    value: "enhance",
  },
  {
    title: "Neon punk",
    value: "neon-punk",
  },
  {
    title: "Origami",
    value: "origami",
  },
];

export const styleOptions: { [key: string]: Record<string, string>[] } = {
  english: styleOptionsEn,
  italian: styleOptionsIt,
  french: styleOptionsFr,
  german: styleOptionsDe,
  spanish: styleOptionsEs,
  dutch: styleOptionsNl,
};
