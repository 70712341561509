import { Lang } from "@/types/lang";

export default <Lang>{
  registered: "Please check you email for confirmation",
  login: "Logged in successfully",
  logout: "Logged out successfully",
  resetPassword: "Password was successfully reset",
  // poem: "Poem created successfully",
  poem: "Poem generated successfully",
  textParaphrased: "Text paraphrased successfully",
  // creativeText: "Creative text created successfully",
  creativeText: "Request completed",
  imageGenerated: "Image generated successfully",
  registrationFinished: "Registration finished successfully",
  textCopied: "Text copied",
  balance: "Balance updated",
  audioGenerated: "Audio generated successfully",
};
