import { AxiosError } from "axios";
import { isArray } from "lodash";

import { useApi } from "@/api";
import { API_ROUTES } from "@/api/apiRoutes";
import { ApiArticleResponse } from "@/hooks/admin/useCreateArticle";
import { i18n } from "@/i18n";

export const useGetArticleById = async (id: string): Promise<ApiArticleResponse | null> => {
  const { request, data } = useApi<string>({
    path: `${API_ROUTES.ARTICLES}/${id}`,
  });

  try {
    await request();

    if (data.value) {
      return data.value;
    }
  } catch (e) {
    let errorMessage = i18n.global.t("error.somethingWentWrong");
    if (e instanceof AxiosError) {
      errorMessage = isArray(e.response?.data.message) ? e.response?.data.message[0] : e.response?.data.message;
    }
    console.error(errorMessage);
  }

  return null;
};
