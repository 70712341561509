import { Lang } from "@/types/lang";

export default <Lang>{
  transactions: "Transaktionen",
  description: "Transaktionen",
  neurons: "Neurons",
  date: "Datum",
  howToUse: "Wie zu verwenden:",
  profile: "Profil",
  password: "Passwort",
  generatingPoems: "Gedichte generieren",
  stanzaCount: "Strophenanzahl",
  stanzaSize: "Strophengröße",
  paraphrasing: "Paraphrasieren",
  style: "Stil",
  generatorImages: "Bildgenerator",
  imageService: "Image-Dienste",
  generatingImages: "Bilder generieren",
  creative: "Kreativ",
  phone: "Telefon",
  registrationNumber: "Zulassungsnummer",
  whereWeAreLocated: "Unser Standort",
  newPassword: "Neues Passwort",
  confirmPassword: "Bestätigen Sie Ihr Passwort",
  audioService: "Audio-Dienste",
  generatingAudio: "Generierung von Text in Audio",
  generatorAudio: "Audio-Erzeugung",
  buyNeurons: "Kaufen Sie Neurons",
  swap: "TAUSCHEN",
  enterSubject: "Betreff Eingeben",
  selectStyle: "Stil auswählen",
  pleaseLogin: "Bitte anmelden, um fortzufahren",
  instructions: "Anweisungen",
  selectTheme: "Thema wählen",
  resetPassword: "Passwort zurücksetzen",
  noNeurons: "Ihr Guthaben ist niedrig. Bitte kaufen Sie Neurons, um fortzufahren.",
  voiceSelection: "Auswahl der Stimme",
  generation: "Generation",
  enterText: "Text eingeben",
  privacyPolicy: "Datenschutzbestimmungen",
  termsAndConditions: "Bedingungen und Konditionen",
  contactUs: "Kontakt",
  faq: "FAQ",
};
