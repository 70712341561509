import { Lang } from "@/types/lang";

export default <Lang>{
  termsHeading1: "Termini e condizioni d'uso",
  termsHeading2: "Garanzia e responsabilità",
  termsHeading3: "Il Contenuto",
  termsHeading4: "Contenuto limitato",
  termsHeading5: "Paesi soggetti a restrizioni",
  termsHeading6: "Procedura di pagamento",
  termsHeading7: "Politica di rimborso",
  termsHeading8: "Servizi di terze parti",
  termsHeading9: "Diritti d'autore",
  termsHeading10: "Cessazione",

  terms1:
    'Prima di utilizzare i Servizi forniti da AI Factory (la "Società", "Noi", "la Piattaforma", il "Servizio/i" o "il Sito"), si raccomanda di leggere i seguenti Termini e Condizioni ("Accordo"). Se si accede al sito web o ai servizi in qualsiasi modo, si riconosce di aver letto, compreso e accettato i Termini e le Condizioni del presente Accordo, accettando di essere vincolati dalle limitazioni di tutti gli altri termini. La violazione di uno qualsiasi dei Termini e delle Condizioni del presente accordo comporta l\'interruzione di qualsiasi diritto all\'utilizzo della Piattaforma o dei Servizi. Se non si è d\'accordo con questi termini, si prega di NON utilizzare il sito web.\n' +
    "Aifactorysolution.com è di proprietà e gestito da Techfox Group LTD.\n" +
    "Techfox Group LTD. è regolamentato dalle leggi della Repubblica di Cipro come società a responsabilità limitata, con sede legale in 22A Agias Zonis, ufficio 101, 3027 Limassol, Cipro.\n" +
    "Numero di registrazione: HE 436440\n" +
    "Numero di telefono: +357 968 78 662\n" +
    "E-mail: contact{'@'}aifactorysolution.com\n" +
    "Ammissibilità dell'uso\n" +
    "L'utente garantisce a Noi che:\n" +
    "- Siete maggiorenni nel vostro paese di residenza;\n" +
    "- siete in grado di stipulare un contratto vincolante e non vi è vietato per legge l'utilizzo o l'accesso ai Servizi;\n" +
    "- che le informazioni fornite sono accurate e veritiere;\n" +
    "- Siete legalmente autorizzati a utilizzare qualsiasi metodo di pagamento, compresa la carta di credito o di debito, per l'evasione di qualsiasi ordine (fornendo tali informazioni, riconoscete e ci autorizzate a condividerle con terze parti incaricate dell'elaborazione dei pagamenti per facilitare il completamento del vostro ordine. Se sospettiamo una frode, una transazione non autorizzata o illegale, ci riserviamo il diritto di rifiutare o annullare l'ordine in qualsiasi momento per determinati motivi, tra cui l'indisponibilità del servizio, errori tecnici o prezzi errati).\n" +
    "Nelle giurisdizioni in cui la legge lo vieta, il presente Contratto non è valido e il diritto di accedere ai Servizi è revocato.",
  terms2:
    "La Piattaforma è in continuo aggiornamento per quanto riguarda la disponibilità, gli errori e le imprecisioni dei Nostri Servizi. La nostra Piattaforma potrebbe contenere Servizi con prezzi imprecisi, descritti come obsoleti o non sempre disponibili, e potremmo subire ritardi nell'aggiornamento delle informazioni sui nostri Servizi sulla Piattaforma e nella pubblicità su altri siti web.\n" +
    "Abbiamo la facoltà di modificare o rivedere le informazioni senza preavviso, comprese eventuali inesattezze o omissioni. Sia che si utilizzi una carta di credito o di debito, il pagamento può essere effettuato con la carta che è stata emessa e l'emittente della carta la convaliderà. Nel caso in cui non fossimo in grado di ottenere l'autorizzazione necessaria, non saremo responsabili di eventuali ritardi o della mancata consegna dell'ordine.\n" +
    "L'utente ha l'obbligo di mantenere la riservatezza quando condivide la propria password con altri. Nel momento in cui si scopre che il proprio account è stato violato o utilizzato per scopi illegali, l'utente è tenuto ad informare tempestivamente la Società.\n" +
    "È responsabilità dell'utente garantire che il Contenuto creato utilizzando i nostri Servizi soddisfi i requisiti legali, tra cui la correttezza e l'adeguatezza. La Società concede all'utente il diritto e la licenza di utilizzare, modificare, esporre, copiare (nella sua interezza), riprodurre e distribuire i Contenuti creati attraverso la nostra piattaforma. La Società concede all'utente i diritti completi su tutti i Contenuti generati dall'utente, il che significa che l'utente è responsabile della protezione e del mantenimento di tali diritti.\n" +
    "L'utente deve utilizzare il Servizio a proprio rischio e pericolo. L'utente riconosce che il Servizio può contenere contenuti che possono essere considerati offensivi, indecenti, scorretti o discutibili e accetta di non essere responsabile di eventuali errori o danni derivanti dall'uso della nostra Piattaforma. Se l'utente si impegna in comportamenti fraudolenti, abusivi o altrimenti illegali durante l'utilizzo dei Servizi, il suo account potrà essere chiuso. È vietato pubblicare o trasmettere qualsiasi messaggio o sollecitazione che abbia lo scopo di ottenere password, account o informazioni private da altri utenti della Piattaforma. È espressamente vietato utilizzare i Servizi allo scopo di violare la sicurezza di qualsiasi rete informatica, decifrare password o codici di crittografia di sicurezza, o trasferire o memorizzare materiale illegale (inclusi contenuti potenzialmente dannosi o illegali), nonché intraprendere qualsiasi attività illegale.\n" +
    "Il codice sorgente della Piattaforma non potrà essere decompilato, decodificato o ottenuto con qualsiasi altro mezzo. La Società implementa backup regolari dei contenuti, ma non garantisce una protezione al 100% da corruzione o perdita di dati. La Società si impegna a fornire assistenza tecnica e a risolvere eventuali problemi, noti o scoperti, che riguardano i backup dei Contenuti. La Società non è responsabile dell'integrità del Contenuto o del suo mancato ripristino in uno stato utilizzabile.",
  terms3:
    "Il nostro Servizio vi consente di generare Contenuti audio, di testo e di immagine. L'utente è responsabile dei Contenuti generati utilizzando i Nostri Servizi, compresa la loro legalità e adeguatezza.\n" +
    "Generando Contenuti sulla Piattaforma, ci concedete il diritto e la licenza di utilizzare, modificare, eseguire pubblicamente, visualizzare pubblicamente, riprodurre e distribuire tali Contenuti su e attraverso il Servizio. L'utente conserva tutti i diritti sui Contenuti creati, pubblicati o visualizzati su o attraverso il Servizio ed è responsabile della protezione di tali diritti.\n" +
    "L'utilizzo dei nostri Servizi implica la consapevolezza che la Società non può garantire che i contenuti generati siano corretti al 100%, della migliore qualità o interamente veritieri e, pertanto, non sarà soggetta ad alcun rimborso o richiesta di informazioni sulla qualità finale dei contenuti.",
  terms4:
    "L'utente è consapevole e accetta di essere completamente responsabile del Contenuto e di tutte le attività associate al suo account, sia che le esegua lui stesso o un altro utente con accesso al suo account.\n" +
    "È vietato creare o trasmettere Contenuti illegali, offensivi, vergognosi, intesi come ripugnanti, minacciosi, diffamatori, indecenti, illegali o altrimenti offensivi. Il seguente elenco di Contenuti può essere considerato limitato, ma non è limitato a:\n" +
    "- Impegnarsi in un'attività illegale o incoraggiare tale comportamento illegale;\n" +
    "- Utilizzare la Piattaforma per creare materiali diffamatori, discriminatori e offensivi, incluse menzioni o osservazioni su religione, razza, orientamento sessuale/identità di genere, origine nazionale/etnica o altri gruppi;\n" +
    "- Utilizzare il Servizio per promuovere attività vietate, come l'uso di sostanze/droghe illegali e servizi per adulti;\n" +
    "- Generare spam, compiti generati dalla macchina o assegnati in modo casuale, pubblicità non autorizzata o non richiesta, lettere a catena;\n" +
    "- qualsiasi altra forma di sollecitazione non protetta, o qualsiasi tipo di lotteria o gioco d'azzardo;\n" +
    "- Utilizzare il Servizio per creare o sviluppare virus, malware, cavalli di Troia o materiale simile progettato per causare interruzioni, danni o interferenze con il funzionamento di qualsiasi software;\n" +
    "- Violare i diritti di proprietà intellettuale di qualsiasi parte, inclusi, a titolo esemplificativo, brevetti, marchi o segreti commerciali, nonché diritti d'autore e diritti di pubblicità;\n" +
    "- Impersonare persone, compresi dipendenti e rappresentanti della Società;\n" +
    "- mettere in pericolo la privacy di altri.\n" +
    "Se non diversamente concordato, la Società ha la facoltà di rifiutare o eliminare i contenuti sessualmente espliciti e di decidere se sono appropriati per l'uso o se sono conformi ai presenti Termini. La Società è libera di modificare il modo in cui il Contenuto viene presentato e di apportare modifiche. La Società ha la facoltà di limitare o revocare il Servizio se l'utente lo utilizza per creare Contenuti considerati offensivi. La Società non è responsabile dei contenuti pubblicati dagli utenti e/o da terzi su altre piattaforme.",
  terms5:
    "Per utilizzare i Servizi l'utente deve confermare il proprio indirizzo e-mail. Tuttavia, l'utilizzo dei Servizi è limitato ai residenti nei seguenti paesi: Afghanistan, Albania, Barbados, Burkina Faso, Cambogia, Isole Cayman, Repubblica Democratica del Congo, Gibilterra, Haiti, Giamaica, Giordania, Mali, Marocco, Mozambico , Nicaragua, Pakistan, Panama, Filippine, Russia, Senegal, Sud Sudan, Siria, Tanzania, Trinidad e Tobago, Turchia, Uganda, Emirati Arabi Uniti, Vanuatu, Yemen, Zimbabwe Corea del Nord (Repubblica Democratica Popolare di Corea), Iran, Myanmar .\n",
  terms6:
    "Per poter accedere ai Servizi, l'utente deve disporre di un importo sufficiente del saldo interno della Piattaforma - \"Neuroni\" - nel proprio conto Wallet. I \"Neuroni\" offrono agli utenti un accesso flessibile ai servizi disponibili sulla piattaforma. L'utente può controllare il proprio saldo e ricaricarlo nella sezione del Sito web dedicata. I Neuroni non hanno valore al di fuori della Piattaforma, possono essere utilizzati solo sulla Piattaforma e non possono essere trasferiti a un'altra piattaforma o a un'altra persona. 10 neuroni equivalgono a 1 euro.\n" +
    "Le transazioni vengono elaborate entro 72 ore dal pagamento.\n" +
    "Una volta completato il pagamento, l'aggiornamento del saldo potrebbe richiedere fino a 24 ore, anche se di solito il processo è immediato. Per i vostri depositi abbiamo la facoltà di utilizzare un servizio di pagamento di terzi (il \"Processore di pagamento\"). Il rispettivo Processore di pagamento è il principale responsabile della facilitazione del pagamento delle vostre transazioni. Affinché la vostra transazione possa essere elaborata, il Processore di pagamento richiede i vostri dati personali e i vostri dati di pagamento, come i dettagli della carta di debito/credito, l'indirizzo e altre informazioni. Queste informazioni vengono utilizzate solo per elaborare le transazioni e non vengono divulgate a terzi, con l'unica eccezione delle istituzioni finanziarie e normative coinvolte nelle procedure di elaborazione o di conformità. Per ulteriori informazioni sull'utilizzo dei vostri dati personali, consultate la nostra Informativa sulla privacy.\n" +
    "Ci riserviamo il diritto di verificare e approvare tutte le transazioni effettuate dall'utente durante l'utilizzo del Servizio per garantire che siano conformi ai presenti Termini e Condizioni e ai termini imposti sulla Piattaforma da fornitori di servizi terzi. L'utente riconosce e accetta espressamente che tali verifiche possono richiedere che l'utente ci fornisca ulteriori informazioni personali al fine di verificare e confermare la sua identità e di eseguire verifiche volte a scoraggiare le frodi e l'uso improprio del Servizio, in conformità con la nostra Politica sulla privacy e tutte le leggi e i regolamenti applicabili.\n" +
    "Procedendo con una transazione, l'utente certifica che tutte le carte bancarie e gli altri conti di pagamento utilizzati per le transazioni sono legalmente suoi.",
  terms7:
    "Non garantiamo alcun tipo di rimborso se il servizio a pagamento è stato fornito. L'utente non ha diritto ad alcun rimborso per le spese o le commissioni di transazione già pagate.\n" +
    "I rimborsi sono possibili solo per le transazioni effettuate per l'acquisto di Neurons e solo in caso di errori tecnici con il Servizio fornito sul Sito web e/o altri problemi derivanti da problemi nostri che hanno portato all'impossibilità di ricevere i Servizi pagati.\n" +
    'Per "problemi tecnici" si intendono problemi significativi di prestazioni, crash di sistema o l\'impossibilità di accedere a funzioni essenziali della nostra piattaforma a causa di errori attribuibili ai nostri sistemi. I rimborsi non saranno concessi in caso di sospetta frode o uso improprio dei nostri Servizi. La società si riserva il diritto di indagare a fondo su qualsiasi reclamo e di intraprendere le opportune azioni legali, se necessario, per proteggere i nostri interessi e quelli dei nostri utenti legittimi.\n' +
    "La richiesta di rimborso deve essere inoltrata tramite contact{'@'}aifactorysolution.com entro e non oltre 24 ore dalla data della transazione. Le richieste di rimborso saranno elaborate entro 14 giorni lavorativi dalla data di presentazione.\n" +
    "Gli utenti devono fornire descrizioni dettagliate, screenshot, messaggi di errore o qualsiasi documentazione pertinente che comprovi le loro affermazioni sui problemi tecnici riscontrati. Le richieste possono essere respinte se non soddisfano i criteri indicati, tra cui prove insufficienti di problemi tecnici o casi di frode o uso improprio.\n" +
    "I rimborsi non saranno effettuati nei casi in cui l'utente sia semplicemente insoddisfatto del prodotto, del servizio o della qualità dei contenuti generati senza valide ragioni tecniche.",
  terms8:
    "I link presenti sulla nostra piattaforma possono condurre a siti web o servizi di terze parti non di proprietà della Società. I siti web o i servizi di terzi possono essere collegati alla Società, ma quest'ultima non è responsabile del loro contenuto, delle loro politiche sulla privacy o delle loro pratiche. L'utente riconosce e accetta che la Società non può essere ritenuta responsabile per eventuali danni o perdite causati da o in relazione all'uso o all'affidamento su tali contenuti, beni o servizi offerti attraverso siti web o altri servizi. Si consiglia vivamente di leggere le condizioni d'uso e le politiche sulla privacy di qualsiasi sito web o servizio di terzi a cui si accede.",
  terms9:
    "Il principio del copyright ci impone di riconoscere e rispettare i diritti di proprietà intellettuale di altri. La nostra politica è quella di affrontare qualsiasi reclamo che il Contenuto pubblicato sul Servizio violi i diritti di proprietà intellettuale o il copyright di altri. Nel caso in cui si ritenga che il Servizio stia facilitando la violazione del copyright, e si è proprietari del copyright o autorizzati per conto dello stesso, si prega di inviare un'e-mail all'indirizzo contact{'@'}aifactorysolution.com per presentare la propria segnalazione. Si prega di includere nella comunicazione una spiegazione dettagliata della presunta violazione. L'ammissione di una falsa dichiarazione di violazione del copyright da parte di un Contenuto può comportare la responsabilità dell'utente per i danni, compresi i costi e le spese legali.",
  terms10:
    "Abbiamo la facoltà di sospendere o chiudere l'Account dell'utente senza alcun preavviso o responsabilità, anche se l'utente non viola i Termini. Questo vale indipendentemente dal motivo. L'utilizzo del Servizio da parte dell'utente sarà terminato con la cancellazione del suo account. Se si desidera rimuovere il proprio Account dalla Piattaforma, è sufficiente cessare di utilizzare il Servizio. Il presente Contratto continuerà ad avere pieno vigore ed efficacia durante l'utilizzo dei Servizi da parte dell'utente. In qualsiasi momento, la Società ha la facoltà di interrompere o sospendere l'accesso dell'utente ai Servizi. In caso di violazione di uno qualsiasi dei termini e delle condizioni del presente Contratto, la Società può sospendere o interrompere immediatamente tutti i Servizi e l'accesso al Sito web senza alcuna responsabilità o avviso.\n" +
    "Tutte le disposizioni del presente Contratto che rimangono in vigore dopo la cessazione sono essenziali, comprese le disposizioni sulla proprietà e le esclusioni di responsabilità. Tali limitazioni si applicano anche alle limitazioni di responsabilità. La Società, i suoi genitori, le sue filiali, i suoi affiliati e i suoi dipendenti sono pienamente obbligati a difendersi da eventuali reclami, responsabilità, risarcimenti/costi, comprese le spese legali o i danni derivanti dall'uso dei Servizi da parte dell'utente, dalla violazione del presente Contratto o dalla violazione da parte dell'utente o di un'altra parte della sua proprietà intellettuale o di altri diritti.\n" +
    "Se una qualsiasi disposizione dei presenti Termini è ritenuta inapplicabile o non valida, le altre disposizioni saranno modificate e interpretate per raggiungere lo scopo previsto dalla legge applicabile senza essere sostituite. Se non diversamente concordato, la mancata applicazione di un diritto o di un obbligo ai sensi dei presenti Termini non pregiudicherà la capacità di una delle parti di far valere la propria conformità al presente Contratto. La versione originale in inglese dei Termini sarà la legge prevalente in qualsiasi controversia.\n" +
    "I presenti Termini sono soggetti a modifiche e possono essere emendati o sostituiti da noi a nostra esclusiva discrezione. L'utente riconosce e accetta di essere vincolato dai Termini modificati se continua ad accedere o a utilizzare il nostro Servizio dopo l'entrata in vigore di tali revisioni. Se non siete d'accordo con i nuovi termini, siete pregati di interrompere l'utilizzo del sito web e del Servizio.\n" +
    "Grazie per aver scelto AI Factory! Per qualsiasi domanda relativa ai presenti Termini e condizioni, contattateci all'indirizzo contact{'@'}aifactorysolution.com.",
  updated: "Ultimo aggiornamento: 01 agosto 2024",
};
