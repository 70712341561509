import { Lang } from "@/types/lang";

export default <Lang>{
  registration: "Registratie",
  login: "Toegang",
  signUpWarning1:
    "Door op Aanmelden te klikken of door verder te gaan met de andere opties hieronder, accepteert u onze  ",
  signUpWarning2: "Servicevoorwaarden en garandeert ",
  signUpWarning3: "u dat u het volgende hebt gelezen het ",
  signUpWarning4: "privacybeleid",
  logout: "Ontkoppeling",
  reset: "Wachtwoord opnieuw instellen",
  createPoem: "Een gedicht maken",
  paraphrase: "Parafrase",
  Creative: "Creatief",
  Image: "Afbeelding",
  registrationConfirmation: "Registratie Bevestiging",
  copyToClipboard: "Kopiëren naar klembord",
  buyNeurons: "Neuronen kopen",
  speechToAudio: "Tekst naar audio",
};
