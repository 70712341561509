import {
  PARAPHRASE_BASE_PRICE,
  PARAPHRASE_DEFAULT_CHARS_QNT,
  PARAPHRASE_EXTRA_CHARS_PRICE,
  PARAPHRASE_EXTRA_CHARS_QNT,
  PARAPHRASE_MULTIPLIER,
} from "@/constants/priceCalculation";

export const getParaphrasePrice = (text: string): number => {
  const x = PARAPHRASE_BASE_PRICE;
  const defCharsQnt = PARAPHRASE_DEFAULT_CHARS_QNT;
  const extraCharsQnt = PARAPHRASE_EXTRA_CHARS_QNT;
  const extraCharsPrice = PARAPHRASE_EXTRA_CHARS_PRICE;
  const multiplier = PARAPHRASE_MULTIPLIER;
  const len = text.length;
  const extraLen = len - defCharsQnt <= 0 ? 0 : len - defCharsQnt;
  const extraQnt = Math.ceil(extraLen / extraCharsQnt);
  const y = extraCharsPrice * extraQnt;
  return x + x * multiplier + y;
};
