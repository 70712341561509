import { Lang } from "@/types/lang";

export default <Lang>{
  registration: "Inscription",
  login: "Connexion",
  signUpWarning1:
    "En cliquant sur connexion, ou en continuant avec les autres options ci-dessous, vous acceptez les conditions de service et avez lu ",
  signUpWarning2: "conditions de service ",
  signUpWarning3: "et avez lu la ",
  signUpWarning4: "politique de confidentialité",
  logout: "Déconnexion",
  reset: "Réinitialiser le mot de passe",
  createPoem: "Créer un poème",
  paraphrase: "Paraphrase",
  Creative: "Créatif",
  Image: "Image",
  registrationConfirmation: "Confirmation d'inscription",
  copyToClipboard: "Copier dans le presse-papiers",
  buyNeurons: "Acheter des Neurons",
  speechToAudio: "De la parole à l'audio",
};
