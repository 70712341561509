export const PARAPHRASE_BASE_PRICE = 100;
export const PARAPHRASE_DEFAULT_CHARS_QNT = 300;
export const PARAPHRASE_EXTRA_CHARS_QNT = 150;
export const PARAPHRASE_EXTRA_CHARS_PRICE = 50;
export const PARAPHRASE_MULTIPLIER = 0.3;

export const VERSE_HAIKU_SIZE_1_PRICE = 150;
export const VERSE_SIZE_2_BASE_PRICE = 100;
export const VERSE_SONNET_PRICE = 300;
export const CREATIVE_BASE_PRICE = 100;
export const IMAGE_BASE_PRICE = 50;

export const SPEECH_BASE_PRICE = 200;
export const SPEECH_DEFAULT_CHARS_QNT = 150;
export const SPEECH_EXTRA_CHARS_QNT = 100;
export const SPEECH_EXTRA_CHARS_PRICE = 100;
export const SPEECH_MULTIPLIER = 0.2;
export const SPEECH_MULTIPLIER_REAL_VOICE = 0.3;
//this is just a comment line to test the pipeline, it can be deleted later on at any time, and sorry.
