<template>
  <div class="privacy">
    <div class="privacy__updated">{{ $t("terms.updated") }}</div>
    <h1 class="privacy__heading">{{ $t("privacy.privacyHeading1") }}</h1>
    <div>
      <pre>{{ $t("privacy.privacy1") }}</pre>
    </div>

    <h1 class="privacy__heading">{{ $t("privacy.privacyHeading2") }}</h1>
    <div>
      <pre>{{ $t("privacy.privacy2") }}</pre>
    </div>

    <h1 class="privacy__heading">{{ $t("privacy.privacyHeading3") }}</h1>
    <div>
      <pre>{{ $t("privacy.privacy3") }}</pre>
    </div>

    <h1 class="privacy__heading">{{ $t("privacy.privacyHeading4") }}</h1>
    <div>
      <pre>{{ $t("privacy.privacy4") }}</pre>
    </div>

    <h1 class="privacy__heading">{{ $t("privacy.privacyHeading5") }}</h1>
    <div>
      <pre>{{ $t("privacy.privacy5") }}</pre>
    </div>

    <h1 class="privacy__heading">{{ $t("privacy.privacyHeading6") }}</h1>
    <div>
      <pre>{{ $t("privacy.privacy6") }}</pre>
    </div>

    <h1 class="privacy__heading">{{ $t("privacy.privacyHeading7") }}</h1>
    <div>
      <pre>{{ $t("privacy.privacy7") }}</pre>
    </div>

    <h1 class="privacy__heading">{{ $t("privacy.privacyHeading8") }}</h1>
    <div>
      <pre>{{ $t("privacy.privacy8") }}</pre>
    </div>

    <div>
      <pre>{{ $t("privacy.privacy9") }}</pre>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.privacy {
  h1:first-of-type {
    padding-top: 40px;
  }

  h1 {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }

  &__heading {
    padding-top: 12px;
    text-align: center;
  }

  pre {
    margin: 0 auto;
    width: 90%;
    white-space: pre-wrap;
  }

  &__updated {
    position: relative;
    top: 30px;
    left: 30px;
  }
}
</style>
