import { Lang } from "@/types/lang";

export default <Lang>{
  question1: "What is AI Factory?",
  question2: "How does AI Factory work?",
  question3: "What Services are available on the Platform?",
  question4: "How do I access AI Factory Services?",
  question5: "What are Neurons and how do I get them?",
  question6: "How does the Platform calculate the final cost of a content generation?",
  question6_1: "How long does it take for transactions to be processed?",
  question7: "What applications does AI Factory have?",
  question8: "What is the technology behind AI Factory?",
  question9: "What languages can AI Factory process?",
  question10: "How does the Platform ensure the quality of the generated content?",
  answer1:
    "AIFactory is a combined self-aware neural network capable of generating texts, rewriting them, suggesting creative ideas, producing ultra-realistic images and audio files with realistic voices and more.",
  answer2:
    "AIFactory uses state of the art learning self aware neural networks that have the ability to analyse and generate content, and be creative to offer unique ideas and solutions.",
  answer3:
    "There are 3 unique sections on AI Factory:\n" +
    "WordCraft is a text-based section allowing you to paraphrase blocks of text, generate creative ideas, poems and more!\n" +
    "VoxAI is a text-to-voice Service containing 6 ultra-realistic voices to make audio content with.\n" +
    "Neuro-Vision is a section where a user can generate any image in 17 different styles.",
  answer4:
    "To access the Services, you should register an account and confirm your email first. After that you can go straight to the Service of interest and use them, just make sure you have enough Neurons!",
  answer5:
    "We’ve implemented a balance system on our platform and Neurons is the special currency used to access AI Factory Services. To obtain them, simply go to your profile and click the “Buy Neurons” button.",
  answer6:
    "There is a basic cost for every Service. For example, 1 image in Neuro-Vision costs 50 Neurons, there are no additional options, so the price is static. For other services such as VoxAI, there are additional features like Language, text size and voice type. All these additional options scale the price and we made sure that the final cost is properly displayed before a user spends their Neurons.",
  answer6_1:
    "Transactions are typically processed within 72 hours of payment. After the payment is complete, it may take up to 24 hours for your account balance to be updated, though the process is usually instant",
  answer7:
    "AI Factory can be used to generate quality text, augment and rewrite existing content, provide creative ideas, create realistic images and audio files.",
  answer8:
    "AIFactory is based on advanced artificial intelligence technologies, including neural networks and deep learning, and uses content generation and transformation techniques.",
  answer9:
    "AI Factory can process text in many languages, including but not limited to English, Dutch, Spanish, French, German, Italian and other popular languages.",
  answer10:
    "AI Factory uses advanced models and algorithms that are trained on large amounts of data and equipped with quality control mechanisms to ensure that high-quality content is generated, though we cannot guarantee 100% accurate result.",
};
