<template>
  <div class="contact-us">
    <h1 class="contact-us__title">{{ $t("title.contactUs") }}</h1>
    <div class="contact-us__block-container">
      <div class="contact-us__block">
        <span class="contact-us__block-name">{{ $t("title.phone") }}:</span>+357 968 78 662
      </div>
      <div class="contact-us__block">
        <span class="contact-us__block-name">{{ $t("title.registrationNumber") }}:</span>
        <div class="registration-number">HE 436440</div>
      </div>
      <div class="contact-us__block">
        <span class="contact-us__block-name">Email:</span>
        <div class="email-address">contact@aifactorysolution.com</div>
      </div>
      <div class="contact-us__block map">
        <span class="contact-us__block-name map">{{ $t("title.whereWeAreLocated") }}:</span>
        <div>
          <div style="width: 100%">
            <iframe
              height="170"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=22A%20Agias%20Zonis,%20office%20101,%203027%20Limassol,%20Cyprus+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              width="100%"
            ></iframe>
          </div>
        </div>
        <span class="contact-us__address">22A Agias Zonis, office 101, 3027 Limassol, Cyprus</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
@import "@/scss/colors.scss";

.email-address {
  @media screen and (max-width: 630px) {
    font-size: 17px;
  }

  @media screen and (max-width: 420px) {
    font-size: 15px;
  }
}

.contact-us {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__block-container {
    display: flex;
    row-gap: 24px;
    flex-direction: column;
    align-items: center;
    width: 50%;

    @media screen and (max-width: 900px) {
      width: 70%;
    }

    @media screen and (max-width: 602px) {
      width: 80%;
    }

    @media screen and (max-width: 526px) {
      width: 90%;
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    margin-bottom: 54px;
    padding-top: 90px;

    @media screen and (max-width: 768px) {
      margin: 0 auto;
      margin-bottom: 20px;
      padding-top: 40px;
    }
  }

  &__block {
    display: flex;
    justify-content: space-between;
    border: 2px solid #2a4365;
    border-radius: 8px;
    background-color: #131313;
    padding: 24px 50px;
    width: 100%;
    font-weight: 400;
    font-size: 20px;

    @media screen and (max-width: 526px) {
      padding: 24px 24px;
    }

    @media screen and (max-width: 526px) {
      font-size: 18px;
    }
  }

  &__block.map {
    flex-direction: column;
    padding: 24px 0;
  }

  &__block-name {
    padding-right: 20px;
    color: $primary-200;
    font-weight: 600;
    font-size: 20px;
    @media screen and (max-width: 526px) {
      font-size: 16px;
    }
  }

  &__block-name.map {
    padding-bottom: 18px;
    padding-left: 50px;
    @media screen and (max-width: 526px) {
      padding-left: 24px;
    }
  }

  &__address {
    float: right;
    margin-left: auto;
    padding-top: 18px;
    padding-right: 24px;
    font-weight: 400;
    text-align: end;
    @media screen and (max-width: 526px) {
      font-size: 18px;
    }
  }
}
</style>
