// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { StyleApiCalc } from "@/views/PoemsService.vue";

const creativeThemesEn = [
  {
    key: "0",
    label: "Gift ideas",
    selectable: false,
    leaf: true,
    children: [
      {
        key: "0-0",
        label: "Gift for mom",
        data: "Gift ideas",
        selectable: true,
      },
      {
        key: "0-1",
        label: "Gift for the significant other",
        data: "Gift ideas",
        selectable: true,
      },
      {
        key: "0-2",
        label: "Wedding gift",
        data: "Gift ideas",
        selectable: true,
      },
      {
        key: "0-3",
        label: "Gift for a colleague",
        data: "Gift ideas",
        selectable: true,
      },
      {
        key: "0-4",
        label: "Gift for a child",
        data: "Gift ideas",
        selectable: true,
      },
    ],
  },
  {
    key: "1",
    label: "Cooking Recipes",
    selectable: false,
    leaf: true,
    children: [
      {
        key: "1-0",
        label: "Vegetarian dish",
        data: "Cooking Recipes",
        selectable: true,
      },
      {
        key: "1-1",
        label: "Meat dish",
        data: "Cooking Recipes",
        selectable: true,
      },
      {
        key: "1-2",
        label: "Economy dish",
        data: "Cooking Recipes",
        selectable: true,
      },
      {
        key: "1-3",
        label: "Desserts",
        data: "Cooking Recipes",
        selectable: true,
      },
      {
        key: "1-4",
        label: "Exotic dish",
        data: "Cooking Recipes",
        selectable: true,
      },
    ],
  },
  {
    key: "2",
    label: "Activities",
    selectable: false,
    leaf: true,
    children: [
      {
        key: "2-0",
        label: "Holiday ideas",
        data: "Activities",
        selectable: true,
      },
      {
        key: "2-1",
        label: "Travel ideas",
        data: "Activities",
        selectable: true,
      },
      {
        key: "2-2",
        label: "Business ideas",
        data: "Activities",
        selectable: true,
      },
      {
        key: "2-3",
        label: "Hobby ideas",
        data: "Activities",
        selectable: true,
      },
      {
        key: "2-4",
        label: "Career ideas",
        data: "Activities",
        selectable: true,
      },
    ],
  },
  {
    key: "3",
    label: "Romance",
    selectable: false,
    leaf: true,
    children: [
      {
        key: "2-0",
        label: "Pick-up lines",
        data: "Romance",
        selectable: true,
      },
      {
        key: "3-1",
        label: "Topics to discuss",
        data: "Romance",
        selectable: true,
      },
      {
        key: "3-2",
        label: "Places for a date",
        data: "Romance",
        selectable: true,
      },
      {
        key: "3-3",
        label: "Proposal ideas",
        data: "Romance",
        selectable: true,
      },
      {
        key: "3-4",
        label: "Break-up lines",
        data: "Romance",
        selectable: true,
      },
    ],
  },
  {
    key: "4",
    label: "Other",
    selectable: false,
    leaf: true,
    children: [
      {
        key: "4-0",
        label: "Tell a joke",
        data: "Other",
        selectable: true,
      },
      {
        key: "4-1",
        label: "Tell a story",
        data: "Othery",
        selectable: true,
      },
      {
        key: "4-2",
        label: "Party ideas",
        data: "Other",
        selectable: true,
      },
      {
        key: "4-3",
        label: "Competition prize ideas",
        data: "Other",
        selectable: true,
      },
      {
        key: "4-4",
        label: "Sanctuary ideas",
        data: "Other",
        selectable: true,
      },
    ],
  },
];
const creativeThemesDe = [
  {
    key: "0",
    label: "Geschenkideen",
    selectable: false,
    leaf: true,
    children: [
      {
        key: "0-0",
        label: "Geschenk für die Mutter",
        data: "Geschenkideen",
        selectable: true,
      },
      {
        key: "0-1",
        label: "Geschenk für die Partnerin oder den Partner",
        data: "Geschenkideen",
        selectable: true,
      },
      {
        key: "0-2",
        label: "Hochzeitsgeschenk",
        data: "Geschenkideen",
        selectable: true,
      },
      {
        key: "0-3",
        label: "Geschenk für einen Kollegen",
        data: "Geschenkideen",
        selectable: true,
      },
      {
        key: "0-4",
        label: "Geschenk für ein Kind",
        data: "Geschenkideen",
        selectable: true,
      },
    ],
  },
  {
    key: "1",
    label: "Kochrezepte",
    selectable: false,
    leaf: true,
    children: [
      {
        key: "1-0",
        label: "Vegetarisches Gericht",
        data: "Kochrezepte",
        selectable: true,
      },
      {
        key: "1-1",
        label: "Fleischgericht",
        data: "Kochrezepte",
        selectable: true,
      },
      {
        key: "1-2",
        label: "Spargericht",
        data: "Kochrezepte",
        selectable: true,
      },
      {
        key: "1-3",
        label: "Nachspeisen",
        data: "Kochrezepte",
        selectable: true,
      },
      {
        key: "1-4",
        label: "Exotisches Gericht",
        data: "Kochrezepte",
        selectable: true,
      },
    ],
  },
  {
    key: "2",
    label: "Aktivitäten",
    selectable: false,
    leaf: true,
    children: [
      {
        key: "2-0",
        label: "Ideen für den Urlaub",
        data: "Aktivitäten",
        selectable: true,
      },
      {
        key: "2-1",
        label: "Reise-Ideen",
        data: "Aktivitäten",
        selectable: true,
      },
      {
        key: "2-2",
        label: "Geschäftsideen",
        data: "Aktivitäten",
        selectable: true,
      },
      {
        key: "2-3",
        label: "Hobby-Ideen",
        data: "Aktivitäten",
        selectable: true,
      },
      {
        key: "2-4",
        label: "Karriere-Ideen",
        data: "Aktivitäten",
        selectable: true,
      },
    ],
  },
  {
    key: "3",
    label: "Romantik",
    selectable: false,
    leaf: true,
    children: [
      {
        key: "2-0",
        label: "Anmachsprüche",
        data: "Romantik",
        selectable: true,
      },
      {
        key: "3-1",
        label: "Themen zum Diskutieren",
        data: "Romantik",
        selectable: true,
      },
      {
        key: "3-2",
        label: "Orte für ein Date",
        data: "Romantik",
        selectable: true,
      },
      {
        key: "3-3",
        label: "Ideen für einen Heiratsantrag",
        data: "Romantik",
        selectable: true,
      },
      {
        key: "3-4",
        label: "Trennungssprüche",
        data: "Romantik",
        selectable: true,
      },
    ],
  },
  {
    key: "4",
    label: "Andere",
    selectable: false,
    leaf: true,
    children: [
      {
        key: "4-0",
        label: "Erzählen eines Witzes",
        data: "Andere",
        selectable: true,
      },
      {
        key: "4-1",
        label: "Eine Geschichte erzählen",
        data: "Andere",
        selectable: true,
      },
      {
        key: "4-2",
        label: "Ideen für Partys",
        data: "Andere",
        selectable: true,
      },
      {
        key: "4-3",
        label: "Ideen für Preisausschreiben",
        data: "Andere",
        selectable: true,
      },
      {
        key: "4-4",
        label: "Ideen für ein Heiligtum",
        data: "Andere",
        selectable: true,
      },
    ],
  },
];
const creativeThemesEs = [
  {
    key: "0",
    label: "Ideas para regalar",
    selectable: false,
    leaf: true,
    children: [
      {
        key: "0-0",
        label: "Regalo para mamá",
        data: "Ideas para regalar",
        selectable: true,
      },
      {
        key: "0-1",
        label: "Regalo para la pareja",
        data: "Ideas para regalar",
        selectable: true,
      },
      {
        key: "0-2",
        label: "Regalo de boda",
        data: "Ideas para regalar",
        selectable: true,
      },
      {
        key: "0-3",
        label: "Regalo para un colega",
        data: "Ideas para regalar",
        selectable: true,
      },
      {
        key: "0-4",
        label: "Regalo para un niño",
        data: "Ideas para regalar",
        selectable: true,
      },
    ],
  },
  {
    key: "1",
    label: "Recetas de cocina",
    selectable: false,
    leaf: true,
    children: [
      {
        key: "1-0",
        label: "Plato vegetariano",
        data: "Recetas de cocina",
        selectable: true,
      },
      {
        key: "1-1",
        label: "Plato de carne",
        data: "Recetas de cocina",
        selectable: true,
      },
      {
        key: "1-2",
        label: "Plato económico",
        data: "Recetas de cocina",
        selectable: true,
      },
      {
        key: "1-3",
        label: "Postres",
        data: "Recetas de cocina",
        selectable: true,
      },
      {
        key: "1-4",
        label: "Plato exótico",
        data: "Recetas de cocina",
        selectable: true,
      },
    ],
  },
  {
    key: "2",
    label: "Actividades",
    selectable: false,
    leaf: true,
    children: [
      {
        key: "2-0",
        label: "Ideas para las vacaciones",
        data: "Actividades",
        selectable: true,
      },
      {
        key: "2-1",
        label: "Ideas de viaje",
        data: "Actividades",
        selectable: true,
      },
      {
        key: "2-2",
        label: "Ideas de negocios",
        data: "Actividades",
        selectable: true,
      },
      {
        key: "2-3",
        label: "Ideas para aficiones",
        data: "Actividades",
        selectable: true,
      },
      {
        key: "2-4",
        label: "Ideas profesionales",
        data: "Actividades",
        selectable: true,
      },
    ],
  },
  {
    key: "3",
    label: "Romance",
    selectable: false,
    leaf: true,
    children: [
      {
        key: "2-0",
        label: "Líneas para ligar",
        data: "Romance",
        selectable: true,
      },
      {
        key: "3-1",
        label: "Temas de conversación",
        data: "Romance",
        selectable: true,
      },
      {
        key: "3-2",
        label: "Lugares para una cita",
        data: "Romance",
        selectable: true,
      },
      {
        key: "3-3",
        label: "Ideas para proponer matrimonio",
        data: "Romance",
        selectable: true,
      },
      {
        key: "3-4",
        label: "Líneas de ruptura",
        data: "Romance",
        selectable: true,
      },
    ],
  },
  {
    key: "4",
    label: "Otros",
    selectable: false,
    leaf: true,
    children: [
      {
        key: "4-0",
        label: "Contar un chiste",
        data: "Otros",
        selectable: true,
      },
      {
        key: "4-1",
        label: "Contar una historia",
        data: "Otros",
        selectable: true,
      },
      {
        key: "4-2",
        label: "Ideas para fiestas",
        data: "Otros",
        selectable: true,
      },
      {
        key: "4-3",
        label: "Ideas para premios de concursos",
        data: "Otros",
        selectable: true,
      },
      {
        key: "4-4",
        label: "Ideas para santuarios",
        data: "Otros",
        selectable: true,
      },
    ],
  },
];
const creativeThemesFr = [
  {
    key: "0",
    label: "Idées cadeaux",
    selectable: false,
    leaf: true,
    children: [
      {
        key: "0-0",
        label: "Cadeau pour maman",
        data: "Idées cadeaux",
        selectable: true,
      },
      {
        key: "0-1",
        label: "Cadeau pour l'autre",
        data: "Idées cadeaux",
        selectable: true,
      },
      {
        key: "0-2",
        label: "Cadeau de mariage",
        data: "Idées cadeaux",
        selectable: true,
      },
      {
        key: "0-3",
        label: "Cadeau pour un collègue",
        data: "Idées cadeaux",
        selectable: true,
      },
      {
        key: "0-4",
        label: "Cadeau pour un enfant",
        data: "Idées cadeaux",
        selectable: true,
      },
    ],
  },
  {
    key: "1",
    label: "Recettes de cuisine",
    selectable: false,
    leaf: true,
    children: [
      {
        key: "1-0",
        label: "Plat végétarien",
        data: "Recettes de cuisine",
        selectable: true,
      },
      {
        key: "1-1",
        label: "Plat de viande",
        data: "Recettes de cuisine",
        selectable: true,
      },
      {
        key: "1-2",
        label: "Plat économique",
        data: "Recettes de cuisine",
        selectable: true,
      },
      {
        key: "1-3",
        label: "Desserts",
        data: "Recettes de cuisine",
        selectable: true,
      },
      {
        key: "1-4",
        label: "Plat exotique",
        data: "Recettes de cuisine",
        selectable: true,
      },
    ],
  },
  {
    key: "2",
    label: "Activités",
    selectable: false,
    leaf: true,
    children: [
      {
        key: "2-0",
        label: "Idées de vacances",
        data: "Activités",
        selectable: true,
      },
      {
        key: "2-1",
        label: "Idées de voyage",
        data: "Activités",
        selectable: true,
      },
      {
        key: "2-2",
        label: "Idées d'affaires",
        data: "Activités",
        selectable: true,
      },
      {
        key: "2-3",
        label: "Idées de loisirs",
        data: "Activités",
        selectable: true,
      },
      {
        key: "2-4",
        label: "Idées de carrière",
        data: "Activités",
        selectable: true,
      },
    ],
  },
  {
    key: "3",
    label: "Romance",
    selectable: false,
    leaf: true,
    children: [
      {
        key: "2-0",
        label: "Idées de drague",
        data: "Romance",
        selectable: true,
      },
      {
        key: "3-1",
        label: "Sujets de discussion",
        data: "Romance",
        selectable: true,
      },
      {
        key: "3-2",
        label: "Lieux de rendez-vous",
        data: "Romance",
        selectable: true,
      },
      {
        key: "3-3",
        label: "Idées de demande en mariage",
        data: "Romance",
        selectable: true,
      },
      {
        key: "3-4",
        label: "Lignes de rupture",
        data: "Romance",
        selectable: true,
      },
    ],
  },
  {
    key: "4",
    label: "Autres",
    selectable: false,
    leaf: true,
    children: [
      {
        key: "4-0",
        label: "Raconter une blague",
        data: "Autres",
        selectable: true,
      },
      {
        key: "4-1",
        label: "Raconter une histoire",
        data: "Autres",
        selectable: true,
      },
      {
        key: "4-2",
        label: "Idées de fêtes",
        data: "Autres",
        selectable: true,
      },
      {
        key: "4-3",
        label: "Idées de prix de concours",
        data: "Autres",
        selectable: true,
      },
      {
        key: "4-4",
        label: "Idées de sanctuaires",
        data: "Autres",
        selectable: true,
      },
    ],
  },
];
const creativeThemesIt = [
  {
    key: "0",
    label: "Idee regalo",
    selectable: false,
    leaf: true,
    children: [
      {
        key: "0-0",
        label: "Regalo per la mamma",
        data: "Idee regalo",
        selectable: true,
      },
      {
        key: "0-1",
        label: "Regalo per l'altra persona",
        data: "Idee regalo",
        selectable: true,
      },
      {
        key: "0-2",
        label: "Regalo di nozze",
        data: "Idee regalo",
        selectable: true,
      },
      {
        key: "0-3",
        label: "Regalo per un collega",
        data: "Idee regalo",
        selectable: true,
      },
      {
        key: "0-4",
        label: "Regalo per un bambino",
        data: "Idee regalo",
        selectable: true,
      },
    ],
  },
  {
    key: "1",
    label: "Ricette di cucina",
    selectable: false,
    leaf: true,
    children: [
      {
        key: "1-0",
        label: "Piatto vegetariano",
        data: "Ricette di cucina",
        selectable: true,
      },
      {
        key: "1-1",
        label: "Piatto di carne",
        data: "Ricette di cucina",
        selectable: true,
      },
      {
        key: "1-2",
        label: "Piatto economico",
        data: "Ricette di cucina",
        selectable: true,
      },
      {
        key: "1-3",
        label: "Dolci",
        data: "Ricette di cucina",
        selectable: true,
      },
      {
        key: "1-4",
        label: "Piatto esotico",
        data: "Ricette di cucina",
        selectable: true,
      },
    ],
  },
  {
    key: "2",
    label: "Attività",
    selectable: false,
    leaf: true,
    children: [
      {
        key: "2-0",
        label: "Idee per le vacanze",
        data: "Attività",
        selectable: true,
      },
      {
        key: "2-1",
        label: "Idee di viaggio",
        data: "Attività",
        selectable: true,
      },
      {
        key: "2-2",
        label: "Idee per il business",
        data: "Attività",
        selectable: true,
      },
      {
        key: "2-3",
        label: "Idee per gli hobby",
        data: "Attività",
        selectable: true,
      },
      {
        key: "2-4",
        label: "Idee per la carriera",
        data: "Attività",
        selectable: true,
      },
    ],
  },
  {
    key: "3",
    label: "Idee romantiche",
    selectable: false,
    leaf: true,
    children: [
      {
        key: "2-0",
        label: "Linee di rimorchio",
        data: "Idee romantiche",
        selectable: true,
      },
      {
        key: "3-1",
        label: "Argomenti da discutere",
        data: "Idee romantiche",
        selectable: true,
      },
      {
        key: "3-2",
        label: "Luoghi per un appuntamento",
        data: "Idee romantiche",
        selectable: true,
      },
      {
        key: "3-3",
        label: "Idee per una proposta di matrimonio",
        data: "Idee romantiche",
        selectable: true,
      },
      {
        key: "3-4",
        label: "Battute di rottura",
        data: "Idee romantiche",
        selectable: true,
      },
    ],
  },
  {
    key: "4",
    label: "Altro",
    selectable: false,
    leaf: true,
    children: [
      {
        key: "4-0",
        label: "Raccontare una barzelletta",
        data: "Altro",
        selectable: true,
      },
      {
        key: "4-1",
        label: "Raccontare una storia",
        data: "Altro",
        selectable: true,
      },
      {
        key: "4-2",
        label: "Idee per una festa",
        data: "Altro",
        selectable: true,
      },
      {
        key: "4-3",
        label: "Idee per un concorso a premi",
        data: "Altro",
        selectable: true,
      },
      {
        key: "4-4",
        label: "Idee per il santuario",
        data: "Altro",
        selectable: true,
      },
    ],
  },
];
const creativeThemesNl = [
  {
    key: "0",
    label: "Cadeau-ideeën",
    selectable: false,
    leaf: true,
    children: [
      {
        key: "0-0",
        label: "Cadeau voor mama",
        data: "Cadeau-ideeën",
        selectable: true,
      },
      {
        key: "0-1",
        label: "Cadeau voor de partner",
        data: "Cadeau-ideeën",
        selectable: true,
      },
      {
        key: "0-2",
        label: "Cadeau voor de bruiloft",
        data: "Cadeau-ideeën",
        selectable: true,
      },
      {
        key: "0-3",
        label: "Cadeau voor een collega",
        data: "Cadeau-ideeën",
        selectable: true,
      },
      {
        key: "0-4",
        label: "Cadeau voor een kind",
        data: "Cadeau-ideeën",
        selectable: true,
      },
    ],
  },
  {
    key: "1",
    label: "Recepten",
    selectable: false,
    leaf: true,
    children: [
      {
        key: "1-0",
        label: "Vegetarisch gerecht",
        data: "Recepten",
        selectable: true,
      },
      {
        key: "1-1",
        label: "Vleesgerecht",
        data: "Recepten",
        selectable: true,
      },
      {
        key: "1-2",
        label: "Economieschotel",
        data: "Recepten",
        selectable: true,
      },
      {
        key: "1-3",
        label: "Desserts",
        data: "Recepten",
        selectable: true,
      },
      {
        key: "1-4",
        label: "Exotisch gerecht",
        data: "Recepten",
        selectable: true,
      },
    ],
  },
  {
    key: "2",
    label: "Activiteiten",
    selectable: false,
    leaf: true,
    children: [
      {
        key: "2-0",
        label: "Vakantie-ideeën",
        data: "Activiteiten",
        selectable: true,
      },
      {
        key: "2-1",
        label: "Ideeën voor op reis",
        data: "Activiteiten",
        selectable: true,
      },
      {
        key: "2-2",
        label: "Zakelijke ideeën",
        data: "Activiteiten",
        selectable: true,
      },
      {
        key: "2-3",
        label: "Hobby-ideeën",
        data: "Activiteiten",
        selectable: true,
      },
      {
        key: "2-4",
        label: "Carrière-ideeën",
        data: "Activiteiten",
        selectable: true,
      },
    ],
  },
  {
    key: "3",
    label: "Romantiek",
    selectable: false,
    leaf: true,
    children: [
      {
        key: "2-0",
        label: "Pick-up regels",
        data: "Romantiek",
        selectable: true,
      },
      {
        key: "3-1",
        label: "Onderwerpen om te bespreken",
        data: "Romantiek",
        selectable: true,
      },
      {
        key: "3-2",
        label: "Plaatsen voor een afspraakje",
        data: "Romantiek",
        selectable: true,
      },
      {
        key: "3-3",
        label: "Ideeën voor een aanzoek",
        data: "Romantiek",
        selectable: true,
      },
      {
        key: "3-4",
        label: "Uitmaak lines",
        data: "Romantiek",
        selectable: true,
      },
    ],
  },
  {
    key: "4",
    label: "Andere",
    selectable: false,
    leaf: true,
    children: [
      {
        key: "4-0",
        label: "Vertel een mop",
        data: "Other",
        selectable: true,
      },
      {
        key: "4-1",
        label: "Vertel een verhaal",
        data: "Othery",
        selectable: true,
      },
      {
        key: "4-2",
        label: "Ideeën voor een feestje",
        data: "Other",
        selectable: true,
      },
      {
        key: "4-3",
        label: "Ideeën voor een prijsvraag",
        data: "Other",
        selectable: true,
      },
      {
        key: "4-4",
        label: "Heiligdom ideeën",
        data: "Other",
        selectable: true,
      },
    ],
  },
];
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const themesMap: any = {
  en: creativeThemesEn,
  de: creativeThemesDe,
  es: creativeThemesEs,
  fr: creativeThemesFr,
  it: creativeThemesIt,
  nl: creativeThemesNl,
};

const verseStylesEn = [
  {
    label: "Haiku",
    value: 1,
  },
  {
    label: "Fable",
    value: 2,
  },
  {
    label: "Ballad",
    value: 3,
  },
  {
    label: "Story",
    value: 4,
  },
  {
    label: "Legend",
    value: 5,
  },
  {
    label: "Prose",
    value: 6,
  },
  {
    label: "Sonnet",
    value: 7,
  },
];

const verseStylesDe = [
  {
    label: "Haiku",
    value: 1,
  },
  {
    label: "Fabel",
    value: 2,
  },
  {
    label: "Ballade",
    value: 3,
  },
  {
    label: "Geschichte",
    value: 4,
  },
  {
    label: "Legende",
    value: 5,
  },
  {
    label: "Prosa",
    value: 6,
  },
  {
    label: "Sonett",
    value: 7,
  },
];

const verseStylesEs = [
  {
    label: "Haiku",
    value: 1,
  },
  {
    label: "Fábula",
    value: 2,
  },
  {
    label: "Balada",
    value: 3,
  },
  {
    label: "Cuento",
    value: 4,
  },
  {
    label: "Leyenda",
    value: 5,
  },
  {
    label: "Prosa",
    value: 6,
  },
  {
    label: "Soneto",
    value: 7,
  },
];

const verseStylesFr = [
  {
    label: "Haïku",
    value: 1,
  },
  {
    label: "Fable",
    value: 2,
  },
  {
    label: "Ballade",
    value: 3,
  },
  {
    label: "Histoire",
    value: 4,
  },
  {
    label: "Légende",
    value: 5,
  },
  {
    label: "Prose",
    value: 6,
  },
  {
    label: "Sonnet",
    value: 7,
  },
];

const verseStylesIt = [
  {
    label: "Haiku",
    value: 1,
  },
  {
    label: "Favola",
    value: 2,
  },
  {
    label: "Ballata",
    value: 3,
  },
  {
    label: "Racconto",
    value: 4,
  },
  {
    label: "Leggenda",
    value: 5,
  },
  {
    label: "Prosa",
    value: 6,
  },
  {
    label: "Sonetto",
    value: 7,
  },
];

const verseStylesNl = [
  {
    label: "Haiku",
    value: 1,
  },
  {
    label: "Fabel",
    value: 2,
  },
  {
    label: "Ballade",
    value: 3,
  },
  {
    label: "Verhaal",
    value: 4,
  },
  {
    label: "Legende",
    value: 5,
  },
  {
    label: "Proza",
    value: 6,
  },
  {
    label: "Sonnet",
    value: 7,
  },
];

export const poemsStyles: { [key: string]: StyleApiCalc[] } = {
  english: verseStylesEn,
  italian: verseStylesIt,
  french: verseStylesFr,
  german: verseStylesDe,
  spanish: verseStylesEs,
  dutch: verseStylesNl,
};
