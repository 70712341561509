import { Lang } from "@/types/lang";

export default <Lang>{
  question1: "Qué es AI Factory?",
  question2: "Cómo funciona AI Factory?",
  question3: "Qué servicios ofrece la plataforma?",
  question4: "Cómo puedo acceder a los servicios de AI Factory?",
  question5: "Qué son las Neuronas y cómo las consigo?",
  question6: "Cómo calcula la Plataforma el coste final de una generación de contenidos? ",
  question6_1: "¿Cuánto tardan en procesarse las transacciones?",
  question7: "Qué aplicaciones tiene AI Factory?",
  question8: "Cuál es la tecnología que hay detrás de AI Factory?",
  question9: "Qué idiomas puede procesar AI Factory?",
  question10: "Cómo garantiza la plataforma la calidad de los contenidos generados?",
  answer1:
    "AIFactory es una red neuronal autoconsciente combinada capaz de generar textos, reescribirlos, sugerir ideas creativas, producir imágenes ultrarrealistas y archivos de audio con voces realistas y mucho más.",
  answer2:
    "AIFactory utiliza redes neuronales autoconscientes de aprendizaje de última generación que tienen la capacidad de analizar y generar contenidos, y ser creativas para ofrecer ideas y soluciones únicas.",
  answer3:
    "Hay 3 secciones únicas en AI Factory:\n" +
    "WordCraft es una sección basada en texto que te permite parafrasear bloques de texto, generar ideas creativas, poemas y mucho más.\n" +
    "VoxAI es un servicio de conversión de texto a voz que contiene de 100 voces ultrarrealistas con las que crear contenidos de audio.\n" +
    "Neuro-Vision es una sección donde un usuario puede generar cualquier imagen en 17 estilos diferentes.",
  answer4:
    "Para acceder a los Servicios, primero debes registrar una cuenta y confirmar tu correo electrónico. Después de eso, puedes ir directamente al Servicio que te interese y utilizarlo, ¡sólo asegúrate de tener suficientes Neuronas!",
  answer5:
    "Hemos implementado un sistema de saldo en nuestra plataforma y las Neuronas son la moneda especial que se utiliza para acceder a los Servicios de AI Factory. Para obtenerlas, simplemente ve a tu perfil y haz clic en el botón 'Comprar Neuronas'.",
  answer6:
    "Hay un coste básico para cada Servicio. Por ejemplo, 1 imagen en Neuro-Vision cuesta 50 Neuronas, no hay opciones adicionales, por lo que el precio es estático. Para otros servicios como VoxAI, hay características adicionales como el idioma, el tamaño del texto y el tipo de voz. Todas estas opciones adicionales escalan el precio y nos aseguramos de que el coste final se muestre correctamente antes de que un usuario gaste sus Neuronas.",
  answer6_1:
    "Las transacciones se procesan normalmente en las 72 horas siguientes al pago. Una vez completado el pago, el saldo de su cuenta puede tardar hasta 24 horas en actualizarse, aunque el proceso suele ser instantáneo.",
  answer7:
    "AI Factory puede utilizarse para generar texto de calidad, aumentar y reescribir contenidos existentes, aportar ideas creativas, crear imágenes realistas y archivos de audio.",
  answer8:
    "AIFactory se basa en tecnologías avanzadas de inteligencia artificial, incluidas redes neuronales y aprendizaje profundo, y utiliza técnicas de generación y transformación de contenidos.",
  answer9:
    "AI Factory puede procesar texto en muchos idiomas, incluidos, entre otros, inglés, neerlandés, español, francés, alemán, italiano y otros idiomas populares.",
  answer10:
    "AI Factory utiliza modelos y algoritmos avanzados que se entrenan con grandes cantidades de datos y están equipados con mecanismos de control de calidad para garantizar la generación de contenidos de alta calidad, aunque no podemos garantizar un resultado preciso al 100%.",
};
