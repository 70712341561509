import {
  SPEECH_BASE_PRICE,
  SPEECH_DEFAULT_CHARS_QNT,
  SPEECH_EXTRA_CHARS_PRICE,
  SPEECH_EXTRA_CHARS_QNT,
  SPEECH_MULTIPLIER,
} from "@/constants/priceCalculation";

export const getTextToSpeechPrice = (content: string): number => {
  //TODO: waiting for SPEECH_MULTIPLIER_REAL_VOICE from customer
  const x = SPEECH_BASE_PRICE;
  const defCharsQnt = SPEECH_DEFAULT_CHARS_QNT;
  const extraCharsQnt = SPEECH_EXTRA_CHARS_QNT;
  const extraCharsPrice = SPEECH_EXTRA_CHARS_PRICE;
  const multiplier = SPEECH_MULTIPLIER;

  const len = content.length;
  const extraLen = len - defCharsQnt <= 0 ? 0 : len - defCharsQnt;
  const extraQnt = Math.ceil(extraLen / extraCharsQnt);
  const y = extraCharsPrice * extraQnt;

  return x + x * multiplier + y;
};
