import IllestWaveform from "1llest-waveform-vue";
// import { createGtm, VueGtmUseOptions } from '@gtm-support/vue-gtm';
import PrimeVue from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";
import unoverlay from "unoverlay-vue";
import { createApp } from "vue";

import "1llest-waveform-vue/lib/style.css";
import { i18n } from "@/i18n";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/scss/themenew.css";

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(PrimeVue)
  .use(unoverlay)
  .use(ConfirmationService)
  .use(ToastService)
  .use(IllestWaveform)
  // .use(createGtm({ id: process.env.VUE_APP_GTM_ID, vueRouter: router }))
  // Vue.use(createGtm({ id: process.env.VUE_APP_GTM_ID || '', vueRouter: router, }))
  .mount("#app");
