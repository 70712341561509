import { Lang } from "@/types/lang";

export default <Lang>{
  termsHeading1: "Condiciones de uso",
  termsHeading2: "Garantía y responsabilidad",
  termsHeading3: "El Contenido",
  termsHeading4: "Contenido restringido",
  termsHeading5: "Países restringidos",
  termsHeading6: "Procedimiento de pago",
  termsHeading7: "Política de reembolso",
  termsHeading8: "Servicios de terceros",
  termsHeading9: "Derechos de autor",
  termsHeading10: "Rescisión",

  terms1:
    'Antes de utilizar los Servicios prestados por AI Factory (la "Empresa", "Nosotros", "Nos", "la Plataforma", el/los "Servicio(s)" o "el Sitio web"), se recomienda leer los siguientes Términos y condiciones ("Acuerdo"). Si accede al sitio web o a los servicios de cualquier forma, reconoce que ha leído, entendido y aceptado los Términos y Condiciones del presente Acuerdo, aceptando quedar vinculado por las limitaciones de todos los demás términos. El incumplimiento de cualquiera de los Términos y Condiciones de este acuerdo tiene como consecuencia la interrupción de cualquier derecho a utilizar la Plataforma o los Servicios. Si no está de acuerdo con estas Condiciones, le rogamos que NO utilice el Sitio Web.\n' +
    "Aifactorysolution.com es propiedad y está gestionado por Techfox Group LTD.\n" +
    "Techfox Group LTD. Está regulada por las leyes de la República de Chipre como una sociedad de responsabilidad limitada, con domicilio social en 22A Agias Zonis, oficina 101, 3027 Limassol, Chipre.\n" +
    "Número de registro: HE 436440\n" +
    "Número de teléfono: +357 968 78 662\n" +
    "Correo electrónico: contact{'@'}aifactorysolution.com\n" +
    "Condiciones de uso\n" +
    "Usted nos garantiza que\n" +
    "- Es mayor de edad en su país de residencia;\n" +
    "- Tiene capacidad para celebrar un contrato vinculante y no tiene prohibido por ley utilizar o acceder a los Servicios;\n" +
    "- Que la información que nos facilita es exacta y veraz;\n" +
    "- Usted está legalmente autorizado a utilizar cualquier método de pago, incluyendo tarjeta de crédito o débito, para la realización de cualquier pedido. (Al proporcionar dicha información, usted reconoce y nos autoriza a compartir la información con terceros que procesan pagos para facilitar la realización de su pedido. Si sospechamos que se ha producido un fraude o una transacción no autorizada o ilegal, nos reservamos el derecho a rechazar o cancelar su pedido en cualquier momento por determinados motivos, incluida la falta de disponibilidad del Servicio, errores técnicos o precios incorrectos).\n" +
    "En las jurisdicciones en las que la ley lo prohíba, el presente Acuerdo perderá su validez y se revocará el derecho de acceso a los Servicios.",
  terms2:
    "La Plataforma se actualiza continuamente con respecto a la disponibilidad, errores e inexactitudes de Nuestros Servicios. Nuestra Plataforma puede tener Servicios con precios inexactos, descritos como obsoletos o no disponibles en todo momento, y Nosotros podemos experimentar retrasos en la actualización de la información sobre nuestro Servicio en la Plataforma y en la publicidad en otros sitios web.\n" +
    "Estamos autorizados a modificar o revisar la información sin previo aviso, incluyendo cualquier inexactitud u omisión. Tanto si utiliza una tarjeta de crédito como de débito, el pago puede realizarse con la tarjeta que se le haya emitido y el emisor de la tarjeta la validará. En caso de que no podamos obtener la autorización necesaria, no seremos responsables de ningún retraso o falta de entrega de su pedido.\n" +
    "Usted tiene la obligación de mantener la confidencialidad al compartir su contraseña con otras personas. Si descubre que su cuenta ha sido pirateada o utilizada con fines ilegales, debe notificárnoslo inmediatamente.\n" +
    "Es su responsabilidad asegurarse de que el Contenido creado utilizando Nuestro(s) Servicio(s) cumple(n) los requisitos legales, incluyendo su corrección y adecuación. La Empresa le concede el derecho y la licencia para utilizar, modificar, exhibir, copiar (en su totalidad), reproducir y distribuir el Contenido creado a través de nuestra plataforma. La Empresa le concede derechos completos sobre todo el Contenido generado por usted, lo que significa que usted es responsable de proteger y conservar dichos derechos.\n" +
    "Usted utilizará el Servicio por su cuenta y riesgo. Usted reconoce que el Servicio puede contener contenido que pueda considerarse ofensivo, indecente, incorrecto o censurable, y acepta que no existe responsabilidad alguna por los errores o daños resultantes de su uso de nuestra Plataforma. Si incurre en conductas fraudulentas, abusivas o ilegales mientras utiliza los Servicios, su cuenta podrá ser cancelada. Queda prohibido publicar o transmitir cualquier mensaje o solicitud que tenga por objeto obtener contraseñas, cuentas o información privada de otros usuarios de la Plataforma. Queda expresamente prohibido utilizar los Servicios con el fin de vulnerar la seguridad de cualquier red informática, descifrar contraseñas o códigos de cifrado de seguridad, o transferir o almacenar material ilegal (incluidos contenidos potencialmente dañinos o ilegales), así como participar en cualquier actividad ilegal.\n" +
    "El código fuente de la Plataforma no será descompilado, sometido a ingeniería inversa ni obtenido por ningún otro medio. La Empresa realiza copias de seguridad periódicas de los contenidos, pero éstas no garantizan una protección del 100% frente a la corrupción o pérdida de datos. La Empresa realizará esfuerzos y soporte técnico para resolver cualquier problema que pueda surgir, ya sea conocido o descubierto, que afecte a las copias de seguridad de los Contenidos. La Empresa no se hace responsable de la integridad de los Contenidos ni de su imposibilidad de restaurarlos con éxito a un estado utilizable.",
  terms3:
    "Nuestro Servicio le permite generar Contenido de Audio, Texto e Imagen. Usted es responsable del Contenido que genere utilizando Nuestros Servicios, incluyendo su legalidad y adecuación.\n" +
    "Al generar Contenido en la Plataforma, nos concede el derecho y la licencia para utilizar, modificar, ejecutar públicamente, mostrar públicamente, reproducir y distribuir dicho Contenido en y a través del Servicio. Usted conserva todos y cada uno de sus derechos sobre cualquier Contenido que cree, publique o muestre en o a través del Servicio y es responsable de proteger dichos derechos.\n" +
    "El uso de nuestros Servicios implica que usted entiende que la Empresa no puede garantizar que el contenido generado sea 100% correcto, de la mejor calidad o totalmente veraz y, por lo tanto, no estará sujeto a ningún reembolso o consulta sobre la calidad final del contenido.",
  terms4:
    "Usted es consciente y acepta que es totalmente responsable del Contenido, así como de todas las actividades asociadas a su cuenta, tanto si las realiza usted como si las realiza otro usuario con acceso a su cuenta.\n" +
    "Se le prohíbe crear o transmitir cualquier Contenido que sea ilegal, ofensivo, vergonzoso, que pretenda ser repugnante, amenazador, difamatorio, indecente, ilegal u ofensivo de cualquier otro modo. La siguiente lista de Contenidos puede considerarse restringida, pero no se limita a:\n" +
    "- Participar en una actividad ilegal o fomentar dicho comportamiento ilegal;\n" +
    "- Utilizar la Plataforma para crear materiales difamatorios, discriminatorios y ofensivos, incluyendo cualquier mención u observación relativa a religión, raza, orientación sexual/identidad de género, origen nacional/étnico u otros grupos;\n" +
    "- Utilizar el Servicio para promocionar actividades prohibidas, como el consumo de sustancias/drogas ilegales y servicios para adultos;\n" +
    "- Generar spam, tareas generadas por máquinas o asignadas al azar, publicidad no autorizada o no solicitada, cartas en cadena;\n" +
    "- Cualquier otra forma de solicitación no segura, o cualquier tipo de lotería o juego;\n" +
    "- Utilizar el Servicio para crear o desarrollar virus, malwares, troyanos o material similar diseñado para causar interrupciones, daños o interferencias en el funcionamiento de cualquier software;\n" +
    "- Infringir los derechos de propiedad intelectual de cualquier parte, incluidos, entre otros: patentes, marcas comerciales o secretos comerciales, así como derechos de autor y derechos de publicidad;\n" +
    "- Hacerse pasar por otras personas, incluidos empleados y representantes de la Empresa;\n" +
    "- Poner en peligro la privacidad de otras personas.\n" +
    "A menos que se acuerde lo contrario, la Empresa tiene autoridad para rechazar o eliminar contenidos sexualmente explícitos y tomar decisiones sobre si son apropiados para su uso o si cumplen estas Condiciones. La Empresa tiene libertad para modificar la forma en que se presenta el Contenido y realizar cambios. La Empresa está facultada para restringir o revocar el Servicio si usted lo utiliza para crear Contenido que se considere ofensivo. La Empresa no se hace responsable de los contenidos publicados por usuarios y/o terceros en otras plataformas.",
  terms5:
    "Para utilizar los Servicios, el usuario debe confirmar su dirección de correo electrónico. Sin embargo, el uso de los Servicios está restringido para los residentes de los siguientes países: Afganistán, Albania, Barbados, Burkina Faso, Camboya, Islas Caimán, República Democrática del Congo, Gibraltar, Haití, Jamaica, Jordania, Mali, Marruecos, Mozambique. , Nicaragua, Pakistán, Panamá, Filipinas, Rusia, Senegal, Sudán del Sur, Siria, Tanzania, Trinidad y Tobago, Turquía, Uganda, Emiratos Árabes Unidos, Vanuatu, Yemen, Zimbabwe Corea del Norte (República Popular Democrática de Corea), Irán, Myanmar .",
  terms6:
    'Para poder acceder a los Servicios, debe disponer de una cantidad suficiente del saldo interno de la Plataforma - "Neuronas"- en su cuenta Wallet. Las "Neuronas" ofrecen a los usuarios un acceso flexible a los servicios disponibles en la plataforma. Puede consultar su saldo y recargarlo en la sección correspondiente del sitio web. Las Neuronas no tienen valor fuera de la Plataforma, sólo pueden utilizarse en la Plataforma y no pueden transferirse a otra plataforma o a otra persona. 10 Neuronas equivalen a 1 Euro.\n' +
    "Las transacciones se procesan en las 72 horas siguientes al pago.\n" +
    'Una vez completado el pago, su saldo puede tardar hasta 24 horas en actualizarse, aunque normalmente este proceso es instantáneo. Tenemos derecho a utilizar un procesador de servicios de pago de terceros (el "Procesador de Pagos") para sus depósitos. El procesador de pagos correspondiente es el principal responsable de facilitar el pago de sus transacciones. Para poder procesar su transacción, el procesador de pagos necesita sus datos personales y sus datos de pago, como los datos de su tarjeta de débito/crédito, su dirección y otra información. Esta información sólo se utiliza para procesar las transacciones y no se revela a terceros, con la única excepción de las instituciones financieras y reguladoras implicadas en los procedimientos de procesamiento o cumplimiento. Puede obtener más información sobre el uso de sus datos personales en Nuestra política de privacidad.\n' +
    "Nos reservamos el derecho a verificar y aprobar todas y cada una de las transacciones realizadas por usted al utilizar el Servicio para garantizar que cumplen con estas Condiciones y con las condiciones impuestas a la Plataforma por terceros proveedores de servicios. Usted reconoce y acepta expresamente que dichas verificaciones pueden requerir que nos proporcione información personal adicional con el fin de verificar y confirmar su identidad y realizar verificaciones destinadas a disuadir el fraude y el uso indebido del Servicio, de conformidad con Nuestra Política de Privacidad y todas las leyes y reglamentos aplicables.\n" +
    "Al proceder con una transacción, usted certifica que todas las tarjetas bancarias y otras cuentas de pago que utiliza para las transacciones son legalmente suyas.",
  terms7:
    "No garantizamos ningún tipo de reembolso si se ha prestado el servicio de pago. No tiene derecho a ningún reembolso por las tasas o comisiones de transacción que ya haya abonado.\n" +
    "Los reembolsos sólo son posibles para las transacciones realizadas para comprar Neuronas y sólo en caso de errores técnicos con el Servicio proporcionado en el Sitio Web y/u otros problemas derivados de Nuestros propios problemas que le hayan impedido recibir los Servicios por los que ha pagado.\n" +
    '"Problemas técnicos" se definen como problemas significativos de rendimiento, caídas del sistema o la imposibilidad de acceder a funciones esenciales de nuestra plataforma debido a errores atribuibles a nuestros sistemas. No se concederán reembolsos en caso de sospecha de fraude o uso indebido de nuestros Servicios. La empresa se reserva el derecho a investigar a fondo cualquier reclamación y a emprender las acciones legales pertinentes si fuera necesario para proteger nuestros intereses y los de nuestros usuarios legítimos.\n' +
    "La solicitud de reembolso debe enviarse a través de contact{'@'}aifactorysolution.com en un plazo máximo de 24 horas desde la fecha de la transacción. Las solicitudes de reembolso se tramitarán en un plazo de 14 días hábiles a partir de la fecha de envío.\n" +
    "Los usuarios deben proporcionar descripciones detalladas, capturas de pantalla, mensajes de error o cualquier documentación pertinente que justifique sus reclamaciones sobre los problemas técnicos experimentados. Las solicitudes podrán denegarse si no cumplen los criterios descritos, incluidas pruebas insuficientes de problemas técnicos o casos de fraude o uso indebido.\n" +
    "No se efectuarán reembolsos en los casos en los que el usuario esté simplemente insatisfecho con el producto, el servicio o la calidad del contenido generado sin razones técnicas válidas.",
  terms8:
    "Los enlaces de Nuestra Plataforma pueden conducir a sitios web o servicios de terceros que no son propiedad de la Empresa. Los sitios web o servicios de terceros pueden estar vinculados a la Empresa, pero ésta no es responsable de su contenido, políticas de privacidad o prácticas. Usted reconoce y acepta que la Empresa no será responsable de ningún daño o pérdida causados por o en relación con el uso o la confianza depositada en dichos contenidos, bienes o servicios ofrecidos a través de sitios web u otros servicios. Le recomendamos encarecidamente que lea las condiciones de uso, así como las políticas de privacidad, de los sitios web o servicios de terceros a los que acceda.",
  terms9:
    "El principio de derechos de autor nos obliga a reconocer y respetar los derechos de propiedad intelectual de terceros. Nuestra política es atender cualquier reclamación de que el Contenido publicado en el Servicio infringe los derechos de propiedad intelectual o los derechos de autor de terceros. En caso de que crea que el Servicio está facilitando la infracción de los derechos de autor, y usted es el propietario de los derechos de autor o está autorizado en su nombre, envíenos un correo electrónico a contact{'@'}aifactorysolution.com para enviar su notificación. Por favor, incluya en su notificación una explicación detallada de la supuesta infracción. La admisión de una falsedad en cuanto a que cualquier Contenido infringe sus derechos de autor puede dar lugar a que usted deba responder por daños y perjuicios, incluidos costes y honorarios de abogados.",
  terms10:
    "Estamos facultados para suspender o cancelar su Cuenta sin previo aviso ni responsabilidad alguna, incluso aunque no infrinja las Condiciones. Esto se aplica independientemente del motivo. Su uso del Servicio finalizará cuando elimine su cuenta. Si desea eliminar su Cuenta de la Plataforma, puede simplemente dejar de utilizar el Servicio. El presente Acuerdo continuará en pleno vigor y efecto mientras esté utilizando los Servicios. En cualquier momento, la Empresa está facultada para cancelar o suspender su acceso a los Servicios. En caso de incumplimiento de cualquiera de los términos y condiciones del presente Contrato, la Empresa podrá suspender o cancelar inmediatamente todos los Servicios y el acceso al Sitio Web sin responsabilidad alguna ni previo aviso.\n" +
    "Todas las disposiciones del presente Acuerdo que permanezcan en vigor tras la rescisión son esenciales, incluidas las disposiciones sobre propiedad y las limitaciones de responsabilidad. Estas limitaciones también se aplican a las limitaciones de responsabilidad. La Empresa, sus empresas matrices, subsidiarias, filiales y empleados están totalmente obligados a defenderse contra cualquier reclamación, responsabilidad, liquidación/coste de indemnización, incluidos los honorarios de abogados o daños y perjuicios derivados de su uso de los Servicios, la violación de este Acuerdo, o la infracción por usted o por otra parte de su propiedad intelectual u otros derechos.\n" +
    "Si alguna disposición de estas Condiciones se considera inaplicable o inválida, las demás disposiciones se modificarán e interpretarán para lograr el propósito previsto según la legislación aplicable sin ser sustituidas. A menos que se acuerde lo contrario, la inaplicación de cualquier derecho u obligación en virtud de estas Condiciones no afectará a la capacidad de cualquiera de las partes para exigir el cumplimiento del presente Acuerdo. La versión original en inglés de las Condiciones será la ley que prevalezca en cualquier disputa.\n" +
    "Estas Condiciones están sujetas a cambios y pueden ser modificadas o sustituidas por nosotros a nuestra entera discreción. Usted reconoce y acepta quedar vinculado por las Condiciones revisadas si continúa accediendo o utilizando Nuestro Servicio después de que dichas revisiones entren en vigor. Si no está de acuerdo con las nuevas condiciones, le rogamos que deje de utilizar el sitio web y el Servicio.\n" +
    "Gracias por elegir AI Factory. Si tiene alguna duda sobre las presentes Condiciones, póngase en contacto con nosotros en contact{'@'}aifactorysolution.com.",
  updated: "Última actualización el 01 ago 2024",
};
