import { Lang } from "@/types/lang";

export default <Lang>{
  privacyHeading1: "Privacy Policy",
  privacyHeading2: "The purpose of gathering your information",
  privacyHeading3: "Cookies",
  privacyHeading4: "Disclosure of data",
  privacyHeading5: "Retention Period",
  privacyHeading6: "Data Protection",
  privacyHeading7: "GDPR",
  privacyHeading8: "Links to third-party Websites",
  privacy1:
    "The Company's goal is to maintain the confidentiality of Our customers. This Privacy Policy outlines the methods by which Our Platform will collect, use, share, store, protect, or disclose your Personal Information or Usage Information.\n" +
    "The use of Our Services implies your acceptance and consent to be subject to this Privacy Policy. You are obligated to discontinue using Our Services if you do not comply with the Privacy Policy.\n\n" +
    "Techfox Group LTD. Is regulated under the laws of the Republic of Cyprus as a private limited company, with registered office at 22A Agias Zonis, office 101, 3027 Limassol, Cyprus\n" +
    "Registration number: HE 436440\n" +
    "Phone Number: +357 968 78 662\n" +
    "E-mail: contact{'@'}aifactorysolution.com",
  privacy2:
    "The purposes for which we gather your information are listed below, and they include but not limited to:\n" +
    "\t•\tRegistering and managing your account;\n" +
    "\t•\tCommunicating with you directly;\n" +
    "\t•\tProviding information about Us, publishing reviews, forum posts, or content; \n" +
    "\t•\tResponding to your inquiries;\n" +
    "\t•\tComplying with applicable legal provisions.\n" +
    "We gather information about your computer or mobile device while you are using Our Services and use it to analyse your data. The data We gather includes computer IP addresses, mobile device IDs and technical details about your computer or mobile device.",
  privacy3:
    "“Cookies”, which are small files that are stored on your computer or mobile device, allow Us to track the usage of Our Services and identify when you visit the website. \n" +
    "Modifying your browser or mobile device preferences can prevent cookies from being accepted on your computer or device, which may impede the use of certain features that are available on Our Platform. You acknowledge and agree that this Privacy Policy does not encompass the use of cookies by third parties.",
  privacy4:
    "Our policy prohibits the transfer of your Personal Information to third parties for the purpose of monetizing through advertising or sales. We may share your Personal Information with third parties, including those who provide services for such purposes as business analytics, customer service, marketing, distribution and payment processing. Vendors have the authority to gather data for Us to manage our application features or provide customized services. We make it clear that the information provided by third-party vendors is exclusively used for their own purposes and cannot be shared or exploited for any other purpose.\n" +
    "We may disclose your Personal Information and Usage Information to government entities when it is necessary, such as in response to a court order or government demand. If We are required to comply with a legal request, an email notification will be sent to your inbox.",
  privacy5:
    "The amount of time your personal data will be kept is determined by the Company based on the required time to comply with the Privacy Policy and may vary. We will maintain and utilize your Data as necessary to fulfill Our legal obligations, resolve disputes, and enforce Our agreements and policies. The usual storage time however, is 1 (one) year since the last provision (or update) of data by user.",
  privacy6:
    "We want you to trust Us and have the confidence that your information is protected. We adhere to the standard safeguarding measures for Personal Information and Usage Information that are commonly accepted. Although no Services can guarantee complete security, We have the necessary administrative, technical, and security measures to ensure the protection of your information. Authorized personnel, agents, third-party vendors, and other entities only use Our data for the required purposes. We use appropriate firewalls to secure it against unauthorized usage, as well as encryption technologies and intrusion detection systems.\n" +
    "The Company will take all necessary steps to ensure that your data is treated securely and in accordance with this Privacy Policy. No Personal Data will be transferred internationally unless adequate controls are in place to safeguard your data and other information.",
  privacy7:
    "We comply with General Data Protection Regulation (GDPR) in regards to processing of your Personal Data. We may process Personal Data under the following conditions:\n" +
    "\t•\tYou have given your consent for processing Personal Data for one or more specific purposes.\n" +
    "\t•\tCompliance with a legal obligation to which the Company is subject.\n" +
    "\t•\tVital interests: Processing Personal Data is necessary in order to protect your vital interests or of another natural person.\n" +
    "\t•\tPublic interests: Processing Personal Data is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Company.\n" +
    "\t•\tLegitimate interests: Processing Personal Data is necessary for the purposes of the legitimate interests pursued by the Company.\n" +
    "In any situation, the Company will happily assist in clarifying the exact legal basis that applies to the processing, including whether the supply of Personal Data is a statutory or contractual necessity, or a prerequisite to enter into a contract.\n" +
    "The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee you can exercise your rights. You have the right under this Privacy Policy, and by law to:\n" +
    "\t•\tRequest access to your Personal Data;\n" +
    "\t•\tRequest correction of the Personal Data that We hold about you;\n" +
    "\t•\tObject to processing of your Personal Data;\n" +
    "\t•\tRequest erasure of your Personal Data. You have the right to ask Us to delete or remove Personal Data when there is no good reason for Us to continue processing it;\n" +
    "\t•\tRequest the transfer of your Personal Data;\n" +
    "\t•\tWithdraw your consent for processing personal information.",
  privacy8:
    "Our Platform may contain links to other websites that are not operated by the Company. If you click on a third-party link, you will be directed to that third-party's website. We strongly advise you to review the privacy policy of every website you visit.\n" +
    "We have no control over and assume no responsibility for the content, privacy policies or practices of any third-party websites or services.",
  privacy9:
    "\n" +
    "This Privacy Policy may be updated or changed at any time without prior notice, so We suggest you check this page occasionally from time to time. If you have any questions about this Privacy Policy, you can contact Us at contact{'@'}aifactorysolution.com",
};
