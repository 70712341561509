import { AxiosError } from "axios";
import { isArray } from "lodash";

import { RequestMethod, useApi } from "@/api";
import { API_ROUTES } from "@/api/apiRoutes";
import { i18n } from "@/i18n";
import { useAppStore } from "@/store/app/appStore";
import { HookConfig } from "@/types/api";

export type UserPayment = {
  amount_count: number;
};

export const useUserPayment = async <R>(body: UserPayment, config?: HookConfig): Promise<string | null> => {
  const appStore = useAppStore();
  const { updateIsLoading } = appStore;
  const { request, data } = useApi<R>({
    path: API_ROUTES.PAYMENT as string,
    method: RequestMethod.Post,
    body,
  });

  if (!config?.noLoader) {
    updateIsLoading(true);
  }

  try {
    await request();

    if (data) {
      localStorage.setItem("payment_session_id", data.value.session_id);
      return data.value.url;
    }
  } catch (e) {
    let errorMessage = i18n.global.t("error.somethingWentWrong");
    if (e instanceof AxiosError) {
      errorMessage = isArray(e.response?.data.message) ? e.response?.data.message[0] : e.response?.data.message;
    }
    console.error(errorMessage);
  } finally {
    if (!config?.noLoader) {
      updateIsLoading(false);
    }
  }
  return null;
};
