import { VERSE_HAIKU_SIZE_1_PRICE, VERSE_SIZE_2_BASE_PRICE, VERSE_SONNET_PRICE } from "@/constants/priceCalculation";

export enum VerseStyleEnum {
  HAIKU = "haiku",
  FABLE = "fable",
  BALLAD = "ballad",
  STORY = "story",
  LEGEND = "legend",
  PROSE = "prose",
  SONNET = "sonnet",
}

export const getVersePrice = (stanzaCount: number, stanzaSize: number, style: string): number => {
  switch (style) {
    case VerseStyleEnum.HAIKU:
      return stanzaCount * VERSE_HAIKU_SIZE_1_PRICE;

    case VerseStyleEnum.SONNET:
      return VERSE_SONNET_PRICE;

    default: {
      const oneStanzaPrice = (stanzaSize / 2) * VERSE_SIZE_2_BASE_PRICE;
      return stanzaCount * oneStanzaPrice;
    }
  }
};
