import { Lang } from "@/types/lang";

export default <Lang>{
  registered: "Compruebe su correo electrónico para obtener confirmación",
  login: "Conectado correctamente",
  logout: "Se ha desconectado correctamente",
  resetPassword: "La contraseña se ha restablecido correctamente",
  poem: "Poema generado con éxito",
  textParaphrased: "Texto parafraseado con éxito",
  creativeText: "Texto creativo generado correctamente",
  imageGenerated: "Imagen generada correctamente",
  registrationFinished: "Registro finalizado con éxito",
  textCopied: "Texto copiado",
  balance: "Saldo actualizado",
  audioGenerated: "Audio generado correctamente",
};
