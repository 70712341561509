import { Lang } from "@/types/lang";

export default <Lang>{
  transactions: "Transacciones",
  description: "Descripción",
  neurons: "Neurons",
  date: "Fecha",
  howToUse: "Cómo utilizarlo:",
  profile: "Perfil",
  password: "Contraseña",
  generatingPoems: "Generar poemas",
  stanzaCount: "Recuento de estrofas",
  stanzaSize: "Tamaño de la habitación",
  paraphrasing: "Parafraseando",
  style: "Estilo",
  generatorImages: "Generador de imágenes",
  imageService: "Servicios de imagen",
  generatingImages: "Generación de imágenes",
  creative: "Creativo",
  contactUs: "Contacto",
  phone: "Teléfono",
  registrationNumber: "Número de registro",
  whereWeAreLocated: "Nuestra ubicación",
  newPassword: "Nueva contraseña",
  confirmPassword: "Confirmar contraseña",
  audioService: "Servicios de audio",
  generatingAudio: "Generación de texto a audio",
  generatorAudio: "Generación de audio",
  buyNeurons: "Comprar Neurons",
  swap: "INTERCAMBIO",
  enterSubject: "Ingresar Asunto",
  selectStyle: "Seleccionar estilo",
  pleaseLogin: "Inicie sesión para continuar",
  instructions: "Instrucciones",
  selectTheme: "Seleccione el tema",
  resetPassword: "Restablecer la contraseña",
  noNeurons: "Tu saldo es bajo. Por favor, compre Neurons para continuar.",
  voiceSelection: "Selección de voz",
  generation: "Generación",
  enterText: "Introducir texto",
  privacyPolicy: "Política de privacidad",
  termsAndConditions: "Condiciones generales",
};
