import { Lang } from "@/types/lang";

export default <Lang>{
  privacyHeading1: "Politique de confidentialité",
  privacyHeading2: "L'objectif de la collecte de vos informations",
  privacyHeading3: "Cookies",
  privacyHeading4: "Divulgation des données",
  privacyHeading5: "Période de conservation",
  privacyHeading6: "Protection des données",
  privacyHeading7: "GDPR",
  privacyHeading8: "Liens vers des sites web de tiers",
  privacy1:
    "L'objectif de la société est de préserver la confidentialité de ses clients. La présente politique de confidentialité décrit les méthodes par lesquelles notre plate-forme recueillera, utilisera, partagera, stockera, protégera ou divulguera vos informations personnelles ou vos informations d'utilisation.\n" +
    "L'utilisation de nos services implique votre acceptation et votre consentement à être soumis à cette politique de confidentialité. Vous êtes tenu de cesser d'utiliser nos services si vous ne vous conformez pas à la politique de protection de la vie privée.\n\n" +
    "Techfox Group LTD. Est réglementée par les lois de la République de Chypre en tant que société à responsabilité limitée, dont le siège social est situé à 22A Agias Zonis, office 101, 3027 Limassol, Cyprus\n" +
    "Numéro d'enregistrement: HE 436440\n" +
    "Numéro de téléphone: +357 968 78 662\n" +
    "E-mail: contact{'@'}aifactorysolution.com",
  privacy2:
    "Les raisons pour lesquelles nous recueillons vos informations sont énumérées ci-dessous :\n" +
    "\t•\tEnregistrement et gestion de votre compte ;\n" +
    "\t•\tCommuniquer directement avec vous ;\n" +
    "\t•\tFournir des informations sur nous, publier des critiques, des messages sur les forums ou du contenu ; \n" +
    "\t•\tRépondre à vos demandes ;\n" +
    "\t•\tRespecter les dispositions légales applicables.\n" +
    "Nous recueillons des informations sur votre ordinateur ou votre appareil mobile lorsque vous utilisez nos services et nous les utilisons pour analyser vos données. Les données que nous recueillons comprennent les adresses IP des ordinateurs, les identifiants des appareils mobiles et les détails techniques concernant votre ordinateur ou votre appareil mobile.",
  privacy3:
    'Les "cookies", qui sont de petits fichiers stockés sur votre ordinateur ou votre appareil mobile, nous permettent de suivre l\'utilisation de nos services et de savoir quand vous visitez le site web. \n' +
    "La modification des préférences de votre navigateur ou de votre appareil mobile peut empêcher les cookies d'être acceptés sur votre ordinateur ou votre appareil, ce qui peut entraver l'utilisation de certaines fonctionnalités disponibles sur Notre Plateforme. Vous reconnaissez et acceptez que la présente politique de confidentialité n'englobe pas l'utilisation de cookies par des tiers.",
  privacy4:
    "Notre politique interdit le transfert de vos informations personnelles à des tiers dans le but de les monétiser par le biais de la publicité ou de la vente. Nous pouvons partager vos informations personnelles avec des tiers, y compris ceux qui fournissent des services à des fins d'analyse commerciale, de service à la clientèle, de marketing, de distribution et de traitement des paiements. Les fournisseurs sont habilités à collecter des données pour nous afin de gérer les fonctionnalités de notre application ou de fournir des services personnalisés. Nous précisons que les informations fournies par les vendeurs tiers sont exclusivement utilisées à leurs propres fins et ne peuvent être partagées ou exploitées à d'autres fins.\n" +
    "Nous pouvons divulguer vos informations personnelles et vos informations d'utilisation à des entités gouvernementales lorsque cela est nécessaire, par exemple en réponse à une décision de justice ou à une demande du gouvernement. Si nous sommes tenus de nous conformer à une demande légale, une notification par courrier électronique sera envoyée à votre boîte de réception.",
  privacy5:
    "La durée de conservation de vos données personnelles est déterminée par la Société en fonction du temps nécessaire pour se conformer à la politique de confidentialité et peut varier. Nous conserverons et utiliserons vos données si nécessaire pour remplir nos obligations légales, résoudre les litiges et appliquer nos accords et politiques. La durée de conservation habituelle est toutefois de 1 (un) an à compter de la dernière fourniture (ou mise à jour) de données par l'utilisateur.",
  privacy6:
    "Nous voulons que vous nous fassiez confiance et que vous ayez l'assurance que vos informations sont protégées. Nous adhérons aux mesures de protection des informations personnelles et des informations relatives à l'utilisation qui sont communément acceptées. Bien qu'aucun service ne puisse garantir une sécurité totale, nous disposons des mesures administratives, techniques et de sécurité nécessaires pour assurer la protection de vos informations. Le personnel autorisé, les agents, les fournisseurs tiers et les autres entités n'utilisent nos données qu'aux fins requises. Nous utilisons des pare-feu appropriés pour les protéger contre toute utilisation non autorisée, ainsi que des technologies de cryptage et des systèmes de détection d'intrusion.\n" +
    "L'Entreprise prendra toutes les mesures nécessaires pour s'assurer que vos données sont traitées en toute sécurité et conformément à la présente politique de confidentialité. Aucune donnée à caractère personnel ne sera transférée au niveau international à moins que des contrôles adéquats ne soient mis en place pour protéger vos données et autres informations.",
  privacy7:
    "Nous nous conformons au Règlement général sur la protection des données (RGPD) en ce qui concerne le traitement de vos données personnelles. Nous pouvons traiter les données personnelles dans les conditions suivantes :\n" +
    "\t•\tVous avez donné votre consentement au traitement des données à caractère personnel pour une ou plusieurs finalités spécifiques.\n" +
    "\t•\tle respect d'une obligation légale à laquelle l'entreprise est soumise.\n" +
    "\t•\tIntérêts vitaux : Le traitement des données à caractère personnel est nécessaire pour protéger vos intérêts vitaux ou ceux d'une autre personne physique.\n" +
    "\t•\tIntérêts publics : Le traitement des données à caractère personnel est lié à une tâche effectuée dans l'intérêt public ou dans l'exercice de l'autorité officielle dont est investie la société.\n" +
    "\t•\tIntérêts légitimes : Le traitement des données à caractère personnel est nécessaire aux fins des intérêts légitimes poursuivis par la société.\n" +
    "Dans tous les cas, la société aidera volontiers à clarifier la base juridique exacte qui s'applique au traitement, y compris si la fourniture de données à caractère personnel est une nécessité légale ou contractuelle, ou une condition préalable à la conclusion d'un contrat.\n" +
    "La Société s'engage à respecter la confidentialité de vos données personnelles et à vous garantir l'exercice de vos droits. Vous avez le droit, en vertu de la présente politique de protection de la vie privée et de la loi, de :\n" +
    "\t•\tDemander l'accès à vos données personnelles ;\n" +
    "\t•\tDemander la correction des données personnelles que nous détenons à votre sujet ;\n" +
    "\t•\tS'opposer au traitement de vos données personnelles ;\n" +
    "\t•\tDemander l'effacement de vos données personnelles. Vous avez le droit de nous demander d'effacer ou de supprimer des données à caractère personnel lorsque nous n'avons aucune raison valable de continuer à les traiter ;\n" +
    "\t•\tDemander le transfert de vos données personnelles ;\n" +
    "\t•\tRetirer votre consentement au traitement des informations personnelles.",
  privacy8:
    "Notre Plateforme peut contenir des liens vers d'autres sites web qui ne sont pas exploités par la Société. Si vous cliquez sur le lien d'un tiers, vous serez dirigé vers le site web de ce tiers. Nous vous conseillons vivement de consulter la politique de confidentialité de chaque site web que vous visitez.\n" +
    "Nous n'avons aucun contrôle et n'assumons aucune responsabilité quant au contenu, aux politiques de confidentialité ou aux pratiques des sites web ou services de tiers.",
  privacy9:
    "La présente politique de confidentialité peut être mise à jour ou modifiée à tout moment sans avis préalable, c'est pourquoi nous vous conseillons de consulter cette page de temps à autre. Si vous avez des questions concernant cette politique de confidentialité, vous pouvez nous contacter à l'adresse suivante : contact{'@'}aifactorysolution.com",
};
