import { Lang } from "@/types/lang";

export default <Lang>{
  paraphrase1: "Entrez le texte que vous souhaitez paraphraser ",
  paraphrase2: "Choisissez un style",
  paraphrase3: "Cliquez sur 'Paraphrase'",
  creative1: "Choisissez un sujet",
  creative2: "Choisissez une idée à générer",
  creative3: 'Cliquez sur "Générer". La plate-forme affichera 3 résultats',
  poems1: "Choisissez un style",
  poems2: "Définir la taille et le nombre de strophes",
  poems3: "Cliquez sur 'Générer'",
  voxAi1: "Choisissez une langue.",
  voxAi2: "Choisissez une voix.",
  voxAi3:
    "Saisissez le texte et cliquez sur \"Générer\". L'audio généré peut ensuite être téléchargé en cliquant sur le bouton 'Télécharger'.",
  neuroVision1: "Saisissez une invite et définissez le nombre d'images à générer.",
  neuroVision2: "Choisissez un style d'image",
  neuroVision3:
    "Appuyez sur \"Générer\". Notez qu'AI Factory dispose de filtres de mots-clés, veuillez vous abstenir d'utiliser des invites nuisibles, suggestives ou offensantes de quelque manière que ce soit.",
};
