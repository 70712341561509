import { Lang } from "@/types/lang";

export default <Lang>{
  registered: "Controlla la tua email per conferma",
  login: "Accesso effettuato con successo",
  logout: "Logout effettuato con successo",
  resetPassword: "Password reimpostata con successo",
  poem: "Poema generato con successo",
  textParaphrased: "Testo parafrasato con successo",
  creativeText: "Testo creativo generato con successo",
  imageGenerated: "Immagine generata con successo",
  registrationFinished: "Registrazione completata con successo",
  textCopied: "Testo copiato",
  balance: "Saldo aggiornato",
  audioGenerated: "Audio generato con successo",
};
