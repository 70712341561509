import { Lang } from "@/types/lang";

export default <Lang>{
  paraphrase1: "Introduzca un texto que desee parafrasear ",
  paraphrase2: "Elija un estilo",
  paraphrase3: "Haga clic en 'Parafrasear'",
  creative1: "Elija un tema",
  creative2: "Elige una idea para generar",
  creative3: 'Haga clic en "Generar". La Plataforma mostrará 3 resultados',
  poems1: "Elija un estilo",
  poems2: "Configura un tamaño de estrofa y un recuento de estrofas",
  poems3: "Haga clic en 'Genetate'",
  voxAi1: "Elige un idioma.",
  voxAi2: "Elige una voz.",
  voxAi3:
    "Introduzca el texto y haga clic en \"Generar\". El audio generado puede descargarse pulsando el botón 'Descargar'.",
  neuroVision1: "Introduzca un aviso y establezca la cantidad de imágenes para la generación",
  neuroVision2: "Elija un estilo de imagen ",
  neuroVision3:
    'Pulse "Generar". Tenga en cuenta que AI Factory tiene filtros de palabras clave, por favor, absténgase de utilizar prompts dañinos, sugerentes o de algún modo ofensivos',
};
