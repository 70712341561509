import { Lang } from "@/types/lang";

export default <Lang>{
  question1: "Che cos'è AI Factory?",
  question2: "Come funziona AI Factory?",
  question3: "Quali servizi sono disponibili sulla piattaforma?",
  question4: "Come si accede ai Servizi di AI Factory?",
  question5: "Cosa sono i Neuroni e come si ottengono?",
  question6: "Come fa la Piattaforma a calcolare il costo finale di una generazione di contenuti? ",
  question6_1: "Quanto tempo occorre per elaborare le transazioni?",
  question7: "Quali sono le applicazioni di AI Factory?",
  question8: "Qual è la tecnologia alla base di AI Factory?",
  question9: "Quali lingue può elaborare AI Factory?",
  question10: "Come fa la piattaforma a garantire la qualità dei contenuti generati?",
  answer1:
    "AIFactory è una rete neurale combinata autoconsapevole in grado di generare testi, riscriverli, suggerire idee creative, produrre immagini ultra-realistiche e file audio con voci realistiche e altro ancora.",
  answer2:
    "AIFactory utilizza reti neurali autoconsapevoli all'avanguardia che hanno la capacità di analizzare e generare contenuti e di essere creativi per offrire idee e soluzioni uniche.",
  answer3:
    "Ci sono 3 sezioni uniche su AI Factory:\n" +
    "WordCraft è una sezione basata sul testo che consente di parafrasare blocchi di testo, generare idee creative, poesie e altro ancora!\n" +
    "VoxAI è un servizio text-to-voice che contiene 6 voci ultra-realistiche con cui creare contenuti audio.\n" +
    "Neuro-Vision è una sezione in cui l'utente può generare qualsiasi immagine in 17 stili diversi.",
  answer4:
    "Per accedere ai Servizi, è necessario registrare un account e confermare il proprio indirizzo e-mail. Dopodiché si può andare direttamente al servizio di interesse e utilizzarlo, assicurandosi di avere abbastanza neuroni!",
  answer5:
    'Abbiamo implementato un sistema di bilanciamento sulla nostra piattaforma e i Neuroni sono la valuta speciale utilizzata per accedere ai servizi di AI Factory. Per ottenerli, basta andare sul proprio profilo e cliccare sul pulsante "Acquista Neuroni".',
  answer6:
    "Esiste un costo di base per ogni Servizio. Ad esempio, 1 immagine in Neuro-Vision costa 50 Neuroni, non ci sono opzioni aggiuntive, quindi il prezzo è fisso. Per altri servizi, come VoxAI, ci sono caratteristiche aggiuntive come la lingua, la dimensione del testo e il tipo di voce. Tutte queste opzioni aggiuntive fanno lievitare il prezzo e ci siamo assicurati che il costo finale sia visualizzato correttamente prima che l'utente spenda i suoi Neuroni.",
  answer6_1:
    "Le transazioni vengono generalmente elaborate entro 72 ore dal pagamento. Una volta completato il pagamento, potrebbero essere necessarie fino a 24 ore per aggiornare il saldo del conto, anche se di solito il processo è immediato.",
  answer7:
    "AI Factory può essere utilizzato per generare testi di qualità, aumentare e riscrivere contenuti esistenti, fornire idee creative, creare immagini realistiche e file audio.",
  answer8:
    "AIFactory si basa su tecnologie avanzate di intelligenza artificiale, tra cui reti neurali e deep learning, e utilizza tecniche di generazione e trasformazione dei contenuti.",
  answer9:
    "AI Factory è in grado di elaborare testi in molte lingue, tra cui, ma non solo, l'inglese, l'olandese, lo spagnolo, il francese, il tedesco, l'italiano e altre lingue popolari.",
  answer10:
    "AI Factory utilizza modelli e algoritmi avanzati, addestrati su grandi quantità di dati e dotati di meccanismi di controllo della qualità per garantire la generazione di contenuti di alta qualità, anche se non possiamo garantire un risultato accurato al 100%.",
};
