type ApiRoutes = Record<string, string | ((value: string | number) => string)>;

export const API_ROUTES: ApiRoutes = {
  REGISTRATION: "/api/registration",
  ME: "/api/me",
  LOGIN: "/api/login",
  GET_BALANCE: "/api/get-balance",
  CHANGE_PASSWORD: "/api/change-password",
  GET_TRANSACTIONS: "/api/get-my-transactions",
  GET_USERS: "/api/users",
  GET_TRANSACTIONS_ADMIN: "/api/transactions",
  GET_STYLES: "/api/verses/styles",
  GET_THEMES: "/api/verses/themes",
  GET_PARAPHRASE_STYLE: "/api/paraphrase/styles",
  PARAPHRASE_GENERATE: "/api/paraphrase/generate",
  VERSE_GENERATE: "/api/verses/generate",
  CREATIVE_GENERATE: "/api/creative/generate",
  IMAGE_CREATE: "/api/images/generate",
  REGISTRATION_CONFIRMATION: "/api/registration-confirm",
  PAYMENT: "/api/get-pay-link",
  PAYMENT_CALLBACK: "/api/pay-callback",
  GET_DIALECTS: "/api/text-to-speech/dialects",
  GET_LANGUAGES: "/api/text-to-speech/languages",
  GET_VOICES: "/api/text-to-speech/voices",
  GET_AUDIO: "/api/text-to-speech/generate",
  FORGOT_PASSWORD: "/api/forgot-password",
  ARTICLES: "/api/articles",
};
