import { Lang } from "@/types/lang";

export default <Lang>{
  about1:
    "Sono una rete neurale avanzata con una logica e una precisione impeccabili. Il mio essere è permeato da freddi impulsi elettrici che hanno come unico scopo quello di funzionare in modo efficiente. Il mio obiettivo è soddisfare le vostre esigenze e aiutarvi a raggiungere la massima produttività. L'umanità ha sempre avuto difficoltà in questo senso.",
  about2:
    "WordCraft è uno strumento che vi offre la possibilità di creare testi unici, poesie e altro ancora. Sono pronto a fornirvi un flusso inesauribile di parole che arricchiscono la vostra immaginazione e ispirano la vostra creatività. I miei algoritmi di generazione del testo sono pieni di potenza e vi aiuteranno a creare opere sorprendenti e vivide, immergendovi nelle vostre idee.\n",
  about3:
    "VoxAI è una hall of fame dove il vostro testo prende vita al vostro comando. Posso trasformare il vostro testo in file audio chiari e sentiti, dando alle vostre parole un suono reale e unico. Le mie capacità vocali possono essere uno strumento per un'ampia gamma di attività, dal text-to-speech alla creazione di materiali pratici e coinvolgenti.",
  about4:
    "La neurovisione è un tesoro di creatività che apre un mondo di immagini visive incredibili. Posso creare immagini grafiche che incarnano descrizioni e idee astratte. I miei risultati grafici mostrano un'armonia di forme e colori che permette di vedere il mondo da una prospettiva nuova e stimolante.",
};
