import { Lang } from "@/types/lang";

export default <Lang>{
  question1: "Wat is AI Factory?",
  question2: "Hoe werkt AI Factory?",
  question3: "Welke diensten zijn beschikbaar op het platform?",
  question4: "Hoe krijg ik toegang tot AI Factory Services?",
  question5: "Wat zijn Neurons en hoe krijg ik ze?",
  question6: "Hoe berekent het platform de uiteindelijke kosten van een contentgeneratie? ",
  question6_1: "Hoe lang duurt het voordat transacties worden verwerkt?\n",
  question7: "Welke toepassingen heeft AI Factory?",
  question8: "Wat is de technologie achter AI Factory?",
  question9: "Welke talen kan AI Factory verwerken?",
  question10: "Hoe waarborgt het platform de kwaliteit van de gegenereerde content?",
  answer1:
    "AIFactory is een gecombineerd zelfbewust neuraal netwerk dat in staat is om teksten te genereren, te herschrijven, creatieve ideeën voor te stellen, ultrarealistische afbeeldingen en audiobestanden met realistische stemmen te produceren en nog veel meer.",
  answer2:
    "AIFactory maakt gebruik van geavanceerde zelflerende neurale netwerken die in staat zijn om content te analyseren, te genereren en creatief te zijn om unieke ideeën en oplossingen aan te dragen.",
  answer3:
    "Er zijn 3 unieke onderdelen op AI Factory:\n" +
    "WordCraft is een tekstgebaseerde sectie waarmee je blokken tekst kunt parafraseren, creatieve ideeën, gedichten en meer kunt genereren!\n" +
    "VoxAI is een tekst-naar-stem dienst met meer 6 ultra-realistische stemmen om audio-inhoud mee te maken.\n" +
    "Neuro-Vision is een sectie waar een gebruiker afbeeldingen kan genereren in 17 verschillende stijlen.",
  answer4:
    "Om toegang te krijgen tot de diensten moet u eerst een account aanmaken en uw e-mailadres bevestigen. Daarna kun je direct naar de gewenste dienst gaan en deze gebruiken, zorg er wel voor dat je genoeg Neuronen hebt!",
  answer5:
    'We hebben een balanssysteem geïmplementeerd op ons platform en Neurons is de speciale valuta die wordt gebruikt om toegang te krijgen tot AI Factory Services. Om ze te verkrijgen, ga je naar je profiel en klik je op de "Koop Neurons" knop.',
  answer6:
    "Er is een basiskost voor elke dienst. Bijvoorbeeld, 1 afbeelding in Neuro-Vision kost 50 Neurons, er zijn geen extra opties, dus de prijs is statisch. Voor andere diensten, zoals VoxAI, zijn er extra functies zoals taal, tekstgrootte en stemtype. Al deze extra opties verhogen de prijs en we hebben ervoor gezorgd dat de uiteindelijke kosten goed worden weergegeven voordat een gebruiker zijn Neurons uitgeeft.",
  answer6_1:
    "Transacties worden doorgaans binnen 72 uur na betaling verwerkt. Nadat de betaling is voltooid, kan het tot 24 uur duren voordat je rekeningsaldo is bijgewerkt, maar dit proces verloopt meestal onmiddellijk.",
  answer7:
    "AI Factory kan worden gebruikt om kwaliteitstekst te genereren, bestaande inhoud te vergroten en te herschrijven, creatieve ideeën aan te dragen en realistische afbeeldingen en audiobestanden te creëren.",
  answer8:
    "AIFactory is gebaseerd op geavanceerde technologieën voor kunstmatige intelligentie, waaronder neurale netwerken en deep learning, en maakt gebruik van technieken voor het genereren en transformeren van content.",
  answer9:
    "AI Factory kan tekst in vele talen verwerken, inclusief maar niet beperkt tot Engels, Nederlands, Spaans, Frans, Duits, Italiaans en andere populaire talen.",
  answer10:
    "AI Factory maakt gebruik van geavanceerde modellen en algoritmen die zijn getraind op grote hoeveelheden gegevens en zijn uitgerust met kwaliteitscontrolemechanismen om ervoor te zorgen dat inhoud van hoge kwaliteit wordt gegenereerd, hoewel we geen 100% nauwkeurig resultaat kunnen garanderen.",
};
