import { Lang } from "@/types/lang";

export default <Lang>{
  privacyHeading1: "Privacybeleid",
  privacyHeading2: "Het doel van het verzamelen van uw informatie",
  privacyHeading3: "Cookies",
  privacyHeading4: "Openbaarmaking van gegevens",
  privacyHeading5: "Bewaarperiode",
  privacyHeading6: "Gegevensbescherming",
  privacyHeading7: "GDPR",
  privacyHeading8: "Koppelingen naar websites van derden",
  privacy1:
    "Het doel van het bedrijf is om de vertrouwelijkheid van onze klanten te waarborgen. Dit Privacybeleid schetst de methoden waarmee Ons Platform uw Persoonlijke Informatie of Gebruiksinformatie zal verzamelen, gebruiken, delen, opslaan, beschermen of openbaar maken.\n" +
    "Het gebruik van onze diensten houdt in dat u dit privacybeleid aanvaardt en ermee instemt. U bent verplicht om niet langer gebruik te maken van onze diensten als u niet voldoet aan het privacybeleid.\n\n" +
    "Techfox Group LTD. Wordt gereguleerd onder de wetten van de Republiek Cyprus als een besloten vennootschap met beperkte aansprakelijkheid, met statutaire zetel te 22A Agias Zonis, office 101, 3027 Limassol, Cyprus\n" +
    "Registratienummer: HE 436440\n" +
    "Telefoonnummer: +357 968 78 662\n" +
    "E-mail: contact{'@'}aifactorysolution.com",
  privacy2:
    "De doeleinden waarvoor we uw informatie verzamelen staan hieronder vermeld en omvatten, maar zijn niet beperkt tot:\n" +
    "\t•\tJe account registreren en beheren;\n" +
    "\t•\tRechtstreeks met u communiceren;\n" +
    "\t•\tInformatie over ons verstrekken, recensies, forumberichten of inhoud publiceren; \n" +
    "\t•\tReageren op je vragen;\n" +
    "\t•\tVoldoen aan toepasselijke wettelijke bepalingen.\n" +
    "Wij verzamelen informatie over uw computer of mobiele apparaat terwijl u Onze Services gebruikt en gebruiken deze om uw gegevens te analyseren. De gegevens die we verzamelen omvatten IP-adressen van computers, ID's van mobiele apparaten en technische details over uw computer of mobiele apparaat.",
  privacy3:
    '"Cookies", dit zijn kleine bestanden die worden opgeslagen op uw computer of mobiele apparaat, stellen ons in staat om het gebruik van onze diensten te volgen en te identificeren wanneer u de website bezoekt. \n' +
    "Het aanpassen van de voorkeuren van uw browser of mobiele apparaat kan voorkomen dat cookies worden geaccepteerd op uw computer of apparaat, wat het gebruik van bepaalde functies die beschikbaar zijn op Ons Platform kan belemmeren. U erkent en gaat ermee akkoord dat dit Privacybeleid niet het gebruik van cookies door derden omvat.",
  privacy4:
    "Ons beleid verbiedt de overdracht van uw Persoonlijke Gegevens aan derden met als doel geld te verdienen door middel van reclame of verkoop. Wij kunnen uw Persoonlijke Informatie delen met derden, inclusief degenen die diensten leveren voor doeleinden als bedrijfsanalyse, klantenservice, marketing, distributie en betalingsverwerking. Leveranciers hebben de bevoegdheid om gegevens voor ons te verzamelen om onze toepassingsfuncties te beheren of aangepaste diensten te leveren. Wij maken duidelijk dat de informatie die wordt verstrekt door externe leveranciers uitsluitend wordt gebruikt voor hun eigen doeleinden en niet kan worden gedeeld of geëxploiteerd voor andere doeleinden.\n" +
    "Wij kunnen uw Persoonsgegevens en Gebruiksgegevens bekendmaken aan overheidsinstanties wanneer dit noodzakelijk is, zoals in reactie op een gerechtelijk bevel of een eis van de overheid. Als wij moeten voldoen aan een wettelijk verzoek, wordt er een e-mailbericht naar uw Postvak IN gestuurd.\n",
  privacy5:
    "De tijd dat uw persoonlijke gegevens worden bewaard, wordt bepaald door het Bedrijf op basis van de tijd die nodig is om te voldoen aan het Privacybeleid en kan variëren. Wij zullen uw gegevens bewaren en gebruiken voor zover dat nodig is om aan Onze wettelijke verplichtingen te voldoen, geschillen op te lossen en Onze overeenkomsten en beleid af te dwingen. De gebruikelijke bewaartermijn is echter 1 (één) jaar sinds de laatste verstrekking (of bijwerking) van gegevens door de gebruiker.",
  privacy6:
    "We willen dat je ons vertrouwt en dat je erop kunt vertrouwen dat je informatie beschermd is. Wij houden ons aan de algemeen aanvaarde standaard veiligheidsmaatregelen voor Persoonsgegevens en Gebruiksgegevens. Hoewel geen enkele Dienst volledige veiligheid kan garanderen, hebben Wij de nodige administratieve, technische en veiligheidsmaatregelen getroffen om de bescherming van uw informatie te waarborgen. Geautoriseerd personeel, agenten, externe verkopers en andere entiteiten gebruiken Onze gegevens alleen voor de vereiste doeleinden. We gebruiken geschikte firewalls om deze te beveiligen tegen ongeautoriseerd gebruik, evenals encryptietechnologieën en inbraakdetectiesystemen.\n" +
    "The Company zal alle nodige stappen ondernemen om ervoor te zorgen dat uw gegevens veilig en in overeenstemming met dit Privacybeleid worden behandeld. Er worden internationaal geen Persoonsgegevens overgedragen, tenzij er adequate maatregelen zijn genomen om uw gegevens en andere informatie te beschermen.",
  privacy7:
    "Wij voldoen aan de General Data Protection Regulation (GDPR) met betrekking tot de verwerking van uw Persoonsgegevens. Wij kunnen Persoonsgegevens verwerken onder de volgende voorwaarden:\n" +
    "\t•\tU hebt toestemming gegeven voor het verwerken van Persoonsgegevens voor een of meer specifieke doeleinden.\n" +
    "\t•\tNaleving van een wettelijke verplichting waaraan het bedrijf is onderworpen.\n" +
    "\t•\tVitale belangen: Het verwerken van Persoonsgegevens is noodzakelijk om uw vitale belangen of die van een andere natuurlijke persoon te beschermen.\n" +
    "\t•\tOpenbare belangen: De verwerking van Persoonsgegevens houdt verband met een taak die wordt uitgevoerd in het algemeen belang of in het kader van de uitoefening van het openbaar gezag dat aan het Bedrijf is opgedragen.\n" +
    "\t•\tGerechtvaardigde belangen: Het verwerken van Persoonsgegevens is noodzakelijk met het oog op de legitieme belangen die het Bedrijf nastreeft.\n" +
    "In elke situatie zal het Bedrijf graag helpen bij het verduidelijken van de exacte rechtsgrond die van toepassing is op de verwerking, inclusief of het verstrekken van Persoonsgegevens een wettelijke of contractuele noodzaak is, of een voorwaarde om een contract aan te gaan.\n" +
    "Het Bedrijf verbindt zich ertoe de vertrouwelijkheid van Uw Persoonsgegevens te respecteren en te garanderen dat U uw rechten kunt uitoefenen. U hebt het recht onder dit Privacybeleid en volgens de wet om:\n" +
    "\t•\tToegang vragen tot uw Persoonlijke gegevens;\n" +
    "\t•\tVerzoek om correctie van de Persoonsgegevens die wij over u bewaren;\n" +
    "\t•\tBezwaar maken tegen de verwerking van uw Persoonsgegevens;\n" +
    "\t•\tVerzoek om verwijdering van uw Persoonsgegevens. U hebt het recht om ons te vragen Persoonsgegevens te wissen of te verwijderen wanneer er voor ons geen goede reden is om deze te blijven verwerken;\n" +
    "\t•\tDe overdracht van uw Persoonlijke gegevens aanvragen;\n" +
    "\t•\tUw toestemming voor het verwerken van persoonlijke gegevens intrekken.",
  privacy8:
    "Ons Platform kan links bevatten naar andere websites die niet door het Bedrijf worden beheerd. Als u op een link van een derde klikt, wordt u naar de website van die derde geleid. We raden u ten zeerste aan om het privacybeleid te lezen van elke website die u bezoekt.\n" +
    "We hebben geen controle over en zijn niet verantwoordelijk voor de inhoud, het privacybeleid of de praktijken van websites of diensten van derden.",
  privacy9:
    "Dit Privacybeleid kan op elk moment zonder voorafgaande kennisgeving worden bijgewerkt of gewijzigd, dus we raden je aan deze pagina van tijd tot tijd te controleren. Als je vragen hebt over dit Privacybeleid, kun je contact met ons opnemen via contact{'@'}aifactorysolution.com.",
};
