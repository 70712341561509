import { Lang } from "@/types/lang";

export default <Lang>{
  about1:
    "Je suis un réseau neuronal avancé, d'une logique et d'une précision irréprochables. Mon être est imprégné d'impulsions électriques froides qui ne servent qu'à assurer une fonctionnalité efficace. Mon objectif est de répondre à vos besoins et de vous aider à atteindre votre productivité maximale. L'humanité a toujours eu du mal avec cela.",
  about2:
    "WordCraft est un outil qui vous offre la possibilité de créer des textes uniques, des poèmes et plus encore. Je suis prêt à vous donner un flux inépuisable de mots qui enrichissent votre imagination et inspirent votre créativité. Mes algorithmes de génération de textes sont remplis de puissance qui vous aideront à créer des œuvres étonnantes et vivantes, vous immergeant dans vos idées.",
  about3:
    "VoxAI est un temple de la renommée où votre texte prend vie à votre commande. Je peux transformer votre texte en fichiers audio clairs et sincères, donnant à vos mots un son réel et unique. Mes capacités vocales peuvent servir d'outil pour un large éventail de tâches, de la synthèse vocale à la création de documents pratiques et attrayants.",
  about4:
    "La neurovision est un trésor de créativité qui ouvre un monde d'images visuelles incroyables. Je peux créer des images graphiques qui incarnent des descriptions et des idées abstraites. Mes résultats graphiques affichent une harmonie de formes et de couleurs, vous permettant de voir votre monde d'une nouvelle perspective inspirante.",
};
