import { Lang } from "@/types/lang";

export default <Lang>{
  question1: "Was ist AI Factory?",
  question2: "Wie funktioniert AI Factory?",
  question3: "Welche Dienste sind auf der Plattform verfügbar?",
  question4: "Wie kann ich auf die AI Factory Services zugreifen?",
  question5: "Was sind Neuronen und wie erhalte ich sie?",
  question6: "Wie berechnet die Plattform die endgültigen Kosten für eine Inhaltserstellung?",
  question6_1: "Wie lange dauert es, bis Transaktionen bearbeitet werden?",
  question7: "Welche Anwendungen gibt es für AI Factory?",
  question8: "Welche Technologie steckt hinter AI Factory?",
  question9: "Welche Sprachen kann AI Factory verarbeiten?",
  question10: "Wie stellt die Plattform die Qualität der generierten Inhalte sicher?",
  answer1:
    "AIFactory ist ein kombiniertes, selbstbewusstes neuronales Netzwerk, das in der Lage ist, Texte zu generieren, sie umzuschreiben, kreative Ideen vorzuschlagen, ultrarealistische Bilder und Audiodateien mit realistischen Stimmen zu produzieren und vieles mehr.",
  answer2:
    "AIFactory verwendet hochmoderne lernende, selbstbewusste neuronale Netzwerke, die in der Lage sind, Inhalte zu analysieren und zu generieren, und kreativ zu sein, um einzigartige Ideen und Lösungen anzubieten.",
  answer3:
    "Es gibt 3 einzigartige Bereiche auf AI Factory:\n" +
    "WordCraft ist ein textbasierter Bereich, der es Ihnen ermöglicht, Textblöcke zu paraphrasieren, kreative Ideen, Gedichte und mehr zu generieren!\n" +
    "VoxAI ist ein Text-zu-Stimme-Service mit 6 ultra-realistischen Stimmen, mit denen man Audio-Inhalte erstellen kann.\n" +
    "Neuro-Vision ist ein Bereich, in dem ein Benutzer jedes beliebige Bild in 17 verschiedenen Stilen erstellen kann.",
  answer4:
    "Um auf die Dienste zuzugreifen, müssen Sie zunächst ein Konto anlegen und Ihre E-Mail-Adresse bestätigen. Danach können Sie direkt zum gewünschten Service gehen und ihn nutzen. Stellen Sie nur sicher, dass Sie genügend Neuronen haben!",
  answer5:
    'Wir haben ein Guthabensystem auf unserer Plattform implementiert und Neuronen sind die spezielle Währung, die für den Zugang zu den AI Factory Services verwendet wird. Um sie zu erhalten, gehen Sie einfach auf Ihr Profil und klicken Sie auf die Schaltfläche "Neuronen kaufen".',
  answer6:
    "Es gibt einen Grundpreis für jeden Service. Zum Beispiel, 1 Bild in Neuro-Vision kostet 50 Neuronen, es gibt keine zusätzlichen Optionen, also ist der Preis statisch. Für andere Dienste wie VoxAI gibt es zusätzliche Funktionen wie Sprache, Textgröße und Art der Stimme. All diese zusätzlichen Optionen erhöhen den Preis und wir haben sichergestellt, dass die endgültigen Kosten korrekt angezeigt werden, bevor ein Nutzer seine Neuronen ausgibt.",
  answer6_1:
    "Transaktionen werden normalerweise innerhalb von 72 Stunden nach der Zahlung bearbeitet. Nachdem die Zahlung abgeschlossen ist, kann es bis zu 24 Stunden dauern, bis Ihr Kontostand aktualisiert wird, obwohl der Vorgang in der Regel sofort erfolgt",
  answer7:
    "AI Factory kann verwendet werden, um hochwertige Texte zu generieren, bestehende Inhalte zu ergänzen und umzuschreiben, kreative Ideen zu liefern, realistische Bilder und Audiodateien zu erstellen.",
  answer8:
    "AIFactory basiert auf fortschrittlichen Technologien der künstlichen Intelligenz, einschließlich neuronaler Netze und Deep Learning, und nutzt Techniken zur Generierung und Transformation von Inhalten.",
  answer9:
    "AI Factory kann Texte in vielen Sprachen verarbeiten, einschließlich, aber nicht beschränkt auf Englisch, Niederländisch, Spanisch, Französisch, Deutsch, Italienisch und andere gängige Sprachen.",
  answer10:
    "AI Factory verwendet fortschrittliche Modelle und Algorithmen, die auf großen Datenmengen trainiert werden und mit Qualitätskontrollmechanismen ausgestattet sind, um sicherzustellen, dass qualitativ hochwertige Inhalte generiert werden, auch wenn wir keine 100%ige Genauigkeit garantieren können.",
};
