import { Lang } from "@/types/lang";

export default <Lang>{
  paraphrase1: "Inserite il testo che desiderate parafrasare",
  paraphrase2: "Scegliere uno stile",
  paraphrase3: "Fare clic su 'Parafrasi'",
  creative1: "Scegliere un argomento",
  creative2: "Scegliere un'idea da generare",
  creative3: 'Fare clic su "Genera". La piattaforma visualizzerà 3 risultati',
  poems1: "Scegliere uno stile",
  poems2: "Impostare la dimensione e il numero delle strofe",
  poems3: "Fare clic su 'Genetate'",
  voxAi1: "Scegliere una lingua.",
  voxAi2: "Scegliere una voce.",
  voxAi3:
    'Immettere il testo e fare clic su "Genera". L\'audio generato può essere scaricato facendo clic sul pulsante "Download".',
  neuroVision1: "Immettere una richiesta e impostare la quantità di immagini da generare",
  neuroVision2: "Scegliere uno stile di immagine",
  neuroVision3:
    'Premere "Genera". Si noti che AI Factory dispone di filtri per le parole chiave; si prega di astenersi dall\'utilizzare messaggi dannosi, suggestivi o in qualche modo offensivi',
};
