import { AxiosError } from "axios";
import { isArray } from "lodash";

import { useApi } from "@/api";
import { API_ROUTES } from "@/api/apiRoutes";
import { getBalance } from "@/hooks/user/useGetBalance";
import { useUserLogout } from "@/hooks/user/useUserLogout";
import { i18n } from "@/i18n";
import { useAppStore } from "@/store/app/appStore";
import { User, useUserStore } from "@/store/app/userStore";
import { ToastSeverity } from "@/types/toast";

export const MAXTIVITY_TOKEN_KEY = "maxtivity_token";

export const getUserInfo = async (): Promise<User | null | boolean> => {
  const userStore = useUserStore();
  const { updateUser, updateUserAccess } = userStore;

  const appStore = useAppStore();
  const { updateIsLoading, showToast } = appStore;

  const { request, data } = useApi<string>({
    path: API_ROUTES.ME as string,
  });

  updateIsLoading(true);

  try {
    await request();

    if (data.value && data.value.account_type !== "test") {
      updateUser({
        name: data.value.name,
        email: data.value.email,
        account_type: data.value.account_type,
      });
      await getBalance();
      updateUserAccess(true);
      return data.value;
    } else {
      showToast({
        summary: i18n.global.t("message.login"),
        severity: ToastSeverity.Error,
        detail: "Please confirm your email",
      });

      useUserLogout();
      return true;
    }
  } catch (e) {
    let errorMessage = i18n.global.t("error.somethingWentWrong");
    if (e instanceof AxiosError) {
      errorMessage = isArray(e.response?.data.message) ? e.response?.data.message[0] : e.response?.data.message;
    }
    if (localStorage.getItem(MAXTIVITY_TOKEN_KEY) !== "") {
      // showToast({
      //   summary: lang.message.login,
      //   severity: ToastSeverity.Error,
      //   detail: errorMessage,
      // });
    }

    useUserLogout();
  } finally {
    updateIsLoading(false);
  }
  updateUserAccess(false);
  return null;
};
