import { RequestMethod, useApi } from "@/api";
import { API_ROUTES } from "@/api/apiRoutes";
import { Article } from "@/hooks/articles/useGetArticles";
import { useAppStore } from "@/store/app/appStore";

export const useArticleReact = async (react: string, id: string): Promise<Article | null> => {
  const appStore = useAppStore();

  const { updateIsLoading } = appStore;
  const { request, data } = useApi<string>({
    path: `${API_ROUTES.ARTICLES}/${id}/reacted`,
    method: RequestMethod.Post,
    body: {
      type: react,
    },
  });

  try {
    await request();

    if (data.value) {
      return data.value;
    } else {
      return null;
    }
  } finally {
    updateIsLoading(false);
  }
};
