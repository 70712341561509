export enum LanguagesEnum {
  English = "english",
  Italian = "italian",
  French = "french",
  Dutch = "dutch",
  German = "german",
  Spanish = "spanish",
}

export const languages = [
  {
    label: "English",
    key: "en",
  },
  {
    label: "Italian",
    key: "it",
  },
  {
    label: "French",
    key: "fr",
  },
  {
    label: "Dutch",
    key: "nl",
  },
  {
    label: "German",
    key: "de",
  },
  {
    label: "Spanish",
    key: "es",
  },
];

export const languageDialects = [
  {
    languageId: "61b8b2f54268666c126babc9",
    languageName: "English",
    dialect: {
      _id: "61b8b31b4268666c126bace5",
      name: "🇬🇧 United Kingdom",
    },
  },
  {
    languageId: "61b8b2f24268666c126babb1",
    languageName: "Afrikaans",
    dialect: {
      _id: "61b8b3184268666c126baccf",
      name: "🇿🇦 South Africa",
    },
  },
  {
    languageId: "62b2110fb751a736405225ce",
    languageName: "Albanian",
    dialect: {
      _id: "62b2115db751a73640522820",
      name: "🇦🇱 Albania",
    },
  },
  {
    languageId: "61b8b2f24268666c126babb3",
    languageName: "Amharic",
    dialect: {
      _id: "61b8b30a4268666c126bac65",
      name: "🇪🇹 Ethiopia",
    },
  },
  {
    languageId: "61b8b2f24268666c126babb5",
    languageName: "Arabic",
    dialect: {
      _id: "61b8b3164268666c126bacc3",
      name: "🇸🇦 Saudi Arabia",
    },
  },
  {
    languageId: "635bc419d0900a036c2e439d",
    languageName: "Armenian",
    dialect: {
      _id: "635bc437d0900a036c2e483f",
      name: "🇦🇲 Armenia",
    },
  },
  {
    languageId: "62b21780d63b5531a40753f4",
    languageName: "Azerbaijani",
    dialect: {
      _id: "62b2145cd63b5531a4074a88",
      name: "🇦🇿 Azerbaijan",
    },
  },
  {
    languageId: "61b8b2f24268666c126babb7",
    languageName: "Bangla/Bengali",
    dialect: {
      _id: "61b8b3054268666c126bac3b",
      name: "🇧🇩 Bangladesh",
    },
  },
  {
    languageId: "635bc666d0900a036c2e6ed2",
    languageName: "Basque",
    dialect: {
      _id: "61b8b3184268666c126bacd1",
      name: "🇪🇸 Spain",
    },
  },
  {
    languageId: "62b2188ed63b5531a40757e3",
    languageName: "Bosnian",
    dialect: {
      _id: "62b21879d63b5531a40757b1",
      name: "🇧🇦 Bosnia and Herzegovina",
    },
  },
  {
    languageId: "61b8b2f34268666c126babb9",
    languageName: "Bulgarian",
    dialect: {
      _id: "61b8b3064268666c126bac43",
      name: "🇧🇬 Bulgaria",
    },
  },
  {
    languageId: "61b8b2f34268666c126babbb",
    languageName: "Burmese",
    dialect: {
      _id: "61b8b3124268666c126bac9f",
      name: "🇲🇲 Myanmar",
    },
  },
  {
    languageId: "61b8b2f34268666c126babbd",
    languageName: "Catalan",
    dialect: {
      _id: "61b8b3184268666c126bacd1",
      name: "🇪🇸 Spain",
    },
  },
  {
    languageId: "61b8b2f44268666c126babbf",
    languageName: "Chinese",
    dialect: {
      _id: "61b8b3114268666c126bac9b",
      name: "🇨🇳 Mandarin Simplified",
    },
  },
  {
    languageId: "61b8b2f44268666c126babc1",
    languageName: "Croatian",
    dialect: {
      _id: "61b8b3074268666c126bac51",
      name: "🇭🇷 Croatia",
    },
  },
  {
    languageId: "61b8b2f44268666c126babc3",
    languageName: "Czech",
    dialect: {
      _id: "61b8b3084268666c126bac55",
      name: "🇨🇿 Czech Republic",
    },
  },
  {
    languageId: "61b8b2f44268666c126babc5",
    languageName: "Danish",
    dialect: {
      _id: "61b8b3084268666c126bac57",
      name: "🇩🇰 Denmark",
    },
  },
  {
    languageId: "61b8b2f54268666c126babc7",
    languageName: "Dutch",
    dialect: {
      _id: "61b8b3124268666c126baca3",
      name: "🇳🇱 Netherlands",
    },
  },
  {
    languageId: "61b8b2f54268666c126babcb",
    languageName: "Estonian",
    dialect: {
      _id: "61b8b30a4268666c126bac63",
      name: "🇪🇪 Estonia",
    },
  },
  {
    languageId: "61b8b2f54268666c126babcd",
    languageName: "Filipino",
    dialect: {
      _id: "61b8b3154268666c126bacb5",
      name: "🇵🇭 Philippines",
    },
  },
  {
    languageId: "61b8b2f64268666c126babcf",
    languageName: "Finnish",
    dialect: {
      _id: "61b8b30a4268666c126bac67",
      name: "🇫🇮 Finland",
    },
  },
  {
    languageId: "61b8b2f64268666c126babd1",
    languageName: "French",
    dialect: {
      _id: "61b8b30b4268666c126bac69",
      name: "🇫🇷 France",
    },
  },
  {
    languageId: "61b8b2f64268666c126babd3",
    languageName: "Galician",
    dialect: {
      _id: "61b8b3184268666c126bacd1",
      name: "🇪🇸 Spain",
    },
  },
  {
    languageId: "62b219d2d63b5531a4075de7",
    languageName: "Georgian",
    dialect: {
      _id: "62b219b3d63b5531a4075d97",
      name: "🇬🇪 Georgia",
    },
  },
  {
    languageId: "61b8b2f64268666c126babd5",
    languageName: "German",
    dialect: {
      _id: "61b8b30b4268666c126bac6b",
      name: "🇩🇪 Germany",
    },
  },
  {
    languageId: "61b8b2f74268666c126babd7",
    languageName: "Greek",
    dialect: {
      _id: "61b8b30b4268666c126bac6d",
      name: "🇬🇷 Greece",
    },
  },
  {
    languageId: "61b8b2f74268666c126babd9",
    languageName: "Gujarati",
    dialect: {
      _id: "61b8b30d4268666c126bac7b",
      name: "🇮🇳 India",
    },
  },
  {
    languageId: "61b8b2f74268666c126babdb",
    languageName: "Hebrew",
    dialect: {
      _id: "61b8b30e4268666c126bac85",
      name: "🇮🇱 Israel",
    },
  },
  {
    languageId: "61b8b2f74268666c126babdd",
    languageName: "Hindi",
    dialect: {
      _id: "61b8b30d4268666c126bac7b",
      name: "🇮🇳 India",
    },
  },
  {
    languageId: "61b8b2f84268666c126babdf",
    languageName: "Hungarian",
    dialect: {
      _id: "61b8b30c4268666c126bac75",
      name: "🇭🇺 Hungary",
    },
  },
  {
    languageId: "61b8b2f84268666c126babe1",
    languageName: "Icelandic",
    dialect: {
      _id: "61b8b30d4268666c126bac79",
      name: "🇮🇸 Iceland",
    },
  },
  {
    languageId: "61b8b2f84268666c126babe3",
    languageName: "Indonesian",
    dialect: {
      _id: "61b8b30d4268666c126bac7d",
      name: "🇮🇩 Indonesia",
    },
  },
  {
    languageId: "61b8b2f94268666c126babe5",
    languageName: "Irish",
    dialect: {
      _id: "61b8b30e4268666c126bac83",
      name: "🇮🇪 Ireland",
    },
  },
  {
    languageId: "61b8b2f94268666c126babe7",
    languageName: "Italian",
    dialect: {
      _id: "61b8b30f4268666c126bac87",
      name: "🇮🇹 Italy",
    },
  },
  {
    languageId: "61b8b2f94268666c126babe9",
    languageName: "Japanese",
    dialect: {
      _id: "61b8b30c4268666c126bac77",
      name: "🇯🇵 Japan",
    },
  },
  {
    languageId: "61b8b2f94268666c126babeb",
    languageName: "Javanese",
    dialect: {
      _id: "61b8b30d4268666c126bac7d",
      name: "🇮🇩 Indonesia",
    },
  },
  {
    languageId: "61b8b2fa4268666c126babed",
    languageName: "Kannada",
    dialect: {
      _id: "61b8b30d4268666c126bac7b",
      name: "🇮🇳 India",
    },
  },
  {
    languageId: "620518726fc020955bdcf7cc",
    languageName: "Kazakh",
    dialect: {
      _id: "620518a26fc020955bdcf803",
      name: "🇰🇿 Kazakhstan",
    },
  },
  {
    languageId: "61b8b2fa4268666c126babef",
    languageName: "Khmer",
    dialect: {
      _id: "61b8b3064268666c126bac45",
      name: "🇰🇭 Cambodia",
    },
  },
  {
    languageId: "61b8b2fa4268666c126babf1",
    languageName: "Korean",
    dialect: {
      _id: "61b8b30f4268666c126bac8d",
      name: "🇰🇷 Korea",
    },
  },
  {
    languageId: "620519ba6fc020955bdcf864",
    languageName: "Lao",
    dialect: {
      _id: "620519d76fc020955bdcf86d",
      name: "🇱🇦 Laos",
    },
  },
  {
    languageId: "61b8b2fa4268666c126babf3",
    languageName: "Latvian",
    dialect: {
      _id: "61b8b3104268666c126bac91",
      name: "🇱🇻 Latvia",
    },
  },
  {
    languageId: "61b8b2fb4268666c126babf5",
    languageName: "Lithuanian",
    dialect: {
      _id: "61b8b3104268666c126bac95",
      name: "🇱🇹 Lithuania",
    },
  },
  {
    languageId: "62051a6c6fc020955bdcf8a2",
    languageName: "Macedonian",
    dialect: {
      _id: "62051a956fc020955bdcf8ab",
      name: "🇲🇰 Republic of North Macedonia",
    },
  },
  {
    languageId: "61b8b2fb4268666c126babf7",
    languageName: "Malay",
    dialect: {
      _id: "61b8b3114268666c126bac97",
      name: "🇲🇾 Malaysia",
    },
  },
  {
    languageId: "61b8b2fc4268666c126babfd",
    languageName: "Malayalam",
    dialect: {
      _id: "61b8b30d4268666c126bac7b",
      name: "🇮🇳 India",
    },
  },
  {
    languageId: "61b8b2fb4268666c126babf9",
    languageName: "Maltese",
    dialect: {
      _id: "61b8b3114268666c126bac99",
      name: "🇲🇹 Malta",
    },
  },
  {
    languageId: "61b8b2fb4268666c126babfb",
    languageName: "Marathi",
    dialect: {
      _id: "61b8b30d4268666c126bac7b",
      name: "🇮🇳 India",
    },
  },
  {
    languageId: "62b21becd63b5531a4076ba1",
    languageName: "Mongolian",
    dialect: {
      _id: "62b21bd9d63b5531a4076a9d",
      name: "🇲🇳 Mongolia",
    },
  },
  {
    languageId: "62b21c96d63b5531a4076ed9",
    languageName: "Nepali",
    dialect: {
      _id: "62b21c88d63b5531a4076ed0",
      name: "🇳🇵 Nepal",
    },
  },
  {
    languageId: "61b8b2fc4268666c126babff",
    languageName: "Norwegian",
    dialect: {
      _id: "61b8b3134268666c126bacab",
      name: "🇳🇴 Norway",
    },
  },
  {
    languageId: "62051b886fc020955bdcf90f",
    languageName: "Pashto",
    dialect: {
      _id: "62051bab6fc020955bdcf92b",
      name: "🇦🇫 Afghanistan",
    },
  },
  {
    languageId: "61b8b2fc4268666c126bac01",
    languageName: "Persian",
    dialect: {
      _id: "61b8b30d4268666c126bac7f",
      name: "🇮🇷 Iran",
    },
  },
  {
    languageId: "61b8b2fc4268666c126bac03",
    languageName: "Polish",
    dialect: {
      _id: "61b8b3154268666c126bacb7",
      name: "🇵🇱 Poland",
    },
  },
  {
    languageId: "61b8b2fd4268666c126bac05",
    languageName: "Portuguese",
    dialect: {
      _id: "61b8b3154268666c126bacb9",
      name: "🇵🇹 Portugal",
    },
  },
  {
    languageId: "61b8b2fd4268666c126bac07",
    languageName: "Punjabi",
    dialect: {
      _id: "61b8b30d4268666c126bac7b",
      name: "🇮🇳 India",
    },
  },
  {
    languageId: "61b8b2fd4268666c126bac09",
    languageName: "Romanian",
    dialect: {
      _id: "61b8b3164268666c126bacbf",
      name: "🇷🇴 Romania",
    },
  },
  {
    languageId: "61b8b2fd4268666c126bac0b",
    languageName: "Russian",
    dialect: {
      _id: "61b8b3164268666c126bacc1",
      name: "🇷🇺 Russia",
    },
  },
  {
    languageId: "61b8b2fe4268666c126bac0d",
    languageName: "Serbian",
    dialect: {
      _id: "",
      name: "",
    },
  },
  {
    languageId: "62051f366fc020955bdcfb5c",
    languageName: "Sinhala",
    dialect: {
      _id: "61b8b3174268666c126baccb",
      name: "🇱🇰 Sri Lanka",
    },
  },
  {
    languageId: "61b8b2fe4268666c126bac0f",
    languageName: "Slovak",
    dialect: {
      _id: "61b8b3174268666c126bacc7",
      name: "🇸🇰 Slovakia",
    },
  },
  {
    languageId: "61b8b2fe4268666c126bac11",
    languageName: "Slovenian",
    dialect: {
      _id: "61b8b3174268666c126bacc9",
      name: "🇸🇮 Slovenia",
    },
  },
  {
    languageId: "61b8b2ff4268666c126bac13",
    languageName: "Somali",
    dialect: {
      _id: "61b8b3184268666c126baccd",
      name: "🇸🇴 Somalia",
    },
  },
  {
    languageId: "61b8b2ff4268666c126bac15",
    languageName: "Spanish",
    dialect: {
      _id: "61b8b3184268666c126bacd1",
      name: "🇪🇸 Spain",
    },
  },
  {
    languageId: "61b8b2ff4268666c126bac17",
    languageName: "Sundanese",
    dialect: {
      _id: "61b8b30d4268666c126bac7d",
      name: "🇮🇩 Indonesia",
    },
  },
  {
    languageId: "61b8b2ff4268666c126bac19",
    languageName: "Swahili",
    dialect: {
      _id: "61b8b30f4268666c126bac8b",
      name: "🇰🇪 Kenya",
    },
  },
  {
    languageId: "61b8b3004268666c126bac1b",
    languageName: "Swedish",
    dialect: {
      _id: "61b8b3194268666c126bacd3",
      name: "🇸🇪 Sweden",
    },
  },
  {
    languageId: "61b8b3014268666c126bac1d",
    languageName: "Tamil",
    dialect: {
      _id: "61b8b3174268666c126baccb",
      name: "🇱🇰 Sri Lanka",
    },
  },
  {
    languageId: "61b8b3014268666c126bac1f",
    languageName: "Telugu",
    dialect: {
      _id: "61b8b30d4268666c126bac7b",
      name: "🇮🇳 India",
    },
  },
  {
    languageId: "61b8b3014268666c126bac21",
    languageName: "Thai",
    dialect: {
      _id: "61b8b31a4268666c126bacdb",
      name: "🇹🇭 Thailand",
    },
  },
  {
    languageId: "61b8b3014268666c126bac23",
    languageName: "Turkish",
    dialect: {
      _id: "61b8b31a4268666c126bacdd",
      name: "🇹🇷 Turkey",
    },
  },
  {
    languageId: "61b8b3024268666c126bac25",
    languageName: "Ukrainian",
    dialect: {
      _id: "61b8b31c4268666c126bace9",
      name: "🇺🇦 Ukraine",
    },
  },
  {
    languageId: "61b8b3024268666c126bac27",
    languageName: "Urdu",
    dialect: {
      _id: "61b8b3144268666c126bacaf",
      name: "🇵🇰 Pakistan",
    },
  },
  {
    languageId: "61b8b3024268666c126bac29",
    languageName: "Uzbek",
    dialect: {
      _id: "61b8b31c4268666c126baced",
      name: "🇺🇿 Uzbekistan",
    },
  },
  {
    languageId: "61b8b3024268666c126bac2b",
    languageName: "Vietnamese",
    dialect: {
      _id: "61b8b31d4268666c126bacef",
      name: "🇻🇳 Vietnam",
    },
  },
  {
    languageId: "61b8b3034268666c126bac2d",
    languageName: "Welsh",
    dialect: {
      _id: "61b8b31b4268666c126bace5",
      name: "🇬🇧 United Kingdom",
    },
  },
  {
    languageId: "61b8b3034268666c126bac2f",
    languageName: "Zulu",
    dialect: {
      _id: "61b8b3184268666c126baccf",
      name: "🇿🇦 South Africa",
    },
  },
];
