import { Lang } from "@/types/lang";

export default <Lang>{
  paraphrase1: "Geben Sie den Text ein, den Sie paraphrasieren möchten.",
  paraphrase2: "Wählen Sie einen Stil.",
  paraphrase3: 'Klicken Sie auf "Paraphrase".',
  creative1: "Wählen Sie ein Thema.",
  creative2: "Wählen Sie eine Idee zur Erstellung.",
  creative3: 'Klicken Sie auf "Generieren". Die Plattform wird 3 Ergebnisse anzeigen.',
  poems1: "Wählen Sie einen Stil.",
  poems2: "Legen Sie eine Strophengröße und Strophenanzahl fest.",
  poems3: 'Klicken Sie auf "Genetieren".',
  voxAi1: "Wählen Sie eine Sprache.",
  voxAi2: "Wählen Sie eine Stimme.",
  voxAi3:
    'Geben Sie den Text ein und klicken Sie auf "Generieren". Das erzeugte Audio kann dann durch Klicken auf die Schaltfläche "Herunterladen" heruntergeladen werden.',
  neuroVision1: "Geben Sie einen Prompt ein und legen Sie die Anzahl der zu erzeugenden Bilder fest. ",
  neuroVision2: "Wählen Sie eine Art von Bild. ",
  neuroVision3:
    'Drücken Sie "Generieren". Beachten Sie, dass AI Factory über Schlüsselwortfilter verfügt. Bitte verwenden Sie keine schädlichen, suggestiven oder in irgendeiner Weise anstößigen Prompts.',
};
