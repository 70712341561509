import { AxiosError } from "axios";
import { isArray } from "lodash";

import { RequestMethod, useApi } from "@/api";
import { API_ROUTES } from "@/api/apiRoutes";
import { Article } from "@/hooks/articles/useGetArticles";
import { i18n } from "@/i18n";
import { useAppStore } from "@/store/app/appStore";

export type ApiArticle = {
  title: string;
  content: string;
  stats: Record<string, number>;
};

export type ApiArticleResponse = {
  data: Article;
};

export const useArticleCreate = async (body: ApiArticle): Promise<ApiArticleResponse | null> => {
  const date = new Date().toISOString().slice(0, 10);

  const appStore = useAppStore();

  const { updateIsLoading } = appStore;
  const { request, data } = useApi<string>({
    path: `${API_ROUTES.ARTICLES}`,
    method: RequestMethod.Post,
    body: {
      ...body,
      published_at: date,
    },
  });

  updateIsLoading(true);

  try {
    await request();

    if (data.value) {
      return data.value;
    }
  } catch (e) {
    let errorMessage = i18n.global.t("error.somethingWentWrong");
    if (e instanceof AxiosError) {
      errorMessage = isArray(e.response?.data.message) ? e.response?.data.message[0] : e.response?.data.message;
    }
    console.error(errorMessage);
  } finally {
    updateIsLoading(false);
  }
  return null;
};
