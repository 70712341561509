import { Lang } from "@/types/lang";

export default <Lang>{
  title: "Terms and Conditions",
  termsHeading1: "Terms & Conditions",
  termsHeading2: "Warranty and Liability",
  termsHeading3: "The Content",
  termsHeading4: "Restricted Content",
  termsHeading5: "Restricted Countries",
  termsHeading6: "Payment Procedure",
  termsHeading7: "Refund Policy",
  termsHeading8: "Third-party Services",
  termsHeading9: "Copyrights",
  termsHeading10: "Termination",

  terms1:
    "Prior to using the Services provided by AI Factory (the “Company”, “We”, “Us” “the Platform”, the “Service(s)” or “the Website”), it is recommended to read the following Terms & Conditions (“Agreement”). If you access the website or services in any way, you acknowledge that you have read, understood and accepted the Terms and Conditions of this Agreement, agreed to be bound by the limitations of all other terms. Breaching of any of the Terms and Conditions of this agreement results in the discontinuation of any right to use the Platform or Services. If you do not agree with these Terms, please do NOT use the Website.\n" +
    "Aifactorysolution.com is owned and operated by Techfox Group LTD.\n" +
    "\n" +
    "Techfox Group LTD. Is regulated under the laws of the Republic of Cyprus as a private limited company, with registered office at 22A Agias Zonis, office 101, 3027 Limassol, Cyprus\n" +
    "Registration number: HE 436440\n" +
    "Phone Number: +357 968 78 662\n" +
    "E-mail: contact{'@'}aifactorysolution.com\n" +
    "Eligibility of Use\n" +
    "You warrant to Us that: \n" +
    "\t•\tYou are of legal age in your country of residence; \n" +
    "\t•\tYou are capable of entering into a binding contract and not prohibited by law from using or accessing the Services; \n" +
    "\t•\tThat the information you provide to Us is accurate and truthful; \n" +
    "\t•\tYou are legally authorized to use any payment method, including credit or debit card for fulfilling any order (By providing such information, you acknowledge and authorize Us to share the information with payment processing third parties to facilitate the completion of your order. If We suspect fraud, an unauthorized or illegal transaction, We reserve the right to refuse or cancel your order at any time for certain reasons, including Service unavailability, technical errors or incorrect pricing).\n" +
    "In jurisdictions where prohibited by law, this Agreement becomes invalid, and the right to access the Services is revoked.",
  terms2:
    "The Platform is continuously being updated with regards to the availability, errors, and inaccuracies of Our Services. Our Platform may have Services that are inaccurately priced, described as stale or not available at all times, and We may experience delays in updating information about our Service on the Platform and in advertising on other websites.\n" +
    "We have the authority to modify or revise information without prior notice, including any inaccuracies or omissions. Whether you use a credit or debit card, payment can be made using whichever card you have been issued and the card issuer will validate it. In case we are unable to obtain the necessary authorization, We will not be held accountable for any delay or non-delivery of your order.\n" +
    "You are under the obligation to maintain confidentiality while sharing your password with others. Upon discovering that Your account has been hacked or used for illegal purposes, you must promptly notify Us.\n" +
    "It is your responsibility to ensure that the Content created using Our Service(s) meets legal requirements, including its correctness and appropriateness. The Company grants you the right and license to use, modify, exhibit, copy (in its entirety), reproduce it, and distribute Content created through our platform. The Company grants you complete rights over all Content generated by you, which means that you are responsible for protecting and retaining those rights.\n" +
    "You are to use the Service at your own risk. You acknowledge that the Service may contain content that may be considered offensive, indecent, incorrect, or objectionable, and you agree that no liability exists for any errors or damages resulting from your use of our Platform. If you engage in fraudulent, abusive, or otherwise illegal behavior while using the Services then your account may be terminated. You are prohibited from posting or transmitting any message or solicitation that is intended to obtain passwords, accounts, or private information from other users of the Platform. It is expressly prohibited to use the Services for the purpose of infringing on the security of any computer network, cracking passwords or security encryption codes, or transferring or storing illegal material (including potentially harmful or illegal content), as well as engaging in any illegal activity.\n" +
    "The Platform's source code will not be decompiled, reverse-engineered, or obtained through any other means. The Company implements regular Content Backups, but they do not guarantee 100% protection from corruption or loss of data. Technical support and efforts will be made by the Company to resolve any problems that may arise, whether they are known or discovered, that affect the backups of Content. The Company is not responsible for the integrity of Content or its failure to successfully restore it to a usable state.",
  terms3:
    "Our Service allows you to generate Audio, Text & Image Content. You are responsible for the Content that you generate using Our Services, including its legality and appropriateness.\n" +
    "By generating Content on the Platform, you grant Us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service. You retain any and all of your rights to any Content you create, post, or display on or through the Service and you are responsible for protecting those rights.\n" +
    "Using our Services implies that you understand that the Company cannot guarantee that the generated content is 100% correct, of the best quality or entirely truthful and therefore, will not be subject to any refunds or queries regarding the final content quality.",
  terms4:
    "You are aware and agree that you bear complete responsibility for the Content as well as for all activities associated with your account, whether you or another user perform them with an access to your account. \n" +
    "You are prohibited from creating or transmitting any Content that is illegal, offensive, disgraceful, intended to be repugnant, threatening, defamatory, indecent, unlawful or otherwise offensive. Following list of Contents may be deemed restricted, but is not limited to: \n" +
    "\t•\tEngaging in an unlawful activity or encouraging such illegal behaviour; \n" +
    "\t•\tUsing the Platform to create materials that defame, discriminate and are offensive, including any mentions or remark made regarding religion, race, sexual orientation/gender identity, national/ethnic origin, or other groups; \n" +
    "\t•\tUsing the Service to promote prohibited activities such as illegal substances/drugs usage and adult services; \n" +
    "\t•\tGenerating spam, machine-generated or randomly assigned tasks, advertising that is unauthorized or unsolicited, chain letters;\n" +
    "\t•\tAny other form of unsecured solicitation, or any kind of lottery or gambling; \n" +
    "\t•\tUsing the Service for creating or developing viruses, malwares, trojan horses or similar material designed to cause disruption, damage, or interference with the operation of any software; \n" +
    "\t•\tInfringing on the intellectual property rights of any party, including but not limited to: patents, trademarks or trade secrets, as well as copyrights and publicity rights; \n" +
    "\t•\tImpersonating individuals, including employees and representatives of the Company;\n" +
    "\t•\tEndangering the privacy of others. \n" +
    "Unless otherwise agreed upon, the Company has the authority to reject or delete sexually explicit content and make decisions about whether it is appropriate for use or compliance with these Terms. The Company is at liberty to modify the way the Content is presented and make changes. The Company has the authority to restrict or revoke the Service if you use it to create Content that is considered offensive. The Company is not responsible for the content posted by users and/or third parties on other platforms.  ",
  terms5:
    "In order to use the Services a user should confirm their e-mail address. However, the use of the Services is restricted for the residents of  the following countries: Afghanistan, Albania, Barbados, Burkina Faso, Cambodia, Cayman Islands, Democratic Republic of the Congo, Gibraltar, Haiti, Jamaica, Jordan, Mali, Morocco, Mozambique, Nicaragua, Pakistan, Panama, Philippines, Russia, Senegal, South Sudan, Syria, Tanzania, Trinidad and Tobago, Turkey, Uganda, United Arab Emirates, Vanuatu, Yemen, Zimbabwe North Korea (Democratic People's Republic of Korea), Iran, Myanmar.\n",
  terms6:
    'In order to access the Services, you must have a sufficient amount of the Platform’s internal balance – “Neurons” - in your account Wallet. "Neurons" offer users flexible access to the services available on the platform. You may check your balance and top it up in the according section of the Website. Neurons have no value outside the Platform, can only be used on the Platform and cannot be transferred to another platform or to another person. 10 Neurons equal 1 Euro. \n' +
    "Transactions are processed within 72 hours of payment. \n" +
    "After the payment is complete, it may take up to 24 hours for your balance to update, though usually this process is instant. We are entitled to use a third-party payment service processor (the “Payment Processor”) for your deposits. The respective Payment Processor is primarily responsible for facilitating payment of your transactions. In order for your transaction to be processed, the Payment Processor requires your personal details and your payment details, such as debit/credit card details, address and other information. This information is only used to process the transactions and is not disclosed to any third parties, with the only exception being the financial and regulatory institutions involved in the processing or compliance procedures. You can learn more about the usage of your personal data in Our Privacy Policy.\n" +
    "We reserve the right to verify and approve any and all transactions made by you when using the Service to ensure that they comply with these Terms and Conditions and the terms imposed on the Platform by third party service providers. You expressly acknowledge and agree that such verifications may require you to provide Us with additional personal information in order to verify and confirm your identity and to perform verifications aimed to deter fraud and misuse of the Service, in compliance with Our Privacy Policy and all applicable laws and regulations.\n" +
    "By proceeding with a transaction, you certify that all bank cards and other payment accounts you use for transactions are legally yours.",
  terms7:
    "We do not guarantee any kind of refunds if the paid service has been provided. You are not entitled to any refunds for any fees or transaction commissions you have already paid.\n" +
    "Refunds are only possible for transactions conducted to purchase Neurons and only in case of technical errors with the Service provided on the Website and/or other issues arising from Our own issues that led to your inability to receive the Services you have paid for.\n" +
    '"Technical problems" are defined as significant performance issues, system crashes, or the inability to access essential features of our platform due to errors attributable to our systems. Refunds will not be granted in cases of suspected fraud or misuse of our Services. The company reserves the right to investigate any claims thoroughly and take appropriate legal actions if necessary to protect our interests and those of our legitimate users.\n' +
    "Refund request must be submitted via contact{'@'}aifactorysolution.com no later than 24 hours from the transaction date. Refund requests will be processed within 14 business days from the date of submission. \n" +
    "Users must provide detailed descriptions, screenshots, error messages, or any relevant documentation that substantiates their claims of technical issues experienced. Requests may be denied if they do not meet the outlined criterion, including insufficient evidence of technical problems or instances of fraud or misuse.\n" +
    "Refunds will not be issued for cases where the user is simply dissatisfied with the product, service or quality of generated content without valid technical reasons.",
  terms8:
    "Links on Our Platform may lead to third party websites or services not owned by the Company. Third party websites or services may be linked to the Company, but the Company is not responsible for their content, privacy policies, or practices. You acknowledge and agree that the Company cannot be held liable for any damage or loss caused by or in connection with the use of or reliance on any such content, goods, or services offered through any websites or other services. We strongly recommend that you read the terms of use, as well as the privacy policies, of any third party websites or services which you access.",
  terms9:
    "The principle of copyright requires us to acknowledge and respect the intellectual property rights of others. It is our policy to address any claim that Content posted on the Service infringes intellectual property rights or copyright of others. In case you believe that the Service is facilitating copyright infringement, and you are either a copyright owner or authorized on behalf of, please send us an email at contact{'@'}aifactorysolution.com to submit your notice. Please include a detailed explanation of the supposed breach in your notice. The admission of a falsehood that any Content is infringing your copyright may result in you being held accountable for damages, including costs and attorneys' fees. ",
  terms10:
    "We have the authority to suspend or terminate your Account without any prior notice or liability, even if you do not violate the Terms. This applies regardless of the reason. Your use of the Service will be terminated upon deleting your account. If you want to remove your Account from the Platform, you can simply cease using the Service. This Agreement will continue in full force and effect while you are using the Services. At any time, the Company has the authority to terminate or suspend your access to the Services. In the event of a breach to any of the terms and conditions of this Agreement, the Company may immediately suspend or terminate all Services and access to the Website without any liability or warning. \n" +
    "All of the provisions of this Agreement that remain in effect after termination are essential, including ownership provisions and disclaimers. These limitations also apply to liability limitations. The Company, its parents, subsidiaries, affiliates and employees are fully obligated to defend against any claims, liabilities, settlements/suitable costs, including attorneys' fees or damages arising from your use of Services, violation of this Agreement, or the infringement by you or another party of your intellectual property or other rights.\n" +
    "If any provision of these Terms is deemed unenforceable or invalid, the other provisions will be modified and interpreted to achieve the intended purpose as per applicable law without being superseded. Unless otherwise agreed upon, the non-application of any right or obligation under these Terms will not affect the ability of either party to enforce its compliance with this Agreement. The original English version of the Terms will be the prevailing law in any dispute. \n" +
    "These Terms are subject to change and may be amended or replaced by us at our sole discretion. You acknowledge and agree to be bound by the revised Terms if you continue to access or use Our Service after those revisions become effective. If you do not agree with the new terms, please discontinue your use of the website and the Service. \n" +
    "\n" +
    "Thank you for choosing AI Factory! If you have any queries regarding these Terms and Conditions, please reach out to us at contact{'@'}aifactorysolution.com.",
  updated: "Last updated on 01 Aug 2024",
};
