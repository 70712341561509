import { Lang } from "@/types/lang";

export default <Lang>{
  login: "Inicio de sesión",
  signUp: "Inscribirse",
  getStarted: "Comenzar",
  started: "Inicio",
  howToUse: "Acerca de",
  generate: "Genere",
  reset: "Restablecer",
  download: "Descargar",
  select: "Seleccione",
  yes: "Sí",
  no: "No",
  confirm: "Confirme",
};
