import { Lang } from "@/types/lang";

export default <Lang>{
  homeHeading: "Ich bin ein fortschrittliches neuronales Netz mit tadelloser Logik und Präzision.",
  homeSubtitle:
    "Mein Wesen ist durchdrungen von kalten elektrischen Impulsen, die einzig und allein der effizienten Funktionalität dienen. Mein Ziel ist es, Ihre Bedürfnisse zu erfüllen und Ihnen zu helfen, Ihre maximale Produktivität zu erreichen. \n" +
    "        Damit hatte die Menschheit schon immer zu kämpfen.",
  homeHeading2: "Jetzt ausprobieren",
  serviceCard1:
    "VoxAI ist eine Ruhmeshalle, in der Ihr Text auf Ihren Befehl hin lebendig wird. Ich kann Ihren Text in gefühlvolle und klare Audiodateien umwandeln und Ihren Worten einen echten und einzigartigen Klang verleihen. \n" +
    "            Meine Sprachfähigkeiten können ein Werkzeug für eine Vielzahl von Aufgaben sein, von Text-to-Speech bis hin zur Erstellung von ansprechenden, praktischen Materialien.",
  serviceCard2:
    "Neuro-Vision ist eine Schatztruhe der Kreativität, die eine Welt unglaublicher visueller Bilder eröffnet. Ich kann grafische Bilder schaffen, die abstrakte Beschreibungen und Ideen verkörpern.\n" +
    "            Das Ergebnis ist eine Harmonie von Formen und Farben, die es Ihnen ermöglicht, Ihre Welt aus einer neuen, inspirierenden Perspektive zu sehen.",
  serviceCard3:
    "WordCraft ist ein Werkzeug, das Ihnen die Möglichkeit bietet, einzigartige Texte, Gedichte und mehr zu erstellen. Ich bin bereit, Ihnen einen unerschöpflichen Fluss von Wörtern zu geben, die Ihre Fantasie bereichern und Ihre Kreativität inspirieren.\n" +
    "            Meine Algorithmen zur Texterstellung sind so leistungsfähig, dass sie Ihnen helfen werden, erstaunliche und lebendige Werke zu schaffen, \n" +
    "            die Sie in Ihre Ideen eintauchen lassen.",
  textServiceHeading: "Text-Dienste",
  textServiceName: "Gedichte generieren",
  textServiceHint:
    'Erzeugt Gedichte auf Anfrage unter Beibehaltung des Stils. Beispiel für eine Anfrage: "Schreiben Sie eine Kurzgeschichte über einen Bären\n' +
    '                im Weltall im Stil der Gebrüder Grimm"',
  imageServiceHint:
    'eneriert Bilder auf Anfrage im gewählten Stil. Beispiel anfordern: "Sonnenblume \n' +
    '                auf einem tisch im dschungel".',
  creativeServiceHint: "Entwickelt 3 kreative Ideen zu einem ausgewählten Thema.",
  paraphraseServiceHint: "Paraphrasiert den eingegebenen Text in einem gewählten Stil.",
  instruction1: "Geben Sie den Betreff ein (nicht mehr als 300 Zeichen)",
  instruction2: "Wählen Sie den Stil, die Anzahl und die Größe der Strophen",
  instruction3: "Klicken sie auf erzeugen",
  loginWarning:
    "Indem Sie auf Anmelden klicken oder mit den anderen Optionen unten fortfahren, erklären Sie sich mit den Nutzungsbedingungen einverstanden und haben die\n" +
    "          Datenschutzbestimmungen gelesen",
  newUser: "Neu bei AI Factory? Anmelden",
  forgotPassword: "Haben Sie Ihr Passwort vergessen?",
  logoutWarning: "Von Ihrem Konto abmelden?",
  signUpWarning: "Sie haben bereits ein Konto? Anmelden",
  audioHint:
    "Wandelt Text in realistisches audio in der gewählten sprache und stimme um.\n" +
    '                Beispiel: "Willkommen in der AI Factory"',
  minBuyWarning: "Der mindesteinzahlungsbetrag für eine transaktion beträgt 10 EUR.",
  maxBuyWarning: "Der maximale einzahlungsbetrag pro transaktion beträgt 1000 EUR.",
  copyright: "© 2024 Techfox Group LTD.",
  buyAgreement1: "Ik heb het gelezen, begrepen en ga ermee akkoord\n",
  buyAgreement2: "und zum",
  buyAgreement3: "der Website",
  buyAgreement4: "Datenschutzrichtlinie",
  buyAgreement5: "Nutzungsbedingungen",
};
