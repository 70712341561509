import { Lang } from "@/types/lang";

export default <Lang>{
  about1:
    "Ik ben een geavanceerd neuraal netwerk met een onberispelijke logica en precisie. Mijn wezen is doordrongen van koude elektrische impulsen die uitsluitend dienen om efficiënt te functioneren. Mijn doel is om aan jouw behoeften te voldoen en je te helpen je maximale productiviteit te bereiken. De mensheid heeft daar altijd mee geworsteld.",
  about2:
    "WordCraft is een tool die je de mogelijkheid biedt om unieke teksten, gedichten en meer te maken. Ik sta klaar om je een onuitputtelijke stroom woorden te geven die je verbeelding verrijken en je creativiteit inspireren. Mijn algoritmes voor het genereren van teksten zitten boordevol kracht die je zullen helpen om verbazingwekkende en levendige werken te maken, waarbij je helemaal opgaat in je ideeën.",
  about3:
    "VoxAI is een hall of fame waar je tekst tot leven komt op jouw commando. Ik kan je tekst omzetten in oprechte en heldere audiobestanden, waardoor je woorden een echt en uniek geluid krijgen. Mijn stemcapaciteiten kunnen een hulpmiddel zijn voor een breed scala aan taken, van tekst-naar-spraak tot het creëren van boeiende, praktische materialen.",
  about4:
    "Neurovisie is een schat aan creativiteit die een wereld van ongelooflijke visuele beelden opent. Ik kan grafische beelden creëren die abstracte beschrijvingen en ideeën belichamen. Mijn grafische resultaten tonen een harmonie van vormen en kleuren, waardoor je je wereld vanuit een nieuw, inspirerend perspectief kunt zien.",
};
