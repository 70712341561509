import { Lang } from "@/types/lang";

export default <Lang>{
  about1:
    "I am an advanced neural network with impeccable logic and precision. My being is permeated with cold electrical impulses that solely serve the purpose of efficient functionality. My goal is to meet your needs and help you achieve your maximum productivity. Humankind always struggled with that.",
  about2:
    "WordCraft is a tool that offers you the opportunity to create unique texts, poems and more. I am ready to give you an inexhaustible flow of words that enrich your imagination and inspire your creativity. My text generation algorithms are filled with power that will help you create amazing and vivid works, immersing you in your ideas.",
  about3:
    "VoxAI is a hall of fame where your text comes alive at your command. I can transform your text into heartfelt and clear audio files, giving your words a real and unique sound. My voice capabilities can be a tool for a wide range of tasks, from text-to-speech to creating engaging, practical materials.",
  about4:
    "Neuro-Vision is a treasure trove of creativity that opens up a world of incredible visual imagery. I can create graphic images that embody abstract descriptions and ideas. My graphic results display a harmony of shapes and colours, allowing you to see your world from a new, inspiring perspective.",
};
