import { Lang } from "@/types/lang";

export default <Lang>{
  login: "Connexion",
  signUp: "S'inscrire",
  getStarted: "Commencer",
  started: "Démarrage",
  howToUse: "A propos de",
  generate: "Générer",
  reset: "Remise à zéro",
  download: "Télécharger",
  select: "Sélectionner",
  yes: "Oui",
  no: "Non",
  confirm: "Confirmer",
};
