import { Lang } from "@/types/lang";

export default <Lang>{
  registered: "Controleer uw e-mail voor bevestiging",
  login: "Succesvol ingelogd",
  logout: "Succesvol uitgelogd",
  resetPassword: "Wachtwoord is succesvol gereset",
  poem: "Gedicht succesvol gegenereerd",
  textParaphrased: "Tekst succesvol geherformuleerd",
  creativeText: "Creatieve tekst succesvol gegenereerd",
  imageGenerated: "Afbeelding succesvol gegenereerd",
  registrationFinished: "Registratie succesvol afgerond",
  textCopied: "Tekst gekopieerd",
  balance: "Balans bijgewerkt",
  audioGenerated: "Audio succesvol gegenereerd",
};
