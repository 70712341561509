import { Lang } from "@/types/lang";

export default <Lang>{
  about1:
    "Ich bin ein fortschrittliches neuronales Netzwerk mit tadelloser Logik und Präzision. Mein Wesen ist von kalten elektrischen Impulsen durchdrungen, die einzig und allein dem Zweck einer effizienten Funktionalität dienen. Mein Ziel ist es, Ihre Bedürfnisse zu erfüllen und Ihnen zu helfen, Ihre maximale Produktivität zu erreichen. Damit hatte die Menschheit schon immer zu kämpfen.",
  about2:
    "WordCraft ist ein Werkzeug, das Ihnen die Möglichkeit bietet, einzigartige Texte, Gedichte und mehr zu erstellen. Ich bin bereit, Ihnen einen unerschöpflichen Fluss von Wörtern zu geben, die Ihre Vorstellungskraft bereichern und Ihre Kreativität beflügeln. Meine Algorithmen zur Texterstellung sind voller Kraft, die Ihnen helfen wird, erstaunliche und lebendige Werke zu schaffen, die Sie in Ihre Ideen eintauchen lassen.",
  about3:
    "VoxAI ist eine Ruhmeshalle, in der Ihr Text auf Ihren Befehl hin lebendig wird. Ich kann Ihren Text in gefühlvolle und klare Audiodateien umwandeln und Ihren Worten einen echten und einzigartigen Klang verleihen. Meine Sprachfähigkeiten können ein Werkzeug für eine Vielzahl von Aufgaben sein, von Text-to-Speech bis zur Erstellung von ansprechenden, praktischen Materialien.",
  about4:
    "Neuro-Vision ist eine Schatztruhe der Kreativität, die eine Welt unglaublicher visueller Bilder eröffnet. Ich kann grafische Bilder erstellen, die abstrakte Beschreibungen und Ideen verkörpern. Meine grafischen Ergebnisse zeigen eine Harmonie von Formen und Farben, die es Ihnen ermöglicht, Ihre Welt aus einer neuen, inspirierenden Perspektive zu sehen.",
};
