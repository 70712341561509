import { Lang } from "@/types/lang";

export default <Lang>{
  question1: "Qu'est-ce que AI Factory ?",
  question2: "Comment fonctionne AI Factory ?",
  question3: "Quels sont les services disponibles sur la plateforme ?",
  question4: "Comment puis-je accéder aux services d'AI Factory ?",
  question5: "Qu'est-ce qu'un Neuron et comment l'obtenir ?",
  question6: "Comment la plateforme calcule-t-elle le coût final d'une génération de contenu ?",
  question6_1: "Combien de temps faut-il pour que les transactions soient traitées ?",
  question7: "Quelles sont les applications d'AI Factory ?",
  question8: "Quelle est la technologie qui sous-tend AI Factory ?",
  question9: "Quelles langues AI Factory peut-elle traiter ?",
  question10: "Comment la plateforme garantit-elle la qualité du contenu généré ?",
  answer1:
    "AIFactory est un réseau neuronal autonome combiné capable de générer des textes, de les réécrire, de suggérer des idées créatives, de produire des images ultra-réalistes et des fichiers audio avec des voix réalistes, etc.",
  answer2:
    "AIFactory utilise des réseaux neuronaux d'apprentissage autonome de pointe qui ont la capacité d'analyser et de générer du contenu, et d'être créatifs pour proposer des idées et des solutions uniques.",
  answer3:
    "AI Factory comporte trois sections uniques :\n" +
    "WordCraft est une section basée sur le texte qui vous permet de paraphraser des blocs de texte, de générer des idées créatives, des poèmes et bien plus encore !\n" +
    "VoxAI est un service de conversion texte-voix contenant de 6 voix ultra-réalistes pour créer du contenu audio.\n\n" +
    "Neuro-Vision est une section où l'utilisateur peut générer n'importe quelle image dans 17 styles différents.",
  answer4:
    "Pour accéder aux services, vous devez d'abord créer un compte et confirmer votre adresse électronique. Ensuite, vous pouvez accéder directement au service qui vous intéresse et l'utiliser, à condition d'avoir suffisamment de neurones !",
  answer5:
    "Nous avons mis en place un système d'équilibre sur notre plateforme et les Neurons sont la monnaie spéciale utilisée pour accéder aux services de l'AI Factory. Pour les obtenir, il vous suffit de vous rendre dans votre profil et de cliquer sur le bouton \"Acheter des neurones\".",
  answer6:
    "Il y a un coût de base pour chaque service. Par exemple, 1 image dans Neuro-Vision coûte 50 Neurons, il n'y a pas d'options supplémentaires, le prix est donc statique. Pour d'autres services tels que VoxAI, il existe des caractéristiques supplémentaires telles que la langue, la taille du texte et le type de voix. Toutes ces options supplémentaires font varier le prix et nous avons veillé à ce que le coût final soit correctement affiché avant que l'utilisateur ne dépense ses neurones.",
  answer6_1:
    "Les transactions sont généralement traitées dans les 72 heures suivant le paiement. Une fois le paiement effectué, la mise à jour du solde de votre compte peut prendre jusqu'à 24 heures, mais le processus est généralement instantané.",
  answer7:
    "AI Factory peut être utilisé pour générer des textes de qualité, augmenter et réécrire des contenus existants, fournir des idées créatives, créer des images et des fichiers audio réalistes.",
  answer8:
    "AIFactory repose sur des technologies avancées d'intelligence artificielle, notamment les réseaux neuronaux et l'apprentissage profond, et utilise des techniques de génération et de transformation de contenu.",
  answer9:
    "AI Factory peut traiter des textes dans de nombreuses langues, y compris, mais sans s'y limiter, l'anglais, le néerlandais, l'espagnol, le français, l'allemand, l'italien et d'autres langues courantes.",
  answer10:
    "AI Factory utilise des modèles et des algorithmes avancés qui sont formés sur de grandes quantités de données et équipés de mécanismes de contrôle de la qualité afin de garantir que le contenu généré est de haute qualité, bien que nous ne puissions pas garantir un résultat précis à 100 %.",
};
