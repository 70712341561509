import { Lang } from "@/types/lang";

export default <Lang>{
  paraphrase1: "Voer een tekst in die u wilt parafraseren.",
  paraphrase2: "Kies een stijl.",
  paraphrase3: "Klik op 'Parafraseren'.",
  creative1: "Kies een onderwerp.",
  creative2: "Kies een idee om te genereren.",
  creative3: 'Klik op "Genereer". Het Platform toont 3 resultaten.',
  poems1: "Kies een stijl.",
  poems2: "Stel een strofengrootte en strofenaantal in.",
  poems3: 'Klik op "Geneteren".',
  voxAi1: "Kies een taal.",
  voxAi2: " Kies een stem.",
  voxAi3:
    'Voer de tekst in en klik op "Genereer". De gegenereerde audio kan vervolgens worden gedownload door op de knop "Downloaden" te klikken.',
  neuroVision1: "Voer een prompt in en stel het aantal te genereren beelden in.",
  neuroVision2: "Kies een afbeeldingsstijl.",
  neuroVision3:
    'Druk op "Genereer". AI Factory heeft trefwoordfilters. Gebruik geen schadelijke, suggestieve of aanstootgevende prompts.',
};
